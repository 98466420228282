import { Injectable } from '@angular/core';
import { KRONOS } from '../../../app-constants';
import { API } from '../../../constants/index';
import { DataService } from '../core/interceptor.service';

@Injectable({
  providedIn: 'root'
})
export class ForecastService {

  constructor(private dataService: DataService) { }

  addUpdateAllocation(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.FORECAST + API.ADD_UPDATE_ALLOCATION, req);
  }

  getAllocation(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.FORECAST + API.GET_RESOURCE_ALLOCATION, req)
  }

  getOrgForecastData(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.FORECAST + API.GET_ORG_FORECAST_DATA, req);
  }
}
