import { Injectable } from '@angular/core';
import { UtilsService } from '../helpers/services/index';
import { MESSAGES } from '../constants/index';
import 'aws-sdk/dist/aws-sdk.min';
import { Buffer } from 'buffer';

@Injectable({
    providedIn: 'root'
})
export class ImageUpload {
    bucketInfo: any;
    bucketName: any;
    window: any = window;
    AWS: any = this.window.AWS;

    constructor(private utils: UtilsService) {
        this.getBucketInfo();
    }

    resize(img, MAX_WIDTH: number, MAX_HEIGHT: number, callback) {
        return img.onload = () => {
            let width = img.width;
            let height = img.height;
            if (width > height) {
                if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;
                }
            } else {
                if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height;
                    height = MAX_HEIGHT;
                }
            }
            const canvas = document.createElement('canvas');
            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext('2d');

            ctx.drawImage(img, 0, 0, width, height);
            const dataUrl = canvas.toDataURL('image/jpeg');
            callback(dataUrl, img.src.length, dataUrl.length);
        };
    }

    readFiles(files, index, callbackResp) {
        const reader = new FileReader();

        if ((files[index].type !== 'image/jpeg') && (files[index].type !== 'image/png')) {
            this.utils.growlMessages('warn', MESSAGES.ONLY_IMAGES);
        } else {
            this.utils.readFile(files[index], reader, (result) => {
                const fileName = files[index].name;
                const img = document.createElement('img');
                img.src = result;
                this.resize(img, 400, 300, (resized_jpeg, before, after) => {
                    callbackResp(fileName, resized_jpeg);
                });
            });
        }
    }

    uploadProfile(request, imageUrl, userFileName, callback) {
        if (this.bucketInfo) {
            const fileName = this.utils.getTimestamp() + '_' + userFileName;
            this.AWS.config.accessKeyId = this.bucketInfo.accessKey;
            this.AWS.config.secretAccessKey = this.bucketInfo.secretKey;
            const bucket = new this.AWS.S3({ params: { Bucket: this.bucketName } });
            const buf = new Buffer(imageUrl.replace(/^data:image\/\w+;base64,/, ''), 'base64');
            const params = { Key: fileName, Body: buf, ContentEncoding: 'base64', ContentType: 'image/jpeg' };
            const instance = this;
            bucket.upload(params, function (err, data) {
                if (err) {
                    instance.utils.growlMessages('warn', 'There was an error uploading your file');
                    console.log('There was an error uploading your file: ', err);
                    request.imagePath = null;
                    this.saveUser = false;
                    callback(null);
                } else {
                    callback(fileName);
                }
            });
        } else {
            callback(null);
        }
    }

    getBucketInfo() {
        this.utils.getBucketName().subscribe((result: any) => {
            if (this.utils.isResponseSuccess(result)) {
                this.bucketInfo = result.responseData;
                if (this.bucketInfo) {
                    this.bucketName = this.bucketInfo.bucketName + '/tmp/' + this.utils.getLoggedInUser().orgId;
                }
            }
        });
    }
}