export class Account{
    id:number
    sessioId:string
    name:string
    updateAccountName:string
    countryId:number
    updateCountryId:number
    country:number
    updateCountry:number
    active:boolean
    updateActive:boolean
}