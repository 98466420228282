import { Injectable } from '@angular/core';
import { DataService } from '../core/interceptor.service';
import { KRONOS } from '../../../app-constants';
import { API } from '../../../constants/index';

@Injectable()
export class PolicyService {

  constructor(private dataService: DataService) { }

  addNewCategory(catName, desc) {
    const req = {
      categoryName: catName, categoryDescription: desc
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.POLICY + API.ADD_CATEGORY, req);
  }

  getAllCategory() {
    const req = {
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.POLICY + API.GET_ALL_CATEGORIES, req);
  }

  addNewPolicy(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.POLICY + API.ADD_POLICY, req);
  }

  getAllPolicy(catId) {
    const req = {
      policyCategory: catId
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.POLICY + API.GET_ALL_POLICY, req);
  }

  updatePolicy(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.POLICY + API.UPDATE_POLICY, req);
  }

  deletePolicy(id) {
    const req = {
      id: id
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.POLICY + API.DELETE_POLICY, req);
  }

  deletePolicyCat(id) {
    const req = {
      id: id
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.POLICY + API.DELETE_POLICY_CAT, req);
  }
}
