export class HashCode {

    /**
     * This method is to get the hashcode value for the action name
     * @param  {string} actionName
     */
    static getHash(actionName) {
        let hashcode;
        for (let i = 0; i < actionName.length; i++) {
            hashcode = Math.imul(31, hashcode) + actionName.charCodeAt(i) | 0;
        }

        if (hashcode < 0) {
            hashcode = hashcode * -1;
        }

        return hashcode
    }
}

export const PERMISSIONS = {

    MODULES: {

        'ORGANIZATION': 1,
        'TIMEREPORT': 8,
        'USER_DIRECTORY': 15,
        'TRAININGS': 16,
        'LINKS': 9,
        'HOLIDAYS': 12,
        'ANNOUNCEMENTS': 10,
        'EVENTS': 11,
        'PROJECTS': 18,
        'EXPENSES': 20,
        'POLICIES': 17,
        'UTILIZATION': 19,
        'ADMINISTRATION': 14,
        'CALENDARS': 13,
        'AWARDS': 21,
        'AVAILABILITY': 22,
        'FORECAST': 23,
        'SKILLS': 24,
        'NEARBY': 25,
        'IBUSPACE': 27,
        'CUSTOMERSPACE': 28,
        'FOODREGISTER': 29,
        'FINANCE': 30,
        'ESAT': 31,
        'KNOWKRONOS': 32,
        'FEEDS': 36,
        'REPORTS': 34,
        'SOLUTIONS': 35,
        'INDUSTRIES': 33,
        'VENDORS' : 41,
        'MICROLEARNING':37
        // 'PMS'
    },

    ACTIONS: {

        // Organization
        'MODIFY_ALL_ORG': HashCode.getHash('modify.allOrganization'),

        // Time report actions
        'MODIFY_TIMEREPORT': HashCode.getHash('modify.timeReport'),
        'VIEW_USER_REPORT': HashCode.getHash('view.userReport'),
        'VIEW_PERSONAL_REPORT': HashCode.getHash('view.personalReport'),
        'VIEW_MONTH_INVOICE_REPORT': HashCode.getHash('view.userMonthInvoiceReport'),
        'VIEW_PROJECT_INVOICE_REPORT': HashCode.getHash('view.projectInvoiceReport'),
        'VIEW_BUSINESS_UNIT_REPORT': HashCode.getHash('view.businessUnitReport'),
        'VIEW_COUNTRY_REPORT': HashCode.getHash('view.countryWiseReport'),
        'VIEW_FOOD_REPORT': HashCode.getHash('view.foodReport'),
        'VIEW_TEAM_REPORT': HashCode.getHash('view.teamReport'),
        'VIEW_ACCOUNT_REPORT': HashCode.getHash('view.accountWiseReport'),
        'VIEW_DEFAULTERS_REPORT': HashCode.getHash('view.defaultersReport'),
        'VIEW_FEATURE_INVOICE_REPORT': HashCode.getHash('view.featureInvoiceReport'),
        'VIEW_TRAINING_REPORT': HashCode.getHash('view.trainingReport'),
        'VIEW_LEAVE_GENERAL_REPORT': HashCode.getHash('view.LeaveGeneralReport'),
        'MODIFY_ALL_REPORT_SUBSCRIPTION': HashCode.getHash('modify.report.subscription'),
        'MODIFY_USER_REPORT_SUBSCRIPTION': HashCode.getHash('modify.user.report.subscription'),
        'VIEW_ACANDO_REPORT': HashCode.getHash('view.acando.reports'),
        'VIEW_TASK_REPORT': HashCode.getHash('view.task.reports'),
        'VIEW_WORK_LOCATION_REPORT': HashCode.getHash('view.work.location.reports'),

        // user directory actions
        'MODIFY_USER_DIRECTORY': HashCode.getHash('modify.users'),
        'MODIFY_PERSONAL_PROFILE': HashCode.getHash('modify.PersonalProfile'),
        'VIEW_PERSONAL_PROFILE': HashCode.getHash('view.personalProfile'),
        'MODIFY_OTHERS_PROFILES': HashCode.getHash('modify.OthersProfile'),

        // Trainings actions
        'MODIFY_TRAINING': HashCode.getHash('modify.training'),
        'VIEW_TRAINING': HashCode.getHash('view.training'),
        'UPDATE_ALL_TRAINING': HashCode.getHash('update.all.training'),
        'DELETE_ALL_TRAINING': HashCode.getHash('delete.all.training'),
        'VIEW_TRAINING_FEEDBACK_REPORT': HashCode.getHash('view.training.feedbackReport'),
        'VIEW_ALL_TRAINING_FEEDBACK_REPORT': HashCode.getHash('view.all.training.feedbackReport'),
        'MODIFY_TRAINING_REQUEST': HashCode.getHash('modify.training.request'),

        // Links
        'MODIFY_LINKS': HashCode.getHash('modify.link'),
        'VIEW_LINKS': HashCode.getHash('view.link'),
        'MODIFY_ORG_LINKS': HashCode.getHash('modify.orgLink'),

        // Holidays
        'MODIFY_HOLIDAY': HashCode.getHash('modify.holiday'),
        'VIEW_HOLIDAY': HashCode.getHash('view.holiday'),

        // Announcements
        'MODIFY_ANNOUNCEMENT': HashCode.getHash('modify.announcement'),
        'VIEW_ANNOUNCEMENT': HashCode.getHash('view.announcement'),
        'UPDATE_ALL_ANNOUNCEMENT': HashCode.getHash('update.all.announcement'),
        'DELETE_ALL_ANNOUNCEMENT': HashCode.getHash('delete.all.announcement'),

        // Events
        'MODIFY_EVENT': HashCode.getHash('modify.event'),
        'VIEW_EVENT': HashCode.getHash('view.event'),
        'UPDATE_ALL_EVENT': HashCode.getHash('update.all.event'),
        'DELETE_ALL_EVENT': HashCode.getHash('delete.all.event'),

        // Projects
        'MODIFY_PROJECTINVOICE': HashCode.getHash('modify.projectinvoice'),
        'VIEW_PROJECT_INVOICE': HashCode.getHash('view.projectinvoice'),
        'MODIFY_PROJECT_ESTIMATION': HashCode.getHash('modify.projectestimation'),
        'MODIFY_PROJECT': HashCode.getHash('modify.project'),
        'MODIFY_TEMPLATE': HashCode.getHash('modify.template'),
        'VIEW_REPORT': HashCode.getHash('view.report'),
        'MODIFY_PROJECT_REQUEST': HashCode.getHash('modify.projectRequest'),
        'CREATE_VERIFIED_PROJECT_REQUEST': HashCode.getHash('create.verifiedProjectRequest'),
        'REVIEW_PROJECT_REQUEST': HashCode.getHash('review.projectRequest'),
        'MODIFY_PROJECT_REQUEST_STATUS': HashCode.getHash('modify.projectRequestStatus'),
        'VIEW_PROJECT_WISE_INVOICE_REPORT': HashCode.getHash('view.projectwiseInvoiceReport'),
        'VIEW_ALL_PROJECT_UPDATES': HashCode.getHash('view.allProjectupdates'),
        'VIEW_PROJECT_USER_ALLOCATION': HashCode.getHash('view.projectUserAllocation'),
        'VIEW_PROJECT_ESTIMATION': HashCode.getHash('view.projectEstimation'),
        'VIEW_ASSIGNED_PROJECT': HashCode.getHash('view.assignedProject'),
        'VIEW_PROJECT_NOTES': HashCode.getHash('view.projectNotes'),
        'MODIFY_PROJECT_NOTES': HashCode.getHash('modify.projectNotes'),
        'MODIFY_PROJECT_EVENT': HashCode.getHash('modify.projectEvents'),
        'VIEW_PROJECT_INSIGHTS': HashCode.getHash('view.projectInsights'),
        'MODIFY_PROJECT_MEMBERS': HashCode.getHash('modify.projectMembers'),
        'MODIFY_PROJECT_HEALTH': HashCode.getHash('modify.projectHealth'),
        'VIEW_PROJECT_REPORT': HashCode.getHash('view.projectReport'),
        'VIEW_PROJECT_LESSONS': HashCode.getHash('view.projectLessons'),
        'MODIFY_PROJECT_LESSONS': HashCode.getHash('modify.projectLessons'),
        'VIEW_PROJECT_AUDIT': HashCode.getHash('view.projectAudit'),
        'MODIFY_PROJECT_AUDIT': HashCode.getHash('modify.projectAudit'),
        'MODIFY_PROJECT_USER_ALLOCATION': HashCode.getHash('modify.projectUserAllocation'),
        'VIEW_ALL_PROJECT_AUDIT': HashCode.getHash('view.allProjectsAudit'),
        'VIEW_PROJECT_ADMIN_REPORT': HashCode.getHash('view.projectAdminReport'),
        'MODIFY_ADMIN_PROJECT': HashCode.getHash('modify.admin.project'),
        'MODIFY_CSAT_FORM': HashCode.getHash('modify.CSAT.form'),
        'VIEW_PROJECT_CONTRACT': HashCode.getHash('view.project.contract'),
        'MODIFY_PROJECT_CONTRACT': HashCode.getHash('modify.project.contract'),
        'VIEW_PROJECT_BILLING': HashCode.getHash('view.project.billing'),
        'MODIFY_PROJECT_BILLING': HashCode.getHash('modify.project.billing'),
        'MODIFY_PROJECT_INVOICE_STATUS': HashCode.getHash('modify.project.invoice.status'),
        'MODIFY_DOMAIN': HashCode.getHash('modify.domain'),
        'MODIFY_PROJECT_CHECKIN_QUESTION': HashCode.getHash('modify.checkIn.question'),
        'MODIFY_PUBLIC_PROJECT': HashCode.getHash('modify.public.project'),
        'VIEW_ALL_PROJECTS': HashCode.getHash('view.all.projects'),

        // Expenses
        'VIEW_EXPENSE': HashCode.getHash('view.expense'),
        'MODIFY_EXPENSE': HashCode.getHash('modify.expense'),
        'VIEW_ORG_EXPENSE': HashCode.getHash('view.orgExpense'),
        'MODIFY_EXPENSE_STATUS': HashCode.getHash('modify.expenseStatus'),
        'MODIFY_EXPENSE_CATEGORY': HashCode.getHash('modify.expense.category'),

        // Policies
        'MODIFY_POLICY': HashCode.getHash('modify.policy'),
        'VIEW_POLICY': HashCode.getHash('view.policy'),

        // Utilization
        'MODIFY_UTILIZATION': HashCode.getHash('modify.utilization'),
        'VIEW_UTILIZATION': HashCode.getHash('view.utilization'),

        // Administration Dashboard

        // Accounts
        'MODIFY_ACCOUNT': HashCode.getHash('modify.account'),
        'VIEW_ORG_ACCOUNT_PROJECT': HashCode.getHash('view.orgAccountProjects'),
        'ADD_ACCOUNT_PROJECT': HashCode.getHash('create.orgAccount.project'),
        'UPDATE_ACCOUNT_PROJECT': HashCode.getHash('update.orgAccount.project'),

        'MODIFY_ORGANIZATION': HashCode.getHash('modify.organization'),
        'MODIFY_ROLE': HashCode.getHash('modify.role'),
        'VIEW_ROLE_PERMISSIONS': HashCode.getHash('view.rolePermissions'),
        'MODIFY_TEAM': HashCode.getHash('modify.team'),
        'MODIFY_DESIGNATIONS': HashCode.getHash('modify.designation'),
        'MODIFY_BUSINESS_UNIT': HashCode.getHash('modify.businessunit'),
        'MODIFY_TASK_TEMPLATE': HashCode.getHash('modify.taskTemplate'),
        'MODIFY_GROUPS': HashCode.getHash('modify.group'),
        'MODIFY_AUDIT': HashCode.getHash('modify.audit'),
        'VIEW_AUDIT': HashCode.getHash('view.audit'),
        'MODIFY_ASSET': HashCode.getHash('modify.asset'),
        'MODIFY_ASSET_USER': HashCode.getHash('modify.assetUser'),
        'VIEW_ASSET': HashCode.getHash('view.asset'),
        'MODIFY_SKILLS': HashCode.getHash('modify.skills'),
        'MODIFY_SITES': HashCode.getHash('modify.sites'),
        'MODIFY_LOCATIONS': HashCode.getHash('modify.locations'),
        'MODIFY_CURRENCY': HashCode.getHash('modify.currency'),
        'EXCLUDE_USER': HashCode.getHash('exclude.user'),
        'MODIFY_LOCK_SYSTEM': HashCode.getHash('modify.locksystem'),
        'MODIFY_USER_GROUPS': HashCode.getHash('modify.userGroup'),
        'MODIFY_FEATURE': HashCode.getHash('modify.feature'),
        // 'MODIFY_TRAVEL': HashCode.getHash('modify.travel'),
        // 'VIEW_TRAVEL': HashCode.getHash('view.travel'),
        'MODIFY_CHECKIN_TEMPLATE': HashCode.getHash('modify.projectCheckIn.Template'),
        'MODIFY_CHECKIN_ACTIVITY': HashCode.getHash('modify.checkIn.activity'),
        'MODIFY_PROJECT_CHECKIN_TEMPLATE': HashCode.getHash('modify.checkIn.template'),
        'VIEW_CHECKIN_INSIGHTS': HashCode.getHash('view.checkIn.insights'),
        'VIEW_AWARD_LEADERBOARD': HashCode.getHash('view.award.leaderboard'),
        'MODIFY_CSAT_TEMPLATE': HashCode.getHash('modify.CSAT.template'),
        'VIEW_BULK_UPLOAD': HashCode.getHash('view.bulkUploadTemplate'),
        'MODIFY_DASHBOARD_BANNER' : HashCode.getHash('modify.dashboard.banners'),


        // Calendar
        'VIEW_CALENDAR_HOLIDAYS': HashCode.getHash('view.calendarHolidays'),
        'VIEW_PERSONAL_LEAVE': HashCode.getHash('view.personalLeave'),

        //Awards
        'MODIFY_AWARDS': HashCode.getHash('modify.award'),
        'ISSUE_AWARD': HashCode.getHash('issue.award'),
        'VIEW_ISSUE_AWARD': HashCode.getHash('view.issue.award'),
        'MODIFY_ALL_ISSUE_AWARD': HashCode.getHash('modify.all.issue.award'),
        'VIEW_PERSONAL_AWARDS': HashCode.getHash('view.personal.award'),
        'VIEW_OTHERS_AWARDS': HashCode.getHash('view.others.award'),

        // Allocation
        'MODIFY_USER_AVAILABILITY': HashCode.getHash('modify.user.availabilty'),
        'VIEW_USER_AVAILABILITY': HashCode.getHash('view.user.availability'),

        //Forecast
        'MODIFY_RESOURCE_ALLOCATION': HashCode.getHash('modify.resource.allocation'),
        'VIEW_RESOURCE_ALLOCATION': HashCode.getHash('view.resource.allocation'),

        //Skills
        'SEARCH_SKILL': HashCode.getHash('search.skills'),
        'MODIFY_LEARNING_RESOURCE': HashCode.getHash('modify.learning.resource'),
        'MODIFY_ALL_LEARNING_RESOURCE': HashCode.getHash('modify.all.learning.resource'),
        'VIEW_ALL_LEARNING_RESOURCE': HashCode.getHash('view.all.learning.resource'),

        //Nearby
        'VIEW_NEARBY_PEOPLE': HashCode.getHash('view.nearby.people'),

        //IBU Space
        'VIEW_BUSINESS_UNIT': HashCode.getHash('view.businessunit'),

        //CUSTOMER Space
        'VIEW_CUSTOMER_SPACE': HashCode.getHash('view.customer.space'),
        'MODIFY_CUSTOMER_SPACE': HashCode.getHash('modify.customer.space'),
        'VIEW_CUSTOMER_PROJECTS': HashCode.getHash('view.customer.projects'),
        'MODIFY_CUSTOMER_PROJECTS': HashCode.getHash('modify.customer.projects'),

        //FOOD REGISTER
        'VIEW_FOOD_REGISTER': HashCode.getHash("view.food.register"),
        'FOOD_BOOKING': HashCode.getHash("food.booking"),
        'MARK_FOOD_ENTRY': HashCode.getHash("mark.food.entry"),
        'VIEW_ALL_FOOD_STATUS': HashCode.getHash("view.all.food.status"),
        'FOOD_REPORT': HashCode.getHash("food.report"),

        //FINANCE
        'VIEW_ALL_PROJECT_INVOICE': HashCode.getHash("view.all.project.invoice"),
        'MODIFY_PROJECT_INVOICE': HashCode.getHash("modify.project.invoice"),
        'VIEW_FINANCE_REPORTS': HashCode.getHash("view.finance.reports"),

        //ESAT
        'MOOD_OF_THE_CREW_FEEDBACK': HashCode.getHash("submit.rating.feedback"),

        //KNOWKRONOS
        'VIEW_KNOW_KRONOS': HashCode.getHash("view.know.kronos"),
        'MODIFY_KNOW_KRONOS': HashCode.getHash("modify.know.kronos"),

        //FEEDS
        'VIEW_FEEDS': HashCode.getHash("view.feeds.posts"),

        //INDUSTRIES
        'VIEW_INDUSTRIES': HashCode.getHash('view.industries'),
        'MODIFY_INDUSTRIES': HashCode.getHash('modify.industries'),

        //SOLUTIONS
        'VIEW_SOLUTIONS': HashCode.getHash('view.solutions'),
        'MODIFY_SOLUTIONS': HashCode.getHash('modify.solutions'),

        //MICROLEARNING
        'VIEW_MICROLEARNING': HashCode.getHash("view.microlearning.posts"),

        //VENDORS
        'VIEW_VENDORS': HashCode.getHash('view.vendors'),
        'MODIFY_VENDORS': HashCode.getHash('modify.vendors'),

    },

}
