import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { UtilsService } from "../../../../helpers/services/index";

@Component({
  selector: 'app-contract-card',
  templateUrl: './contract-card.component.html',
  styleUrls: ['./contract-card.component.scss']
})
export class ContractCardComponent implements OnInit {

  @Input() resource: any;
  @Input() contract: any;
  @Output() edit = new EventEmitter<Object>();
  searchString: any;
  constructor(public utils: UtilsService) { }

  ngOnInit() {
  }

  onEdit(data) {
    this.edit.emit(data);
  }

}
