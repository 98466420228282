import { Component, ElementRef, HostListener, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { MODULES, ROUTERLINKS } from 'app/constants';
import { DEFAULT_MODULE } from 'app/constants/modules';
import { GlobalSearchService, UtilsService } from 'app/helpers/services';
import Fuse from 'fuse.js';
import { CookieService } from 'ngx-cookie';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss']
})
export class GlobalSearchComponent implements OnInit {
  userNavigations: any;
  moduleList: any;
  searchedNavigations: any;
  globalSearch: string = "";
  isGlobalSearchFocused: boolean = false;
  fuzzySearcher: any;
  submitGlobalSearchDebounce: Subject<void> = new Subject<void>();
  searchBarWidth: any;
  recentSearchKey: string = "Recent Searches";
  loggedInUser: any;
  @ViewChildren('resultItem') listItems: QueryList<ElementRef>;
  @ViewChild('globalSearchInput') globalSearchInput: ElementRef;
  @ViewChild('searchBar') searchBar: ElementRef;

  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.ctrlKey && (event.key === 'g' || event.key === 'G')) {
      event.preventDefault();
      if (this.globalSearchInput) {
        this.globalSearchInput.nativeElement.focus();
        this.isGlobalSearchFocused = true;
      }
    }

    if (this.isGlobalSearchFocused) {
      const itemsArray = this.listItems.toArray();
      const currentIndex = itemsArray.findIndex(item => item.nativeElement === document.activeElement);

      if (event.key === 'Escape' || event.key === 'Tab') {
        this.globalSearch = "";
        this.globalSearchInput.nativeElement.blur();
        this.onBlur();
        this.setSearchCardsToRecentSearches();
      } else if (event.key === 'ArrowDown') {
        event.preventDefault();
        const nextIndex = (currentIndex + 1) % itemsArray.length;
        itemsArray[nextIndex].nativeElement.focus();
      } else if (event.key === 'ArrowUp') {
        event.preventDefault();
        const prevIndex = (currentIndex - 1 + itemsArray.length) % itemsArray.length;
        itemsArray[prevIndex].nativeElement.focus();
      } else {
        this.globalSearchInput.nativeElement.focus();
      }
    }
  }

  // HostListener to detect clicks outside the component
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    // Check if the click is outside the .search-bar-container element
    const clickedInside = this.elementRef.nativeElement.querySelector('.search-bar-container').contains(event.target);
    if (!clickedInside) {
      this.onBlur();
    }
  }

  constructor(private elementRef: ElementRef, public utils: UtilsService,
    private globalSearchService: GlobalSearchService, private router: Router,private _cookieService : CookieService) {
    if (this._cookieService.get('loggedInUser') != undefined) {
      this.loggedInUser = JSON.parse(this._cookieService.get('loggedInUser'));
    }
    this.moduleList = JSON.parse(localStorage.getItem('moduleList'));
  }

  ngOnInit() {
    this.userNavigations = this.globalSearchService.generateRouterLinks(ROUTERLINKS, MODULES, DEFAULT_MODULE);
    if (this.moduleList != null) {
      for (let i = 0; i < this.moduleList.length; i++) {
        this.userNavigations.forEach(navigation => {
          if (this.moduleList[i].moduleId === navigation.moduleInfo.moduleId && this.utils.checkModulePermissions(navigation.moduleInfo.moduleId)) {
            navigation.moduleInfo.show = true;
          } else {
            if (navigation.moduleInfo.modulesrc === "styria" && this.loggedInUser.orgId === 1) {
              navigation.moduleInfo.show = true;
            }
          }
        });
      }
    }
    this.userNavigations.forEach(navigation => {
      if (typeof navigation.show !== 'boolean') {
        const show = this.utils.getPermissions(navigation.show.moduleId, navigation.show.actionId);
        navigation.show = show;
      }
      // console.log(navigation.route + " " + navigation.show);
      const show = navigation.show && navigation.moduleInfo.show;
      navigation.show = show;
    });

    this.userNavigations = this.userNavigations.filter(item => item.show !== false);
    this.searchedNavigations = {
      "Recent Searches": this.getRecentSearch(),
    }

    // this.searchedNavigations = {
    //   "Navigations": this.userNavigations,
    // }
    const options = {
      keys: ['keywords'],
      threshold: 0.1,
      location: 0,
      distance: 100,
      isCaseSensitive: false,
      includeMatches: true,
      // includeScore: true,
      // useExtendedSearch: true
    };
    this.fuzzySearcher = new Fuse(this.userNavigations, options);
    // const searchResult = fuse.search('the great gatsby');
    // console.log(searchResult);
    // this.fuzzySearcher = new FuzzySearch(this.userNavigations, ['keywords'], {
    //   caseSensitive: false
    // });

    this.submitGlobalSearchDebounce.pipe(debounceTime(300)).subscribe(() => {
      this.onGlobalSearch(this.globalSearch);
    });
  }

  ngAfterViewInit() {
    this.listItems.changes.subscribe(() => {
      this.onListItemsChange(this.listItems);
    });
  }

  @HostListener('window:resize', ['$event'])
  onScreenResize(event: Event) {
    this.updateResultsContainerWidth();
  }

  updateResultsContainerWidth() {
    const searchInputWidth = this.searchBar.nativeElement.offsetWidth;
    this.searchBarWidth = searchInputWidth;
    this.searchBarWidth = this.searchBarWidth + 'px';
    // if(this.searchBarWidth<500){
    //   this.searchBarWidth="90vw";
    // }else{
    // }
    // console.log(this.searchBarWidth);
  }


  onListItemsChange(resultItems) {
    const itemsArray = resultItems.toArray();

    if (itemsArray.length > 0) {
      itemsArray[0].nativeElement.focus();
    }
  }


  onFocus() {
    this.isGlobalSearchFocused = true;
    this.updateResultsContainerWidth();
  }

  onBlur() {
    this.isGlobalSearchFocused = false;
  }

  onResultCardClick(cardInfo) {
    // console.log('route');
    this.router.navigate([cardInfo.route]);
    this.onBlur();
    this.globalSearch = "";
    this.onGlobalSearch("");
    this.addRecentSearch(cardInfo);
    this.setSearchCardsToRecentSearches();
  }

  addRecentSearch(newActivity: any): void {
    const activitiesStr = sessionStorage.getItem(this.recentSearchKey);
    // console.log(activitiesStr);
    let activities = activitiesStr ? JSON.parse(activitiesStr) : [];

    const existingIndex = activities.findIndex(activity => activity.route === newActivity.route);
    if (existingIndex !== -1) {
      activities.splice(existingIndex, 1);
      // console.log(activitiesStr);
    }
    activities.push(newActivity);


    if (activities.length > 3) {
      activities.shift();
    }
    sessionStorage.setItem(this.recentSearchKey, JSON.stringify(activities));
  }

  getRecentSearch(): any[] {
    const activitiesStr = sessionStorage.getItem(this.recentSearchKey);
    return activitiesStr ? JSON.parse(activitiesStr) : [];
  }

  onInputChange() {
    this.submitGlobalSearchDebounce.next();
  }

  onGlobalSearch(query) {
    if (this.utils.isStringNullorEmpty(query)) {
      this.setSearchCardsToRecentSearches();
    } else {
      this.searchedNavigations = {};
      // this.searchedNavigations["Navigations"] = this.globalSearchService.fuzzySearch(query, this.userNavigations, 2);
      const results = this.fuzzySearcher.search(query);

      const filteredResults = results.map(result => {
        const firstMatch = result.matches[0];
        let matchedKeyword = firstMatch.value || '';

        if (firstMatch && firstMatch.indices.length > 0) {
          // Sort indices to ensure correct order
          const sortedIndices = firstMatch.indices.sort((a, b) => a[0] - b[0]);

          // Initialize the wrapped keyword
          let wrappedKeyword = '';
          let lastIndex = 0;

          sortedIndices.forEach(([start, end]) => {
            // Add the part before the matched segment
            wrappedKeyword += matchedKeyword.slice(lastIndex, start);
            // Wrap the matched segment in <strong> tags
            wrappedKeyword += `<strong>${matchedKeyword.slice(start, end + 1)}</strong>`;
            // Update the last index
            lastIndex = end + 1;
          });

          // Add any remaining part after the last match
          wrappedKeyword += matchedKeyword.slice(lastIndex);

          matchedKeyword = wrappedKeyword;
        }

        return {
          ...result.item,
          matchedKeyword: matchedKeyword
        };
      });

      this.searchedNavigations["Navigations"] = filteredResults;
      // const indexes = this.fuzzySearcher.search(query).indexes();

      if (this.searchedNavigations["Navigations"].length === 0) {
        this.searchedNavigations = {}
      }
    }
  }

  setSearchCardsToRecentSearches() {
    this.searchedNavigations = {};
    this.searchedNavigations[this.recentSearchKey] = this.getRecentSearch().reverse();
  }

  ifSearchResultsEmpty() {
    return Object.keys(this.searchedNavigations).length === 0 ? true : false;
  }


  onResultKeyDown(event: KeyboardEvent, searchedItem: any) {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.onResultCardClick(searchedItem);
    }
  }

}
