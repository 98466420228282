import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SharedCommunicationService {
    private formIdSource = new BehaviorSubject<number>(0);

    formId$: Observable<number> = this.formIdSource.asObservable();

    sendFormId(formId: number) {
        this.formIdSource.next(formId);
    }
}