import { Injectable } from '@angular/core';
import { KRONOS } from '../../../app-constants';
import { API } from '../../../constants/index';
import { DataService } from '../core/interceptor.service';

@Injectable()
export class CustomerSpaceService {
  constructor(private dataService: DataService) { }

  getAllAccounts(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.CUSTOMER + API.GET_ALL_ACCOUNTS, req);
  }

  getAccountDetailsById(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.CUSTOMER + API.GET_ACCOUNT_DETAILS_BY_ID, req);
  }

  getProjectsByAccountId(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.CUSTOMER + API.GET_ALL_ACCOUNT_PROJECTS, req);
  }

  getAccountProjectDetailsById(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.CUSTOMER + API.GET_ACCOUNT_PROJECT_DETAILS, req);
  }

  addUpdateAccount(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.CUSTOMER + API.ADD_UPDATE_ACCOUNT, req);
  }

  deleteAccount(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.CUSTOMER + API.DELETE_ACCOUNT, req);
  }

  getAllCustomerNotes(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.CUSTOMER + API.GET_ALL_CUSTOMER_NOTES, req);
  }

  addUpdateCustomerNote(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.CUSTOMER + API.ADD_UPDATE_CUSTOMER_NOTE, req);
  }

  deleteCustomerNote(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.CUSTOMER + API.DELETE_CUSTOMER_NOTE, req);
  }

  createAccountsProject(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.CUSTOMER + API.CREATE_ACCOUNTS_PROJECT, req);
  }

  updateAccountsProject(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.CUSTOMER + API.UPDATE_ACCOUNTS_PROJECT, req);
  }

  deleteAccountsProject(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.CUSTOMER + API.DELETE_ACCOUNTS_PROJECT, req);
  }

  getAccountContacts(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.CUSTOMER + API.GET_ACCOUNT_CONTACTS, req);
  }

  addUpdateAccountContact(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.CUSTOMER + API.ADD_UPDATE_ACCOUNT_CONTACT, req);
  }

  deleteAccountContact(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.CUSTOMER + API.DELETE_ACCOUNT_CONTACT, req);
  }

  getCustomerContract(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.CUSTOMER + API.GET_CUSTOMER_CONTRACT, req);
  }

  addUpdateCustomerContract(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.CUSTOMER + API.ADD_UPDATE_CUSTOMER_CONTRACT, req);
  }

  deleteCustomerContract(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.CUSTOMER + API.DELETE_CUSTOMER_CONTRACT, req);
  }

  getAccountWiseCustomerContract(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.CUSTOMER + API.GET_ACCOUNT_CUSTOMER_CONTRACT, req);
  }


}
