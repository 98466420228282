import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { CompRouterService } from '../../../../helpers/services/index';
import { ROUTERLINKS } from 'app/constants';
import { SharedCommunicationService } from 'app/helpers/services/communication.service';

@Component({
  selector: 'app-onboarding-card',
  templateUrl: './onboarding-card.component.html',
  styleUrls: ['./onboarding-card.component.scss']
})
export class OnboardingCardComponent implements OnInit {
  @Input() formInfo: any;
  flag: boolean = false;

  constructor(public compRouter: CompRouterService, private router: Router,private communicationService: SharedCommunicationService) { }

  ngOnInit() {
  }

  startSurvey(formId: number) {
    this.communicationService.sendFormId(formId);
    this.compRouter.routeToContextUrls("onboarding-feedback-form");
  }
}
