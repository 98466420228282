import { Injectable } from '@angular/core';
import { KRONOS, STYRIA } from '../../../app-constants';
import { API } from '../../../constants/index';
import { Subject } from 'rxjs';
import { DataService } from '../core/interceptor.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  private object = new Subject<any>();
  recieveProfile$ = this.object.asObservable();
  userList: any = [];
  userSkillList: any[];
  userProfile(user: any) {
    this.object.next(user);
  }
  constructor(private dataService: DataService) { }

  getAllBasedOnFilter(obj) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_USERS, obj);
  }

  /*
* API is used to display all the users
*/
  getAll() {
    const req = {
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_USERS, req);
  }
  getAllUsers(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_USERS, req);
  }

  getUsersByDistance(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_USERS_BY_DISTANCE, req);
  }
  /*
* API is used to get user details based on the id
*/
  getUserById(id) {
    const req = {
      userId: id
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_USER_BY_ID, req);
  }

  getAllBusinessUnit() {
    const req = {}
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_BUSINESS_UNIT, req);
  }

  getAllDesignation() {
    const req = {}
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_DESIGNATION, req);
  }
  getAllTeam() {
    const req = {
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_TEAMS, req);
  }

  getTeamUserCount() {
    const req = {
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_TEAMWISE_USER_COUNT, req);
  }

  /*
* API is used to add the user
*/
  add(user): any {
    user.username = user.email;
    user.emergencyContactList = [];
    user.socialContactList = [];
    return this.dataService.put(KRONOS.BASE + KRONOS.USER + API.ADD_USER, user);
  }
  /*
* API is used to add Bulk the user
*/
  addBulkUsersData(user): any {
    const req = {
      userList: [user]
    }
    return this.dataService.put(KRONOS.BASE + KRONOS.USER + API.ADD_BULK_USER, req);
  }
  /*
     * This API will update the user details based on the  Id
   */
  update(user) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.UPDATE_USER, user);
  }

  updateUserTimezone(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.UPDATE_USER_TIMEZONE, req);
  }

  updateUserLocation(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.UPDATE_USER_LOCATION, req);
  }

  updateOthersProfile(user) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.UPDATE_OTHER_USER_PROFILE, user);
  }

  getUserBySkill(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.ANALYTICS + API.GET_USERS_BY_SKILL, req);
  }

  downloadExcel() {
    const req = {
    }
    // let formData:FormData = new FormData();
    //  formData.append('sessionId ',this.sessionId );
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.DOWNLOAD_EXCEL_TEMPLATE, req);
  }

  bulkAddUser(name) {
    const req = {
      name: name
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ADD_BULK_USER, req);
  }

  getAllSkills() {
    const req = {}
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_SKILLS, req);
  }

  updateUserSkill(skillList, id, mentorId, managerId) {
    const req = {
      userSkills: skillList,
      userId: id,
      mentorId: mentorId,
      managerId: managerId
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.UPDATE_USER_SKILL, req);
  }

  deleteSkill(id) {
    const req = {
      id: id
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.DELETE_SKILL, req);
  }

  addSkill(name, description) {
    const req = {
      name: name,
      description: description
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ADD_SKILL, req);
  }

  updateAdminSkill(skillId, skillName, skillDescription) {
    const req = {
      id: skillId,
      name: skillName,
      description: skillDescription
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.UPDATE_SKILL, req);
  }
  /*
*API is used get users based role
*/
  getRoleBasedUsers(orgId) {
    const req = {
      orgId: orgId
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_USERS_BY_BASIC_DETAILS, req);
  }
  /*
  *API is used to display all the sites
  */
  getAllSites() {
    const req = {
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_SITES, req);
  }
  /*
 *API is used to add sites
 */
  addSite(site) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ADD_SITE, site);
  }
  /*
 *API is used to update sites
 */
  updateSite(obj) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.UPDATE_SITE, obj);
  }
  /*
 *API is used to delete site
 */
  deleteSite(id) {
    const req = {
      id: id
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.DELETE_SITE, req);
  }
  /*
   *API is used to display user compliance
   */
  getCompliance(obj) {
    return this.dataService.post(KRONOS.BASE + KRONOS.ANALYTICS + API.GET_COMPLIANCE, obj);
  }

  /*
 *API is used to display total time reported
 */
  getTotalTimeReported(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.ANALYTICS + API.GET_TOTAL_TIME_REPORTED, req);
  }
  /*
*API is used to display total leave in hours
*/
  getTotalLeaveHours(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.ANALYTICS + API.GET_TOTAL_LEAVE_HOURS, req);
  }
  /*
*API is used to display total leave in count
*/
  getTotalLeaveCount(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.ANALYTICS + API.GET_TOTAL_LEAVE_COUNT, req);
  }
  getTotalLeaveByYearPerUser(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.ANALYTICS + API.GET_TOTAL_LEAVE_BY_YEAR_PER_USER, req);
  }

  getWeekWiseProjectData(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.ANALYTICS + API.GET_USER_WISE_PROJECT_DETAILS, req);
  }
  getProjectWiseTaskPerWeek(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.ANALYTICS + API.GET_PROJECT_WISE_TASK_WISE_DETAILS, req);
  }
  getDayWiseProjectData(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.ANALYTICS + API.GET_DAY_WISE_PROJECT_DETAILS, req);
  }
  getAllProjectWorked(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.ANALYTICS + API.GET_ALL_PROJECT_WORKED, req);
  }

  getUserTimeLock() {
    return this.dataService.get(KRONOS.BASE + KRONOS.USER + API.GET_USER_TIME_LOCK);
  }

  updateUserTimeLock(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.UPDATE_USER_TIME_LOCK, req);
  }

  getUserSkillList(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_USER_SKILL_LIST, req);
  }

  getAllSkillsAndUserCount() {
    return this.dataService.get(KRONOS.BASE + KRONOS.USER + API.GET_ALL_SKILLS_AND_COUNT);
  }

  getAllUserSkills() {
    return this.dataService.get(KRONOS.BASE + KRONOS.USER + API.GET_ALL_USER_SKILLS);
  }

  updatePin(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.UPDATE_PIN, req);
  }

  getSkillApproval(id) {
    const req = {
      menteeId: id
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_SKILL_APPROVAL, req);
  }

  getUnApprovedSkills(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_UNAPPROVED_SKILL, req);
  }

  addApprovedSkill(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ADD_APPROVED_SKILL, req);
  }

  deleteApprovedSkill(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.DELETE_APPROVED_SKILL, req);
  }

  getMenteesManagerees(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_MENTEE_MANAGEREE, req);
  }

  getZohoLeavesByMonth(req) {
    return this.dataService.getWithHeaders(STYRIA.PRODGATEWAY + STYRIA.INTEGRATION_SERVICE_VER + STYRIA.ZOHO_PEOPLE + API.GET_ZOHO_LEAVES_BY_MONTH + "/" + req.year, req.headers);
  }

  getKronosLeavesByMonth(req) {
    return this.dataService.getWithHeaders(STYRIA.PRODGATEWAY + STYRIA.INTEGRATION_SERVICE_VER + STYRIA.KRONOS + API.GET_KRONOS_LEAVES_BY_MONTH + "/" + req.year, req.headers);
  }

}
