import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { UtilsService, CompRouterService } from '../../helpers/services/index';
import { SharedSkillService } from '../trainings/shared-skill-component';

@Component({
  selector: 'app-training-directive',
  templateUrl: './training-directive.component.html',
  styleUrls: ['./training-directive.component.scss'],
})

export class TrainingDirectiveComponent implements OnInit {
  searchString: any;
  loggedInUser: any;

  selectedSkills: any[] = []; 
  previousSkillTag: any;

  @Input() generalEvents: any;
  @Input() filterCount: any;
  @Input() removedSkill: any;

  @Output() joinTraining = new EventEmitter<Object>();
  @Output() requestATraining = new EventEmitter<Object>();
  @Output() deleteTrainingRequest = new EventEmitter<Object>();
  @Output() closeTrainingRequest = new EventEmitter<Object>();
  @Output() goTo = new EventEmitter<Object>();
  @Output() removeAllFilters = new EventEmitter<Object>();
  @Output() skillTags = new EventEmitter<any>();

  constructor(public utils: UtilsService, public compRouter: CompRouterService, private _cookieService: CookieService,
    private sharedSkillService: SharedSkillService
  ) {
    if (this._cookieService.get('loggedInUser') != undefined) {
      this.loggedInUser = JSON.parse(this._cookieService.get('loggedInUser'));
    }
  }

  ngOnInit() {
  }

  joinTrainer(id, i) {
    const trainingRequestDetails = {
      'trainingId': id,
      'index': i
    }
    this.joinTraining.emit(trainingRequestDetails);
  }

  requestTraining(id, i) {
    const requestDetails = {
      'trainingId': id,
      'index': i
    }
    this.requestATraining.emit(requestDetails);
  }

  deleteRequest(id) {
    const deleteRequest = {
      'id': id
    }
    this.deleteTrainingRequest.emit(deleteRequest);
  }

  closeRequest(id) {
    const closeRequest = {
      'id': id
    }
    this.closeTrainingRequest.emit(closeRequest);
  }

  routing(type, id) {
    let data = {
      type: type,
      id: id
    }
    this.goTo.emit(data);
  }

  clearFilters() {
    this.removeAllFilters.emit();
    this.sharedSkillService.clear();
  }


  toggleSelection(skill: any) {
    this.selectedSkills = this.sharedSkillService.getList();
    const isSkillSelected = this.selectedSkills.includes(skill.id);

    if (!isSkillSelected) {
        this.sharedSkillService.add(skill.id);
    } else {
        this.sharedSkillService.remove(skill.id);
    }
    this.emitSkillTags(skill);
  }


  isSelected(skill: any): boolean {
    this.selectedSkills = this.sharedSkillService.getList();
    return this.selectedSkills.includes(skill);

  }

  emitSkillTags(skill: any) {
    this.skillTags.emit(skill);
  }

}
