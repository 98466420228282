import { Injectable } from '@angular/core';
import { DataService } from '../core/interceptor.service';
import { KRONOS } from '../../../app-constants';
import { API } from '../../../constants/index';

@Injectable()
export class FeatureService {
  feature: any = {};
  constructor(private dataService: DataService) { }

  addNew(feature) {
    this.feature = feature;
    const req = {
      name: this.feature.name, description: this.feature.description, active: this.feature.active
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ADD_NEW_FEATURE, req);
  }

  updateFeature(feature) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.UPDATE_FEATURE, feature);
  }

  deleteFeature(id) {
    const req = {
      id: id
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.DELETE_FEATURE, req);
  }

  getAll() {
    const req = {}
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_FEATURE, req);
  }
}


