import { Component, Input, Output, EventEmitter, ViewChild, OnChanges, HostListener } from '@angular/core';
import { DaterangePickerComponent } from 'ng2-daterangepicker';
import { DaterangepickerConfig } from 'ng2-daterangepicker';
import { UtilsService } from 'app/helpers/services';
import * as moment from 'moment';
declare var $: any;

@Component({
  selector: 'app-report-template',
  templateUrl: './report-template.component.html',
  styleUrls: ['./report-template.component.scss']
})
export class ReportTemplateComponent implements OnChanges {

  @Input() sourceList: any =[];
  @Input() constValue: any;
  @Input() tableBody: any;
  @Output() report = new EventEmitter<Object>();
  selectedFilterArray: any;
  unSelectedFilterArray: any;
  selectedSourceData: any;
  task: any;
  task1: any;
  request: any = {};
  sourceData: any;
  selectedDateRange: any;
  loading: any;
  date: moment.Moment;
  a2eOptions: any;
  selectedMonth: string;
  calendarToggle: boolean = false;
  currentYear: string;
  monthNames = [{ id: '01', name: 'January' }, { id: '02', name: 'February' }, { id: '03', name: 'March' }, { id: '04', name: 'April' },
  { id: '05', name: 'May' }, { id: '06', name: 'June' }, { id: '07', name: 'July' }, { id: '08', name: 'August' },
  { id: '09', name: 'September' }, { id: '10', name: 'October' }, { id: '11', name: 'November' }, { id: '12', name: 'December' }];
  inside: boolean = false;
  itemsPerRow = 3;
  rows = Array.from(Array(Math.ceil(this.monthNames.length / this.itemsPerRow)).keys());
  @ViewChild(DaterangePickerComponent)
  private picker: DaterangePickerComponent;

  public options: any = {
    locale: { format: 'YYYY-MM-DD' },
    alwaysShowCalendars: false,
  };

  constructor(public daterangepickerOptions: DaterangepickerConfig, public utils: UtilsService) {
    this.daterangepickerOptions.settings =
    {
      locale: { format: 'YYYY-MM-DD' },
      alwaysShowCalendars: false,
      ranges: {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'This Week': [moment().day(1), moment().day(7)],
        'Last Week': [moment().subtract(1, 'weeks').startOf('isoWeek'), moment().subtract(1, 'weeks').endOf('isoWeek')],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
        'Last 3 Months': [moment().subtract(3, 'month'), moment()],
        'Last 6 Months': [moment().subtract(6, 'month'), moment()],
        'Last 12 Months': [moment().subtract(12, 'month'), moment()]
      }
    };
    this.date = moment();
    this.a2eOptions = { format: 'YYYY-MM' };
    this.selectedMonth = moment(this.date).format('YYYY-MMMM');
    this.currentYear = moment(this.date).format('YYYY');
    this.request.date = moment(this.date).format('YYYY-MM');

  }

  ngOnChanges() {
    if (this.tableBody) {
      this.loading = false;
    }
  }

  dateToChange(date) {
    this.date = date;
    this.request.date = moment(this.date, 'YYYY-MM').format('YYYY-MM');
  }

  yearChange(value) {
    if (value == 'increment') {
      const date = moment(this.currentYear, 'YYYY').add('years', 1);
      this.currentYear = moment(date).format('YYYY');
    } else {
      const date = moment(this.currentYear, 'YYYY').subtract('years', 1);
      this.currentYear = moment(date).format('YYYY');
    }
  }

  getDate(obj) {
    this.selectedMonth = this.currentYear + '-' + obj.name;
    this.request.date = this.currentYear + '-' + obj.id;
    this.calendarToggle = false;
    this.inside = false;
  }

  // Month picker
  @HostListener('click')
  clickInside() {
    this.inside = true;
  }

  @HostListener('document:click')
  clickout() {
    if (!this.inside) {
      this.calendarToggle = false;
    }
    this.inside = false;
  }

  selectedSource(event) {
    if (event) {
      this.request.id = event.id
      // this.sourceData = '(' + event.id + ') ' + event.name
      this.selectedSourceData = event;
      this.tableBody = '';
    }
  }

  clearsourceReportData() {
    if (this.sourceData) {
      this.sourceData = '';
      this.selectedSourceData = '';
    }
  }

  calendarApplied() {
    this.request.startDate = this.picker.datePicker.startDate.format('YYYY-MM-DD');
    this.request.endDate = this.picker.datePicker.endDate.format('YYYY-MM-DD');
    this.selectedDateRange = this.request.startDate + ' - ' + this.request.endDate;
  }

  openNewFilterModal() {
    if (this.constValue.filter && localStorage.getItem(this.constValue.filter) != null) {
      const localStorageItems = JSON.parse(localStorage.getItem(this.constValue.filter));
      this.selectedFilterArray = localStorageItems.selected;
      this.unSelectedFilterArray = localStorageItems.unSelected
    } else {
      this.selectedFilterArray = this.constValue.selectedFilterArray;
      this.unSelectedFilterArray = this.constValue.unSelectedFilterArray;
    }
    $('#newFilterModal').modal('show');
  }

  dragStart(event, filter) {
    this.task = filter;
  }

  dragStart1(event, filter) {
    this.task1 = filter;
  }

  drop(event) {
    if (this.task1) {
      this.selectedFilterArray.push(this.task1);
      this.removeItem(this.task1, this.unSelectedFilterArray);
    }
  }

  drop1(event) {
    if (this.task) {
      if (!this.unSelectedFilterArray) {
        this.unSelectedFilterArray = [];
      }
      this.unSelectedFilterArray.push(this.task);
      this.removeItem(this.task, this.selectedFilterArray);
    }
  }

  removeItem(item: any, list: Array<any>) {
    const index = list.map(function (e) {
      return e
    }).indexOf(item);
    list.splice(index, 1);
  }

  dragEnd(event) {
    this.task = null;
  }

  dragEnd1(event) {
    this.task1 = null;
  }

  applyFiltersAction() {
    this.constValue.selectedFilterArray = []; this.constValue.unSelectedFilterArray = []; this.constValue.cols = [];
    this.constValue.selectedFilterArray = this.selectedFilterArray;
    this.constValue.unSelectedFilterArray = this.unSelectedFilterArray;
    const header = this.utils.reportHeader(this.selectedFilterArray, this.constValue.tableHeader);
    this.constValue.cols = header;
    if (this.constValue.filter) {
      localStorage.setItem(this.constValue.filter,
        JSON.stringify({ 'selected': this.selectedFilterArray, 'unSelected': this.unSelectedFilterArray }));
    }
    $('#newFilterModal').modal('hide');
  }

  resetAppliedFilter() {
    this.constValue.selectedFilterArray = []; this.selectedFilterArray = []; this.unSelectedFilterArray = [];
    this.constValue.tableHeader.forEach(element => {
      this.constValue.selectedFilterArray.push(element.header);
    });
    this.selectedFilterArray = this.constValue.selectedFilterArray;
    this.constValue.unSelectedFilterArray = []; this.constValue.cols = [];
    this.constValue.cols = this.constValue.tableHeader;
    if (this.constValue.filter) {
      localStorage.setItem(this.constValue.filter,
        JSON.stringify({ 'selected': this.selectedFilterArray, 'unSelected': this.unSelectedFilterArray }));
    }
    $('#newFilterModal').modal('hide');
  }

  getReports() {
    if (this.selectedSourceData && (this.selectedDateRange || (this.constValue.monthPicker && this.request.date) || this.constValue.noDate)) {
      this.loading = true;
      if (!this.constValue.monthPicker) {
        delete this.request.date;
      }
      const obj = {
        req: this.request,
        sourceData: this.selectedSourceData,
        reportObj: this.constValue
      }
      this.report.emit(obj);
    }
  }

}
