import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'FilterPipe',
  pure: false
})
export class FilterPipe implements PipeTransform {
  transform(items: any, filter: any, isAnd: boolean): any {
    if (filter && Array.isArray(items)) {
      let filterKeys = Object.keys(filter);
      if (isAnd) {
        return items.filter(item => {
          let found = true;
          filterKeys.forEach((keyName) => {
            let search = item;
            keyName.split('.').forEach((key) => { search = search[key] });
            found = found && (new RegExp(filter[keyName], 'gi').test(search) || filter[keyName] === "");
            // filterKeys.reduce((memo, keyName) =>
            // (memo && new RegExp(filter[keyName], 'gi').test(item[keyName])) || filter[keyName] === "", true)
          })
          return found;
        });
      } else {
        return items.filter(item => {
          return filterKeys.some((keyName) => {
            let search = item;
            keyName.split('.').forEach((key) => { search = search[key] });
            return new RegExp(filter[keyName], 'gi').test(search) || filter[keyName] === "";
          });
        });
      }
    } else {
      return items;
    }
  }
}