import { Injectable } from '@angular/core';
import { KRONOS } from '../../../app-constants';
import { API } from '../../../constants/index';
import { DataService } from '../core/interceptor.service';

@Injectable()
export class PmsService {

  constructor(private dataService: DataService) { }

  addGoal(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ADD_GOAL, req);
  }
  getAllGoal() {
    const req = {
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_GOAL, req);
  }
  updateGoal(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.UPDATE_GOAL, req);
  }
  deleteGoal(id) {
    const req = {
      id: id
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.DELETE_GOAL, req);
  }
  // addSection(req) {
  //   return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ADD_SECTION, req);
  // }
  updateSection(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.UPDATE_SECTION, req);
  }
  // addTemplate(req) {
  //   return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ADD_TEMPLATE, req);
  // }
  // updateTemplate(req) {
  //   return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.UPDATE_TEMPLATE, req);
  // }
  // deleteGoalTemplate(req) {
  //   return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.DELETE_TEMPLATE, req);
  // }
  // getAllGoalTEmplate() {
  //   let req = {
  //     sessionId: this.utils.getSessionId()
  //   }
  //   return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_GOAL_TEMPLATE, req);
  // }
  // addTemplateToUser(req) {
  //   return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ADD_TEMPLATE_USER, req);
  // }
  getUserTemplate(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_USER_TEMPLATE, req);
  }
  updateUserGoalTemplate(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.UPDATE_USER_TEMPLATE, req);
  }
  // deleteUserGoal(req) {
  //   return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.DELETE_USER_GOAL, req);
  // }
}
