import { Injectable } from '@angular/core';
import { CompRouterService } from '../core/comp-router.service';
import { UtilsService } from '../core/utils.service';
import { ROUTERLINKS_VISIBILITY } from 'app/constants/router-link';
import { AdminModules } from 'app/helpers/models/administration/modules';
import { PERMISSIONS } from 'app/permissions';

@Injectable({
  providedIn: 'root'
})
export class GlobalSearchService {
  constructor(private compRouter: CompRouterService, public utils: UtilsService) { }

  private extractKeywords(text: string): string[] {
    if (!text) return [];

    // Split by '/' and replace `-` with space in each segment and trim whitespace
    let segments = text.split('/').map(segment =>
      segment.replace(/-/g, ' ').trim()
    );

    // Separate camel case words
    let keywords: string[] = [];
    segments.forEach(segment => {
      const splitSegment = segment.split(/(?=[A-Z])/).join(' ');
      keywords = keywords.concat(splitSegment);
    });

    return keywords;
  }

  public getModuleInfo(route: string, MODULES_REF: any, DEFAULT_MODULE: any): any {
    const firstWord = route.split('/')[0];
    for (const key in MODULES_REF) {
      if (MODULES_REF[key].routeURL) {
        const moduleFirstWord = this.compRouter.routerMap.get(MODULES_REF[key].routeURL).split('/')[0];
        if (firstWord === moduleFirstWord) {
          return MODULES_REF[key];
        }
      }
    }
    return DEFAULT_MODULE;
  }

  getAdminModuleName(route) {
    if (!route) return [];

    // Split by '/' and replace `-` with space in each segment and trim whitespace
    let segments = route.split('/');

    var adminModuleInfo = null;

    if(segments[1]){
      adminModuleInfo = AdminModules.Modules.find(obj => obj.route === segments[1]);
    }

    if( adminModuleInfo ){
      return adminModuleInfo.name;
    }
  }

  public generateRouterLinks(ROUTERLINKS: any, MODULES_REF: any, DEFAULT_MODULE: any): any[] {
    return Object.keys(ROUTERLINKS).map((key, index) => {
      const route = ROUTERLINKS[key];
      const moduleInfo = this.getModuleInfo(route, MODULES_REF, DEFAULT_MODULE);

      var adminModuleName;
      if (moduleInfo.moduleId === PERMISSIONS.MODULES.ADMINISTRATION) {
        adminModuleName = this.getAdminModuleName(route);
      }

      const moduleKeywords = [
        ...(moduleInfo.name ? this.extractKeywords(moduleInfo.name) : []),
        ...(moduleInfo.routeURL ? this.extractKeywords(moduleInfo.routeURL) : []),
        ...(adminModuleName ? [adminModuleName] : [])
      ];
      const routeKeywords = this.extractKeywords(route);

      const keywords = Array.from(new Set([...moduleKeywords, ...routeKeywords]));

      return {
        route: route,
        moduleInfo: moduleInfo,
        keywords: keywords,
        show: ROUTERLINKS_VISIBILITY[key]
      };
    });
  }
}
