export const URL = {
    ROOT: '/',
    DASHBOARD: 'dashboard',
    TIME: '/time-report/time',
    USER: '/user-directory',
    ADMIN: '/administration',
    REPORT: '/reports',
    HOLIDAY: '/holiday',
    EXPENSE_REPORT: '/expense-reporting',
    ALL_EXPENSE_REPORTS: '/expense-reporting/reports',
    LINKS: '/links',
    LINK_TAGS: '/links/tags',
    FEED: '/feed',
    FEEDS: '/feeds',
    SHARED_PROJECTS: 'sharedProjects',
    PROJECT_GROUPS: 'projectGroups',
    GROUPS_VIEW: 'projectGroups/view',
    GROUP_REPORT: 'projectGroups/reports',
    PROJECT_SHARING: '/projectSharing',
    ORGANIZATION_USER_REGISTRATION: 'organization-user-registration',
    INSIDER: '/insider',
    CALENDAR: '/calendar',
    TIME_DASHBOARD: '/time-dashboard',
    ORG_WISE_PROVISIONING: '/org-wise-provisioning',
    TRAININGS: '/trainings',
    POLICY: '/policies',
    PROJECT: '/projects',
    UTILIZATION: '/utilization',

    //  'PROFILE : '/profileOverview',
    PMS: '/pms',
    PROFILE: '/profile',

    // Style Guide
    STYLEGUIDE: 'styleguide',
    COLOR: 'styleguide/color',
    ELEMENTS: 'styleguide/elements',
    ICONS: 'styleguide/icons',
    FONTS: 'styleguide/fonts',

    TIME_EKSTEP: '/time-report/time-ekstep',
    USERS: 'reports/users',

    //notes
    NOTES: '/notes',

    EVENTS: 'events',
    INSIGHTS: '/insights',

    AWARD: '/awards',
    AVAILABILITY: '/availability',
    SKILLS: '/skills',
    FORECAST: '/forecast',
    NEARBY: '/nearby',
    IBUSPACE: '/ibu-space',
    CUSTOMERSPACE: '/portfolio',
    FOODREGISTER: '/food-register',
    FINANCE: '/finance',
    
    KNOWKRONOS: '/know-kronos',
    ESAT: '/esat',
    ONBOARDINGFORM: '/onboarding-feedback-form',
    VENDORS: '/vendors',
    INDUSTRIES: '/industries',
    SOLUTIONS: '/solutions',

    MICROLEARNING:'/micro-learning',

}
