import { Injectable } from '@angular/core';
import { DataService } from '../core/interceptor.service';
import { KRONOS } from '../../../app-constants';
import { API } from '../../../constants/index';

@Injectable()
export class TeamsService {

  constructor(private dataService: DataService) { }

  getAll() {
    const req = {
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_TEAMS, req);
  }

  add(name) {
    const req = {
      name: name
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ADD_TEAM, req);
  }

  update(id, name) {
    const req = {
      id: id, name: name
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.UPDATE_TEAM, req);
  }

  delete(id) {
    const req = {
      id: id
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.DELETE_TEAM, req);
  }
}
