import { Injectable } from '@angular/core';
import { KRONOS } from '../../../app-constants';
import { API } from '../../../constants/index';
import { Announcements } from '../../../helpers/models/index';
import { DataService } from '../core/interceptor.service';

@Injectable()
export class AnnouncementsService {

  constructor(private announcements: Announcements, private dataService: DataService) {
    this.announcements = announcements
  }

  getAll(startDate, endDate) {
    const req = {
      startDate, endDate
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_ANNOUNCEMENTS, req);
  }

  getAnnouncementById(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ANNOUNCEMENT_BY_ID, req);
  }

  addAnnouncement(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ADD_ANNOUNCEMENTS, req);
  }

  updateAllAnnouncement(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.UPDATE_ALL_ANNOUNCEMENT, req);
  }

  updateAnnouncement(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.UPDATE_ANNOUNCEMENTS, req);
  }

  deleteAnnouncement(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.DELETE_ANNOUNCEMENT, req);
  }

  deleteAllAnnouncement(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.DELETE_ALL_ANNOUNCEMENT, req);
  }

  addAnnouncementComment(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ADD_ANNOUNCEMENT_COMMENT, req);
  }

  updateAnnouncementComment(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.UPDATE_ANNOUNCEMENT_COMMENT, req);
  }

  deleteAnnouncementComment(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.DELETE_ANNOUNCEMENT_COMMENT, req);
  }

  getAnnouncementCommentByCommentId(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ANNOUNCEMENT_COMMENT_BY_COMMENT_ID, req);
  }
 
  getAllCommentsByAnnouncementId(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_COMMENTS_BY_ANNOUNCEMENT_ID, req);
  }

  updateLikesByUserId(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.UPDATE_LIKES_BY_USER_ID, req);
  }

  incrementViewedUserId(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.INCREMENT_VIEWED_USER_ID, req); 
  }
}
