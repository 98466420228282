import { Injectable } from '@angular/core';
import { KRONOS, STYRIA } from '../../../app-constants';
import { API } from '../../../constants/index';
import { DataService } from '../core/interceptor.service';

@Injectable()
export class IbuSpaceService {
  constructor(private dataService: DataService) { }

  getAllUserProjectAllocation(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_USER_PROJECT_ALLOCATION, req);
  }

  getUserProjectAllocationOverview(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_USER_PROJECT_ALLOCATION_OVERVIEW, req);
  }

  addBulkUsersToProjects(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ADD_BULK_USER_TO_PROJECTS, req);
  }

  getWeeklyUserProjectReport(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_WEEKLY_USER_PROJECT_REPORT, req);
  }

  getNonAllocatedUsers(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_NON_ALLOCATED_USERS, req);
  }

  getProjectsByUnit() {
    const req = {
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_PROJECTS_BY_UNIT, req);
  }
  getWeeklyProjectUserReport(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_WEEKLY_PROJECT_USER_REPORT, req);
  }

  getAllAccounts(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.CUSTOMER + API.GET_ALL_ACCOUNTS, req);
  }

  getAllProjectRevenueForecast(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_PROJECT_REVENUE_FORECAST, req);
  }

  addRevenueForecastForProject(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.PROJECT + API.ADD_REVENUE_FORECAST_FOR_PROJECT, req);
  }

  updateRevenueForecastForProject(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.PROJECT + API.UPADTE_REVENUE_FORECAST_FOR_PROJECT, req);
  }

  deleteRevenueForecastForProject(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.PROJECT + API.DELETE_REVENUE_FORECAST_FOR_PROJECT, req);
  }

  getProjectRevenueForecastOverview(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_PROJECT_REVENUE_FORECAST, req);
  }

  getIBULeaveReportFromKronos(req) {
    return this.dataService.getWithReqParams(STYRIA.PRODGATEWAY + STYRIA.INTEGRATION_SERVICE_VER + STYRIA.KRONOS + API.IBU_LEAVE_REPORT, req.params);
  }

  getIBULeaveReportFromZoho(req) {
    return this.dataService.getWithReqParams(STYRIA.PRODGATEWAY + STYRIA.INTEGRATION_SERVICE_VER + STYRIA.ZOHO_PEOPLE + API.IBU_LEAVE_REPORT, req.params);
  }
}
