import { Injectable } from '@angular/core';
import { KRONOS } from '../../../app-constants';
import { API } from '../../../constants/index';
import { DataService } from '../core/interceptor.service';

@Injectable({
  providedIn: 'root'
})
export class AwardsService {

  constructor(private dataService: DataService) { }

  getAllAwards() {
    return this.dataService.get(KRONOS.BASE + KRONOS.AWARD + API.GET_ALL_AWARD);
  }

  createUpdateAward(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.AWARD + API.CREATE_UPDATE_AWARD, req);
  }

  getAwardById(id) {
    return this.dataService.get(KRONOS.BASE + KRONOS.AWARD + API.GET_AWARD_BY_ID + '/' + id);
  }

  getAwardByRoleId() {
    return this.dataService.get(KRONOS.BASE + KRONOS.AWARD + API.GET_AWARD_BY_ROLE);
  }

  getReceivedAward() {
    return this.dataService.get(KRONOS.BASE + KRONOS.AWARD + API.GET_RECEIVED_AWARD);
  }

  getReceivedAwardByUserId(id) {
    return this.dataService.get(KRONOS.BASE + KRONOS.AWARD + API.GET_RECEIVED_AWARD_BY_USERID + '/' + id);
  }

  getIssuedAward() {
    return this.dataService.get(KRONOS.BASE + KRONOS.AWARD + API.GET_ISSUED_AWARD);
  }

  issueAward(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.AWARD + API.ISSUE_AWARD, req);
  }

  updateIssuedAward(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.AWARD + API.UPDATE_ISSUED_AWARD, req);
  }

  getLatestReceivedAward() {
    return this.dataService.get(KRONOS.BASE + KRONOS.AWARD + API.GET_LATEST_RECEIVED_AWARD);
  }

  getAwardLeaderboard() {
    return this.dataService.get(KRONOS.BASE + KRONOS.AWARD + API.GET_AWARD_LEADERBOARD);
  }
}
