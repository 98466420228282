﻿import { Injectable } from '@angular/core';
import { DataService } from '../core/interceptor.service';
import { KRONOS } from '../../../app-constants';
import { API } from '../../../constants/index';
import { CookieService } from 'ngx-cookie';

@Injectable()
export class AuthenticationService {
    sessionId: any;
    sessionObj: any = {};
    growlData: any = {};

    constructor(private dataService: DataService, private _cookieService: CookieService) {
    }

    login(username: string, password: string) {
        const obj = {
            username: username,
            password: password
        }
        return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.LOGIN, obj);
    }

    getRolesByOrgLevel() {
        const req = {};
        return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ROLES_BY_ORG, req);
    }

    /**
 * gives release version
 */
    getVersion() {
        const req = {};
        return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_VERSION, req);
    }

    logout() {
        if (this._cookieService.get('loggedInUser') !== undefined) {
            this.sessionId = this._cookieService.get('loggedInUser');
            this.sessionId = JSON.parse(this.sessionId);
            if (this.sessionId.sessionId) {
                const req = {
                    sessionId: this.sessionId.sessionId
                };
                return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.LOGOUT, req);
            }
        }
    }

    forgotPassword(req) {
        return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.FORGOT_PASSWORD, req);
    }
    changepassword(oldpassword: string, newpassword: string, confirmPassword: string) {
        const req = {
            oldPass: oldpassword, newPass: newpassword, confirmNewPass: confirmPassword
        }
        return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.CHANGE_PASSWORD, req);
    }

    authLogin(googleEmail, googleClientId, googleIdToken) {
        const obj = {
            username: googleEmail,
            googleEmail: googleEmail,
            googleClientId: googleClientId,
            googleIdToken: googleIdToken
        }
        return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.LOGIN, obj);
    }

    authMsLogin(msEmail, msClientId, msIdToken) {
        const obj = {
            username: msEmail,
            msEmail: msEmail,
            msClientId: msClientId,
            msIdToken: msIdToken
        }
        return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.LOGIN, obj);
    }
    /**
     * @param  {Object} req
     */
    getAuthorizedPermissions(req) {
        return this.dataService.post(KRONOS.BASE + KRONOS.ROLES + API.GET_AUTHORIZED_PERMISSIONS, req);
    }
}
