import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTERLINKS } from '../../constants/index';
import { UtilsService, CompRouterService } from 'app/helpers/services';

@Component({
  selector: 'app-notes-parent',
  templateUrl: './notes-parent.component.html',
  styleUrls: ['./notes-parent.component.scss']
})

export class NotesParentComponent implements OnInit {

  // addEvent: event emitter to emit values on calling addFunction
  @Output() addEvent = new EventEmitter();
  @Output() viewAllNotes = new EventEmitter();
  // Array to bind sibling/child component's data and display it from the reusable component
  @Input() events = [];
  selectObject: any = {};
  inputValue: string;
  constructor(private router: Router, public utils: UtilsService, public compRouter: CompRouterService) { }

  ngOnInit() {

  }

  // addEventFunction() : called when the form is filled and + button is click
  addEventFunction() {
    this.addEvent.emit(this.inputValue);
    $('#inputField').val('');
    this.inputValue = null;
  }

  routeFunction() {
    this.viewAllNotes.emit();
  }

  /**
   * selectFunction(): called on click of any notes - in the view project page 
   * and on select is routed to another page which has the details of the selected note 
   * and where CRUD operations can be performed
   * */
  selectNoteFunction(selectedObject, i) {
    // events are for a project's notes -- emit values can change based on requirements
    this.selectObject = {
      'name': selectedObject.name,
      'projectId': selectedObject.projectId,
      'id': selectedObject.id,
      'index': i,
    }
    this.routeEvent();
  }

  /**
   * routeEvent(): method called on selecting a notes (in the projects page)
   * to route to the notes page which has note id and project id as queryparams
   */
  routeEvent() {
    this.router.navigate([ROUTERLINKS.NOTES], {
      queryParams:
      {
        note: this.utils.idEncryption(this.selectObject.id),
        project: this.utils.idEncryption(this.selectObject.projectId),
      }
    });
  }
}


