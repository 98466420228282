import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard, GlobalEventsManager, LoginGuard } from './helpers/guards/index';
import { FeedbackComponent } from './modules/feedback/feedback.component';
import { SliderModule } from 'primeng/slider';

export const routes: Routes = [
  { path: '', loadChildren: 'app/core/core.module#CoreModule', canActivate: [LoginGuard] },
  { path: '', loadChildren: 'app/modules/main.module#MainModule', canActivate: [AuthGuard] },
  { path: 'feedback', component: FeedbackComponent }
];


@NgModule({
  declarations: [FeedbackComponent],
  imports: [RouterModule.forRoot(routes),
    CommonModule,
    FormsModule,
    SliderModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
