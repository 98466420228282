import { Injectable } from '@angular/core';
import { KRONOS } from '../../../app-constants';
import { API } from '../../../constants/index';
import { DataService } from '../core/interceptor.service';
import { LearningResource } from 'app/helpers/models';

@Injectable({
  providedIn: 'root'
})
export class LearningResourceService {

  constructor(private dataService: DataService) { }

  getAll() {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_LEARNING_RESOURCES, {});
  }

  getAllSkillsCount() {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_SKILLS_AND_RESOURCE_COUNT, {});
  }

  add(resource) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ADD_LEARNING_RESOURCE, resource);
  }
  updateResource(resource) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.UPDATE_LEARNING_RESOURCE, resource);
  }

  deleteResource(resource: LearningResource) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.DELETE_LEARNING_RESOURCE, resource);
  }
}
