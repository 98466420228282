import { Injectable } from '@angular/core';
import { DataService } from '../core/interceptor.service';
import { KRONOS } from '../../../app-constants';
import { API } from '../../../constants/index';
import { ExpenseCategories } from '../../../helpers/models/index';

@Injectable()
export class ExpenseCategoryService {

  constructor(private dataService: DataService, private category: ExpenseCategories) {
    this.category = category
  }

  getAllExpenseCategories() {
    const req = {
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.EXPENSE_CATEGORY, req);
  }

  addExpenseCategory(category) {
    this.category = category;
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ADD_EXPENSE, this.category);
  }

  updateExpenseCategory(category) {
    this.category = category;
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.UPDATE_EXPENSE, this.category);
  }

  deleteExpenseCategory(id) {
    const req = {
      id: id
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.DELETE_EXPENSE, req);
  }
}
