import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { UtilsService, CompRouterService } from '../../../../helpers/services/index';


@Component({
  selector: 'app-project-card',
  templateUrl: './project-card.component.html',
  styleUrls: ['./project-card.component.scss']
})
export class ProjectCardComponent implements OnInit {
  @Input() resource: any;
  @Input() projects: any;
  @Output() edit = new EventEmitter<Object>();
  @Output() routeToProject = new EventEmitter<Object>();
  searchString: any;

  constructor() { }

  ngOnInit() {
  }

  onEdit(data) {
    this.edit.emit(data);
  }

  routing(data) {
    this.routeToProject.emit(data);
  }

  getFilterKey(search) {
    return { [this.resource.nameField]: search }
  }

}
