import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CsatService, UtilsService } from '../../helpers/services/index';
import * as moment from 'moment';
import { MESSAGES } from 'app/constants';
declare var $: any;

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {

  feedbackQuestions: any;
  feedbackForm: any;
  submitted = false;
  validForm = false;
  responseRecorded = false;
  loggedInUser: any;
  getFeedback = false;
  comments: any;
  satisfaction = {
    answer: 0
  };

  constructor(private csatService: CsatService, private utils: UtilsService,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.loggedInUser = this.utils.getLoggedInUser();

    if (!this.loggedInUser) {
      this.loggedInUser = {
        email: '',
        username: '',
      }
      $('#formModal').modal({
        backdrop: 'static',
        keyboard: false
      }, 'show')
    } else {
      this.getFeedback = true;
      this.loggedInUser.username = this.loggedInUser.lastName ? this.loggedInUser.firstName + " " + this.loggedInUser.lastName : this.loggedInUser.firstName;
      this.getForm();
    }
  }

  getForm() {
    this.activatedRoute.queryParams.subscribe((param: any) => {
      if (param['id']) {
        this.getFeedbackForm(param['id']);
      }
    });
  }

  getFeedbackForm(id) {
    const req = {
      id: id
    }
    this.csatService.getFeedbackForm(req).subscribe((response: any) => {
      this.feedbackQuestions = [];
      if (this.utils.isResponseSuccess(response)) {
        this.getMyFeedback(id, response.responseData);
      }
    });
  }

  getMyFeedback(id, feedbackForm) {
    const req = {
      formId: id,
      email: this.loggedInUser.email.toLowerCase()
    }
    this.csatService.getMyFeedback(req).subscribe((response: any) => {
      if (this.utils.isResponseSuccess(response)) {
        // Feedback form response
        this.setFeedbackform(feedbackForm);
        // Feedback response
        response.responseData.forEach(resp => {
          if (!this.feedbackForm.recurring && this.feedbackForm.id === resp.formId) {
            this.responseRecorded = true;
          } else if (this.feedbackForm.recurring && this.feedbackForm.id === resp.formId) {
            const submittedDate = moment(resp.submittedDate).format('YYYY-MM-DD');
            let startDate, endDate;
            if (this.feedbackForm.recurFreq === 'WEEKLY') {
              startDate = moment().startOf('isoWeek').format('YYYY-MM-DD');
              endDate = moment().endOf('isoWeek').format('YYYY-MM-DD');
            } else if (this.feedbackForm.recurFreq === 'MONTHLY') {
              startDate = moment().startOf('month').format('YYYY-MM-DD');
              endDate = moment().endOf('month').format('YYYY-MM-DD');
            } else if (this.feedbackForm.recurFreq === 'QUARTERLY') {
              startDate = moment().startOf('quarter').format('YYYY-MM-DD');
              endDate = moment().endOf('quarter').format('YYYY-MM-DD');
            }
            if (moment(submittedDate).isBetween(startDate, endDate, null, '[]')) {
              this.responseRecorded = true;
            }
          }
        });
      }
    });
  }

  setFeedbackform(feedbackForm) {
    const resp = feedbackForm;
    if (resp && resp.length > 0 && resp[0].feedbackFormTemplate) {
      this.feedbackForm = resp[0];
      this.feedbackQuestions = resp[0].feedbackFormTemplate.feedbackQuestions;
      this.feedbackQuestions.forEach(qns => {
        if (qns.questionType === 'Yes/No') {
          qns.values = ['Yes', 'No'];
        } else if (qns.questionType === 'Slider') {
          qns.answer = 0;
        }
      });
    }
  }

  selectAnswer(options, obj) {
    obj.answer = options;
    this.checkAnswers();
  }

  submitFeedback() {
    this.submitted = true;
    this.checkAnswers();
    if (this.validForm) {
      const req = {
        formId: this.feedbackForm.id,
        projectId: this.feedbackForm.projectId,
        username: this.loggedInUser.username,
        email: this.loggedInUser.email.toLowerCase(),
        feedbackQuestions: this.feedbackQuestions,
        overAllRating: this.satisfaction.answer,
        comments: this.comments,
      }
      this.csatService.clientFeedback(req).subscribe((response: any) => {
        if (this.utils.isResponseSuccess(response)) {
          this.responseRecorded = true;
        } else {
          this.utils.growlMessages('error', MESSAGES.ERROR);
        }
      });
    }
  }

  checkAnswers() {
    if (this.submitted && this.satisfaction.answer) {
      this.validForm = true;
      this.feedbackQuestions.forEach(qns => {
        if (!qns.answer) {
          this.validForm = false;
        }
      });
    }
  }

  getUserInfo() {
    if (this.loggedInUser.email && this.loggedInUser.username && this.emailIsValid(this.loggedInUser.email)) {
      this.getFeedback = true;
      $('#formModal').modal('hide');
      this.getForm();
    }
  }

  emailIsValid(email) {
    return /\S+@\S+\.\S+/.test(email)
  }
}
