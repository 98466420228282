import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { KRONOS } from '../../../app-constants';
import { API, ROUTERLINKS, MESSAGES, VALIDATOR_MESSAGES } from '../../../constants/index';
import * as Crypto from 'crypto-js';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { CommunicationService } from '../core/communication.service';
import { catchError } from 'rxjs/operators';
import { PERMISSIONS } from '../../../permissions';
import * as moment from 'moment';
import * as shajs from 'sha.js';
declare var $: any;
/**
 * The Utils Service possess the common methods to be used across the system
 */

@Injectable()
export class UtilsService {
  sessionId: any = {};
  growlData: any = {};
  loggedInUser: any;
  stringValue: string;
  key = Crypto.enc.Hex.parse('36ebe205bcdfc499a25e6923f4450fa8');
  iv = Crypto.enc.Hex.parse('be410fea41df7162a679875ec131cf2c');
  numValue: number;
  ROLEPERMISSION = PERMISSIONS;
  reportStyle = {
    headers: true,
    column: { style: { Font: { Bold: '1' } } },
    rows: { 1: { style: { Font: { Color: '#FF0077' } } } },
    cells: {
      1: {
        1: {
          style: { Font: { Color: '#00FFFF' } }
        }
      }
    }
  };

  /**
    * Returns the true or false based on the provided api response's status code
    * @return boolean
    */
  constructor(private http: HttpClient, private router: Router,
    private _cookieService: CookieService, private _myCommunicationService: CommunicationService) { }

  isResponseSuccess(apiResponse): boolean {
    let is_success: boolean = false;
    switch (apiResponse.statusCode) {
      case 200:
        if (apiResponse.statusMessage == "Success") {
          is_success = true;
        }
        break;
      case 390:
        is_success = false;
        break;
      case 306:
        is_success = false;
        break;
      case 401:
        is_success = false;
        this.applogout();
        break;
      case 444:
        is_success = false;
        break;

    }
    return is_success;
  }

  passwordEncryption(originalValue) {
    this.stringValue = originalValue;
    const encrypted = Crypto.AES.encrypt(
      this.stringValue.toString(),
      this.key,
      {
        iv: this.iv,
        mode: Crypto.mode.CBC,
        padding: Crypto.pad.Pkcs7
      });
    return encrypted.toString();
  }

  passwordDecryption(encriptedValue) {
    const manual_data = encriptedValue;
    const decrypted = Crypto.AES.decrypt(
      manual_data,
      this.key,
      {
        iv: this.iv,
        mode: Crypto.mode.CBC,
        padding: Crypto.pad.Pkcs7
      }
    );
    return decrypted.toString(Crypto.enc.Utf8);

  }

  idEncryption(number) {
    this.numValue = number
    const encrypted = Crypto.AES.encrypt(
      this.numValue.toString(),
      this.key,
      {
        iv: this.iv,
        mode: Crypto.mode.CBC,
        padding: Crypto.pad.Pkcs7
      }
    );
    return encrypted;
  }

  idDecryption(encrypted) {
    let manual_data = encrypted;
    const decrypted = Crypto.AES.decrypt(
      manual_data,
      this.key,
      {
        iv: this.iv,
        mode: Crypto.mode.CBC,
        padding: Crypto.pad.Pkcs7
      }
    );
    manual_data = decrypted.toString(Crypto.enc.Utf8);
    //var numberConversion = parseInt(manual_data);
    return manual_data;
  }

  getSessionId(): any {
    if (this._cookieService.get('loggedInUser') != undefined) {
      this.sessionId = this._cookieService.get("loggedInUser");
      this.sessionId = JSON.parse(this.sessionId);
      return this.sessionId.sessionId;
    }
  }

  getLoggedInUser(): any {
    if (this._cookieService.get('loggedInUser') != undefined) {
      let loggedInUser = this._cookieService.get('loggedInUser');
      loggedInUser = JSON.parse(loggedInUser);
      return loggedInUser;
    }
  }
  /**
   * @author : Shrinath Poojary
   * @param  { Number } moduleId : Module ID of selected module
   * @param  { Number } actionId : Action ID of selected module action
   * @returns { Boolean }        : Return true if action exists for that role permission module
   * @description getPermissions : To get action is available or not
   */
  getPermissions(moduleId, actionId) {
    const loggedInUser = this.getLoggedInUser();
    if (loggedInUser) {
      if (loggedInUser.roleId.includes(KRONOS.SUPER_ADMIN_ROLE_ID)) {
        return true;
      } else if (localStorage.getItem('permissions') != null) {
        const rolePermissions = localStorage.getItem('permissions');
        let rolePermission = this.idDecryption(rolePermissions);
        rolePermission = JSON.parse(rolePermission);

        let auth = false;
        if (rolePermission.length !== 0) {
          rolePermission.filter(element => {
            if (element.moduleId === moduleId && element.permissionId === actionId) {
              auth = true;
            }
          });
        }

        return auth;

      } else {
        this.applogout();
      }
    } else {
      this.applogout();
    }
  }

  checkModulePermissions(moduleId) {
    if (this.getLoggedInUser().roleId.includes(KRONOS.SUPER_ADMIN_ROLE_ID)) {
      return true;
    } else if (localStorage.getItem('permissions') != null) {
      const rolePermissions = localStorage.getItem('permissions');
      let permissions = this.idDecryption(rolePermissions);
      permissions = JSON.parse(permissions);
      let auth = false;
      if (permissions.length !== 0) {
        permissions.filter(element => {
          if (element.moduleId === moduleId) {
            auth = true;
          }

        });
      }

      return auth;
    }

  }

  getHoursTimeOnly(minutes) {
    // var regEXP = /^\d+$/;
    // if (regEXP.test(minutes)) {
    //     var hours:String = Number((minutes)/60).toString();
    //     return hours;
    // }
    // else {
    //     return minutes;
    // }
    return Math.floor(minutes / 60).toString();


  }

  getMinutesTimeOnly(minutes) {
    // let regEXP = /^\d+$/;
    // if (regEXP.test(minutes)) {
    //     var mins : String= Number(minutes%60).toString();
    //     var minute = ("0" + mins).slice(-2);

    //     return minute;
    // }
    // else {
    //     return minutes;
    // }
    return (minutes % 60).toString();
  }

  convertHoursToMins(hours): number {
    if (typeof hours != 'undefined' && hours) {
      return (Number(hours) * 60);
    }
    return 0;
  }

  /*Get color Hex code by passing a string*/
  stringToColor(str) {
    // let hash = 0;
    // for (let i = 0; i < str.length; i++) {
    //     hash = str.charCodeAt(i) + ((hash << 5) - hash);
    // }
    // let colour = '#';
    // for (let j = 0; j < 3; j++) {
    //     const value = (hash >> (j * 8)) & 0xFF;
    //     colour += ('00' + value.toString(16)).substr(-2);
    // }
    // return colour;
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let hue = Math.abs(hash % 360);
    // let colour = "hsl(" + hue + ",100%,30%)";
    return this.HSLToHex(hue, '100', '30');
  }

  HSLToHex(h, s, l) {
    s /= 100;
    l /= 100;

    let c = (1 - Math.abs(2 * l - 1)) * s,
      x = c * (1 - Math.abs((h / 60) % 2 - 1)),
      m = l - c / 2, r, g, b;

    if (0 <= h && h < 60) {
      r = c; g = x; b = 0;
    } else if (60 <= h && h < 120) {
      r = x; g = c; b = 0;
    } else if (120 <= h && h < 180) {
      r = 0; g = c; b = x;
    } else if (180 <= h && h < 240) {
      r = 0; g = x; b = c;
    } else if (240 <= h && h < 300) {
      r = x; g = 0; b = c;
    } else if (300 <= h && h < 360) {
      r = c; g = 0; b = x;
    }
    // Having obtained RGB, convert channels to hex
    r = Math.round((r + m) * 255).toString(16);
    g = Math.round((g + m) * 255).toString(16);
    b = Math.round((b + m) * 255).toString(16);

    // Prepend 0s, if necessary
    if (r.length == 1)
      r = "0" + r;
    if (g.length == 1)
      g = "0" + g;
    if (b.length == 1)
      b = "0" + b;

    return "#" + r + g + b;
  }

  hexToRgbA(hex) {
    let c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split('');
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = '0x' + c.join('');
      return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',0.4)';
    }
    throw new Error('Bad Hex');
  }

  /*Get color Hex code by passing a string*/
  hashCode(str) { // java String#hashCode
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  }

  sha256(str) {
    return shajs('sha256').update(str).digest('hex');
  }

  intToRGB(i) {
    const c = (i & 0x00FFFFFF)
      .toString(16)
      .toUpperCase();

    return '#' + '00000'.substring(0, 6 - c.length) + c;
  }

  /*Get text contrast by passing background hex color*/
  getContrast(hexcolor) {
    // const r = parseInt(hexcolor.substr(1, 2), 16);
    // const g = parseInt(hexcolor.substr(3, 2), 16);
    // const b = parseInt(hexcolor.substr(5, 2), 16);
    // const color = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    // return (color >= 128) ? '#000000' : '#ffffff';
    return '#ffffff';
  }


  minutesToStr(minutes) {
    let sign = '';
    if (minutes < 0) {
      sign = '-';
    }

    const hours = this.leftPad(Math.floor(Math.abs(minutes) / 60));
    const minute = this.leftPad(Math.abs(minutes) % 60);
    return sign + hours + "." + minute;

  }

  leftPad(number) {
    return ((number < 10 && number >= 0) ? '0' : '') + number;
  }

  /* Function to generate UUID */
  generateUUID() {
    let d = new Date().getTime();
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
  }

  /* Function to return UUID along with function parameter */
  generateUniqueid(data) {
    const uuid = this.generateUUID();
    return data + '#' + uuid;
  }

  splitLine(data) {
    const newString = data.replace(/↵/g, "<br/>");
    return newString;
  }
  getBucketName() {
    return this.http.post(KRONOS.BASE + KRONOS.USER + API.GET_BUCKETNAME,
      JSON.stringify({ sessionId: this.getSessionId() })).pipe(
        catchError((error) => {
          return Observable.throw(error);
        }));
  }

  getLatestVersion() {
    return this.http.get('assets/build/version.json').pipe(
      catchError((error) => {
        return Observable.throw(error);
      }));
  }

  /**
  * date convertion to utc
  * @param  {date} dateTime
  */
  utcConvertor(dateTime) {
    if (dateTime !== null) {
      return moment(dateTime).utc().format('YYYY-MM-DD HH:mm');
    }
  }
  /**
  * date convertion to local time
  * @param  {date} dateTime
  */
  localConvertor(utcTime) {
    if (utcTime !== null) {
      return moment.utc(utcTime).local().format('hh:mm A');
    }
  }

  unAuthorizedToaster(redirectUrl) {
    this.growlData = {
      severity: 'warn', summary: VALIDATOR_MESSAGES.UNAUTHORIZED_ACCESS, detail: ''
    };
    this._myCommunicationService.emitChange(this.growlData);
    this.router.navigate([redirectUrl]);
  }

  growlMessages(status, message) {
    this.growlData = {
      severity: status, summary: message, detail: ''
    };
    this._myCommunicationService.emitChange(this.growlData);
  }

  getTimestamp() {
    const currentDateTime = new Date(Date.now());
    return currentDateTime.getTime();
  }

  materialInput() {
    var instance = this;
    $("input.floating-input").each(function () {
      instance.matInput(this);
    })

    $("input.floating-input").click(function () {
      $(this).parent(".ngui-auto-complete-wrapper").siblings("label").css({ 'top': '-25px', 'font-size': '15px', 'color': '#0C395F' })
    })

    $("input.floating-input").blur(function () {
      instance.matInput(this);
    })

    $("input.floating-input").on('change', function () {
      instance.matInput(this);
    })

    $("input.floating-input").on('keypress', function () {
      instance.matInput(this);
    })
    $("input.floating-input").on('keyup', function () {
      $(this).parent(".ngui-auto-complete-wrapper").siblings("label").css({ 'top': '-25px', 'font-size': '15px', 'color': '#0C395F' })
    })
  }

  matInput(instance) {
    var $input = $(instance).val();
    if ($input) {
      $(instance).parent(".ngui-auto-complete-wrapper").siblings("label").css({ 'top': '-25px', 'font-size': '15px', 'color': '#0C395F' })
    } else {
      $(instance).parent(".ngui-auto-complete-wrapper").siblings("label").css({
        'color': '#999', 'font-size': '17px', ' font-weight': 'normal', 'position': 'absolute',
        'pointer-events': 'none', 'left': '5px', 'top': '5px', 'transition': '0.2s ease all', '-moz-transition': '0.2s ease all', '-webkit-transition': '0.2s ease all'
      })
    }
  }

  applogout() {
    if (this._cookieService.get('loggedInUser') != undefined) {
      this._cookieService.remove('loggedInUser');
      localStorage.removeItem('moduleList');
      this._cookieService.remove('roles');
      this._cookieService.remove('certificate');
      this._cookieService.remove('locations');
      localStorage.removeItem('userDirectoryFilter');
      localStorage.removeItem('trainingsFilter');
      localStorage.removeItem('projectFilter');
      localStorage.removeItem('allocationFilter');
      localStorage.removeItem('userColFilter');
      localStorage.removeItem('ibuSpaceFilter');
      localStorage.removeItem('leaveSummaryFilter');
      localStorage.removeItem('filters');
      localStorage.removeItem('financeBillingReport');
      localStorage.removeItem('masterData');
      localStorage.removeItem('currentUser');
      localStorage.removeItem('permissions');
      localStorage.removeItem('taskReport');
      localStorage.removeItem('workLocationReport');
      this.growlData = {
        severity: 'warn', summary: MESSAGES.SESSION_EXPIRED, detail: ''
      };
      this._myCommunicationService.emitChange(this.growlData);
      this.router.navigate([ROUTERLINKS.LOGIN]);
    }
  }

  validatorMessage(apiResponse, message): boolean {
    let is_success: boolean = true;
    switch (apiResponse.statusCode) {
      case 200:
        if (apiResponse.statusMessage == "Success") {
          is_success = true;
          this.growlData = {
            severity: 'success', summary: message, detail: ''
          };
          this._myCommunicationService.emitChange(this.growlData);
        }
        break;
      case 320:
        is_success = false;
        if (apiResponse.errorMessage == "email already exist") {
          this.growlData = {
            severity: 'error', summary: apiResponse.errorMessage, detail: ''
          };
        } else {
          this.growlData = {
            severity: 'error', summary: apiResponse.statusMessage, detail: ''
          };
        }
        this._myCommunicationService.emitChange(this.growlData);
        break;
      case 390:
        is_success = false;
        break;
      case 306:
        is_success = this.failedCase();
        break;
      case 401:
        is_success = this.failedCase();
        break;
    }
    return is_success;
  }

  failedCase() {
    this.applogout();
    return false;
  }

  // sorting
  normalizeMixedDataValue(value) {
    const padding = '000000000000000';
    value = value.replace(
      /(\d+)((\.\d+)+)?/g,
      function ($0, integer, decimal, $3) {
        if (decimal !== $3) {
          return (
            padding.slice(integer.length) +
            integer +
            decimal
          );
        }
        decimal = (decimal || '.0');
        return (
          padding.slice(integer.length) +
          integer +
          decimal +
          padding.slice(decimal.length)
        );
      }
    );
    return (value);
  }

  sortAlphabetically(a, b) {
    if (!a) {
      return 1;
    } else if (!b) {
      return -1;
    } else if (a.toUpperCase().charAt(0) > b.toUpperCase().charAt(0)) {
      return 1;
    } else if (a.toUpperCase().charAt(0) === b.toUpperCase().charAt(0)) {
      if (a.toUpperCase().charAt(1) > b.toUpperCase().charAt(1)) {
        return 1;
      } else {
        return -1;
      }
    }
    else {
      return -1
    }
  }

  normalizedSorting(a, b) {
    const aMixed = this.normalizeMixedDataValue(a.empId);
    const bMixed = this.normalizeMixedDataValue(b.empId);
    return (aMixed < bMixed ? -1 : 1);
  }

  sortAction(a, b) {
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1;
    }
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1;
    }
    return 0;
  }

  sortAlpahbetsDesc(a, b) {
    if (!a) {
      return 1;
    } else if (!b) {
      return -1;
    } else if (a.toUpperCase().charAt(0) > b.toUpperCase().charAt(0)) {
      return -1;
    } else if (a.toUpperCase().charAt(0) === b.toUpperCase().charAt(0)) {
      if (a.toUpperCase().charAt(1) > b.toUpperCase().charAt(1)) {
        return -1;
      } else {
        return 1;
      }
    }
    else {
      return 1
    }
  }

  // Image Upload

  imageUplaod(fileId) {
    $(fileId).click();
  }

  readFile(file, reader, callback) {
    // Set a callback funtion to fire after the file is fully loaded
    reader.onload = () => {
      // callback with the results
      callback(reader.result);
    }
    // Read the file
    reader.readAsDataURL(file);
  }

  // Report Export
  exportData(cols, fileName, fileBody) {
    if (cols && cols.length > 0 && fileName && fileBody) {
      // Modify column headers for the query
      const formattedCols = cols.map(col => ({
        field: col.field,
        header: col.header.includes(' ') ? `[${col.header}]` : col.header,
      }));

      const selectQuery = formattedCols.map(col => `${col.field} AS ${col.header}`).join(', ');
      // Construct the final alasql1 query
      const alasql1 = `SELECT ${selectQuery} INTO XLSX("${fileName}-Report.xlsx", ?) FROM ?`;
      alasql(alasql1, [this.reportStyle, fileBody]);
    }
  }

  reportHeader(selectedFilter, filter) {
    const cols = [];
    if (selectedFilter && selectedFilter.includes) {
      filter.forEach(obj => {
        if (selectedFilter.includes(obj.header)) {
          cols.push(obj);
        }
      });
    } else {
      console.error("selectedFilter is undefined or does not have the includes method.");
    }
    return cols;
  }

  // Sort Array

  SortList(arrayList, field) {
    if (arrayList && arrayList.length > 0 && field) {
      const isString = Number.isNaN(arrayList[0].field);
      arrayList.sort(
        (a, b) => {
          if (isString) {
            return this.sortAlphabetically(a[field], b[field])
          } else {
            if (a[field] < b[field]) {
              return -1;
            }
            if (a[field] > b[field]) {
              return 1;
            }
          }

        }
      );
    }
  }

  getBgColor(name) {
    if (name != null) {
      const bgColor = this.stringToColor(name.toLowerCase());
      const color = this.getContrast(bgColor);
      return { 'background-color': bgColor, 'color': color, 'opacity': 0.8 };
    }
  }

  counter(i) {
    return new Array(i);
  }

  isURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
  }

  openInNewTab(url) {
    window.open(url, '_blank').focus();
  }

  setItem(key: string, data: any): void {
    localStorage.setItem(key, JSON.stringify(data));
  }

  getItem(key: string): any {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : null;
  }

  removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  clearCache(): void {
    localStorage.clear();
  }

  isStringNullorEmpty(str: string){
    return str === null || str === undefined || str === "" ? true : false
  }

}
