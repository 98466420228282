import { Injectable } from '@angular/core';
import { DataService } from '../core/interceptor.service';
import { KRONOS } from '../../../app-constants';
import { API } from '../../../constants/index';

@Injectable()
export class GroupUserService {
  groupUsers: any = {};
  constructor(private dataService: DataService) { }

  getAll() {
    const req = {
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_USERS_GROUP, req);
  }

  createGroup(addGroupName, addGroupDescription, addGroupEmail) {
    const req = {
      name: addGroupName, description: addGroupDescription, groupEmail: addGroupEmail
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ADD_USER_GROUP, req);
  }

  getUserFromGroups(groupId) {
    const req = {
      groupId: groupId
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_USER_FROM_GROUPS, req);
  }

  updateUserGroup(id, addGroupName, addGroupDescription, addGroupEmail) {
    const req = {
      id: id, name: addGroupName, description: addGroupDescription, groupEmail: addGroupEmail
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.UPDATE_USER_GROUP, req);
  }

  addUserToGroups(userId, id) {
    const req = {
      userId: userId, groupId: id
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ADD_USER_TO_GROUPS, req);
  }

  addBulkUsersToGroups(userIds, id) {
    const req = {
      userIds: userIds, groupId: id
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ADD_BULK_USERS_TO_GROUPS, req);
  }

  deleteGroup(id) {
    const req = {
      id: id
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.DELETE_USER_GROUP, req);
  }

  removeUserFromGroups(userId, id) {
    const req = {
      userId: userId, groupId: id
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.REMOVE_USER_FROM_GROUPS, req);
  }

  removeBulkUsersFromGroups(userIds, id) {
    const req = {
      userIds: userIds, groupId: id
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.REMOVE_BULK_USERS_FROM_GROUPS, req);
  }


}
