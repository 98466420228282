import { Injectable } from '@angular/core';
import { KRONOS } from '../../../app-constants';
import { API } from '../../../constants/index';
import { DataService } from '../core/interceptor.service';

@Injectable({
  providedIn: 'root'
})

export class FeedbackCardService {

  constructor(private dataService: DataService) { }

  moodOfTheCrewFeedback(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.ESAT + API.MOOD_OF_THE_CREW_FEEDBACK, req);
  }

  viewForms(){
    return this.dataService.get(KRONOS.BASE + KRONOS.ESAT + API.VIEW_FORMS);
  }

  viewFormById(id) {
    const req = {
      formId: id
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.ESAT + API.VIEW_FORM_BY_ID, req);
  }
  
}
