export class AssetData {
    name: string;
    company: string;
    model: string;
    status: string;
    serial: string;
    orderNo: string;
    cost: string;
    vendor: string;
    warranty: number;
    notes: string;
    location: string;
    supplier: string;
    purchaseDate: string;
    // userId: number;
    // purchaseDate: string;
}