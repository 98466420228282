import { Injectable } from '@angular/core';
import { DataService } from '../core/interceptor.service';
import { KRONOS } from '../../../app-constants';
import { API } from '../../../constants/index';

@Injectable()
export class QuestionTypeService {

  constructor(private dataService: DataService) { }

  getAll() {
    const req = {
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_QUESTION_TYPES, req);
  }

  addNew(questionType) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ADD_NEW_QUESTION_TYPE, questionType);
  }

  update(questionType) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.UPDATE_QUESTION_TYPE, questionType);
  }

  deleteQuestionType(id) {
    const req = {
      id: id
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.DELETE_QUESTION_TYPE, req);
  }
}
