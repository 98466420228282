import { Injectable } from '@angular/core';
import { DataService } from '../core/interceptor.service';
import { KRONOS } from '../../../app-constants';
import { API } from '../../../constants/index';

@Injectable()
export class LocationService {
  location: any = {};

  constructor(private dataService: DataService) {
  }

  getAllLocations() {
    const req = {
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_LOCATION, req);
  }

  addNewLocation(location) {
    this.location = location;
    const req = {
      name: this.location.name, description: this.location.description, locIcon: this.location.locIcon
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ADD_NEW_LOCATION, req);
  }

  updateLocation(location) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.UPDATE_LOCATION, location);
  }

  deleteLocation(id) {
    this.location = id
    const req = {
      id: this.location
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.DELETE_LOCATION, req);
  }

}
