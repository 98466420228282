import { Injectable } from '@angular/core';
import { DataService } from '../core/interceptor.service';
import { KRONOS } from '../../../app-constants';
import { API } from '../../../constants/index';

@Injectable({
  providedIn: 'root'
})
export class RolesService {

  constructor(private dataService: DataService) { }

  getRolesOfAnOrganization() {
    const request = {}
    return this.dataService.post(KRONOS.BASE + KRONOS.ROLES + API.GET_ROLES_BY_ORG, request);
  }

  getAllRolesOfAnOrganization() {
    const request = {}
    return this.dataService.post(KRONOS.BASE + KRONOS.ROLES + API.GET_ALL_ROLES_BY_ORG, request);
  }

  addNewRole(request) {
    return this.dataService.post(KRONOS.BASE + KRONOS.ROLES + API.ADD_ORG_ROLE, request);
  }

  updateOrgRoles(request) {
    return this.dataService.post(KRONOS.BASE + KRONOS.ROLES + API.UPDATE_ORG_ROLES, request);
  }

  deleteOrgRoles(request) {
    return this.dataService.post(KRONOS.BASE + KRONOS.ROLES + API.DELETE_ORG_ROLES, request);
  }

  getRolePermission(id) {
    const request = {
      roleId: id
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.ROLES + API.GET_ROLE_PERMISSION, request);
  }

  updateRolePermission(request) {
    return this.dataService.post(KRONOS.BASE + KRONOS.ROLES + API.UPDATE_ROLE_PERMISSION, request);
  }

  getAllUsersByRole(request) {
    return this.dataService.post(KRONOS.BASE + KRONOS.ROLES + API.GET_ALL_USERS_BY_ROLE, request);
  }

  addUserRole(request) {
    return this.dataService.post(KRONOS.BASE + KRONOS.ROLES + API.ADD_USER_ROLE, request);
  }

  removeUserRole(request) {
    return this.dataService.post(KRONOS.BASE + KRONOS.ROLES + API.REMOVE_USER_ROLE, request);
  }


}
