import { Injectable } from '@angular/core';
import { DataService } from '../core/interceptor.service';
import { KRONOS } from '../../../app-constants';
import { API } from '../../../constants/index';

@Injectable()
export class UnitsService {

  constructor(private dataService: DataService) { }

  getAll() {
    const req = {
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_UNITS, req);
  }

  createBusinessUnit(unitname) {
    const req = {
      name: unitname
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.CREATE_BUSINESS_UNIT, req);
  }

  updateUnit(unit) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.UPDATE_UNIT, unit);
  }

  deleteUnit(id) {
    const req = {
      id: id
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.DELETE_UNIT, req);
  }
}
