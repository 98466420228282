import { Component, OnInit } from '@angular/core';
import { GlobalEventsManager } from '../../../helpers/guards/index';
import { MenuItem } from 'primeng/primeng';

@Component({
  selector: 'app-sub-navigation',
  templateUrl: './sub-navigation.component.html',
  styleUrls: ['./sub-navigation.component.css']
})

export class SubNavigationComponent implements OnInit {

  private items: MenuItem[];
  showSideBar: boolean = false;
  showManageBar: boolean = true;
  showMaintainBar: boolean = false;
  loggedInUser: any = {};

  constructor(private globalEventsManager: GlobalEventsManager) {
    this.globalEventsManager.showSideBarEmitter.subscribe((mode) => {
      this.loggedInUser = localStorage.getItem('loggedInUser');
      this.loggedInUser = JSON.parse(this.loggedInUser);
      if (mode !== null) {
        this.showSideBar = mode;
      } else if (this.loggedInUser) {
        if (this.loggedInUser.sessionId) {
          this.showSideBar = true;
        }
      }
    });
  }

  ngOnInit() {
    this.items = [];
    this.items.push({ label: 'Categories' });
    this.items.push({ label: 'Sports' });

  }
}