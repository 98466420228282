import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HolidayService, UtilsService } from '../../helpers/services/index';
import 'fullcalendar';
import * as moment from 'moment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-calendar-parent',
  templateUrl: './calendar-parent.component.html',
  providers: [HolidayService],
  styleUrls: ['./calendar-parent.component.scss']
})

export class CalendarParentComponent implements OnInit {

  @Output() projectEvent = new EventEmitter();
  @Output() projectEventUpdate = new EventEmitter();
  @Input() events: any;
  userId: number;
  trainings: any[];
  leaves: any;
  holiday: any;
  subEvents: any;
  header: any;

  currentYear: number;
  currentMonth: number;
  currentDate;
  start: string;
  end: string;
  event: MyEvent;

  chkHoliday: boolean;
  chkLeaves: boolean;
  chkTrainings: boolean;
  chkEvents: boolean;

  dialogVisible: boolean = false;

  idGen: number = 100;

  constructor(private router: Router, public utils: UtilsService) {
  }

  back(fc) {
    this.events = [];
    this.holiday = Object;
    this.trainings = [];
    this.subEvents = {};
    this.leaves = {};
    fc.prev();
    this.intializeMonthAndYear(new Date(fc.getDate()));
    this.projectEvent.emit({ start: this.start, end: this.end });
  }
  next(fc) {
    this.events = [];
    this.holiday = Object;
    this.trainings = [];
    this.subEvents = {};
    this.leaves = {};
    fc.next();
    this.intializeMonthAndYear(new Date(fc.getDate()));
    this.projectEvent.emit({ start: this.start, end: this.end });
  }
  today(fc) {
    this.events = [];
    this.holiday = Object;
    this.trainings = [];
    this.subEvents = {};
    this.leaves = {};
    fc.today();
    this.intializeMonthAndYear(new Date(fc.getDate()));
    this.projectEvent.emit({ start: this.start, end: this.end });
  }


  ngOnInit() {
    // this.events = [];
    this.holiday = Object;
    this.trainings = [];
    this.subEvents = {};
    this.leaves = {};

    this.chkHoliday = true;
    this.chkLeaves = true;
    this.chkEvents = true;
    this.chkTrainings = true;

    const d = new Date();
    this.intializeMonthAndYear(d);


    this.userId = JSON.parse(localStorage.getItem('loggedInUser'));


    this.header = {
      left: 'title',
      center: '',
      right: ''
    };
  }

  intializeMonthAndYear(d) {
    const year = d.getFullYear(), month = (d.getMonth());
    const start = moment([year, month]);
    const end = moment(start).endOf('month').format('DD');
    if ((d.getMonth() + 1) >= 10) {
      this.end = d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + end;
      this.start = d.getFullYear() + '-' + (d.getMonth() + 1) + '-01';
    } else {
      this.end = d.getFullYear() + '-0' + (d.getMonth() + 1) + '-' + end;
      this.start = d.getFullYear() + '-0' + (d.getMonth() + 1) + '-01';
    }
    this.currentMonth = d.getMonth() + 1;
    this.currentYear = d.getFullYear();
    this.currentDate = this.currentYear + '-' + this.currentMonth;
  }

  handleDayClick(event) {
    this.event = new MyEvent();
    this.event.start = event.date.format();
    this.dialogVisible = false;
    // trigger detection manually as somehow only moving the mouse quickly after click triggers the automatic detection
    // this.cd.detectChanges();
  }

  handleEventClick(eventClickObj) {
    this.projectEventUpdate.emit({ start: this.start, end: this.end, eventClickObj });
    let id = eventClickObj.calEvent.eventId;
    let routeURL = eventClickObj.calEvent.routeURL;
    this.router.navigate([routeURL], {
      queryParams:
      {
        id: this.utils.idEncryption(id)
      }
    })
  }

  saveEvent() {
    // update
    if (this.event.id) {
      const index: number = this.findEventIndexById(this.event.id);
      if (index >= 0) {
        this.events[index] = this.event;
      }
    } else {
      this.event.id = this.idGen++;
      this.events.push(this.event);
      this.event = null;
    }

    this.dialogVisible = false;
  }

  deleteEvent() {
    let index: number = this.findEventIndexById(this.event.id);
    if (index >= 0) {
      this.events.splice(index, 1);
    }
    this.dialogVisible = false;
  }

  findEventIndexById(id: number) {
    let index = -1;
    for (let i = 0; i < this.events.length; i++) {
      if (id == this.events[i].id) {
        index = i;
        break;
      }
    }

    return index;
  }
}

export class MyEvent {
  id: number;
  title: string;
  start: string;
  end: string;
  allDay: boolean = true;
}
