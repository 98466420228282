import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpClient, HttpHeaders } from '@angular/common/http';
import { CommunicationService } from '../../../helpers/services/core/communication.service';
import { UtilsService } from '../../../helpers/services/core/utils.service';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';


@Injectable()
export class DataService {
  constructor(private http: HttpClient, private _myCommunicationService: CommunicationService) { }

  // get method
  get(url) {
    this.requestCall();
    return this.http.get(url).pipe(
      catchError((error) => {
        return throwError(error);
      }),
      finalize(() => this.responseCall()));
  }

  getWithHeaders(url, headers?: HttpHeaders) {
    this.requestCall();
    const options = { headers };
    return this.http.get(url, options).pipe(
      catchError((error) => {
        return throwError(error);
      }),
      finalize(() => this.responseCall())
    );
  }

  getWithReqParams(url, params?: any) {
    this.requestCall();
    const options = { params: params || {} };
    return this.http.get(url, options).pipe(
      catchError((error) => {
        return throwError(error);
      }),
      finalize(() => this.responseCall())
    );
  }

  // post method
  post(url, req) {
    this.requestCall();
    return this.http.post(url, req).pipe(
      catchError((error) => {
        return throwError(error);
      }),
      finalize(() => this.responseCall()));
  }

  postFormData(url, body, headers?: HttpHeaders) {
    this.requestCall();
    const options = { headers };
    return this.http.post(url, body, options).pipe(
      catchError((error) => {
        return throwError(error);
      }),
      finalize(() => this.responseCall()));
  }

  // put method
  put(url, req) {
    this.requestCall();
    return this.http.put(url, req).pipe(
      catchError((error) => {
        return throwError(error);
      }),
      finalize(() => this.responseCall()));
  }
  //delete
  deleteWithHeaders(url, headers?: HttpHeaders) {
    this.requestCall();
    const options = { headers };
    return this.http.delete(url, options).pipe(
      catchError((error) => {
        return throwError(error);
      }),
      finalize(() => this.responseCall())
    );
  }

  //delete with options
  deleteWithRequest(url, req) {
    this.requestCall();
    return this.http.delete(url, req).pipe(
      catchError((error) => {
        return throwError(error);
      }),
      finalize(() => this.responseCall()));
  }

  // delete method
  delete(url, req) {
    this.requestCall();
    return this.http.delete(url, req).pipe(
      catchError((error) => {
        return throwError(error);
      }),
      finalize(() => this.responseCall()));
  }

  requestCall() {
    this._myCommunicationService.loaderDataEmitChange(true);
  }
  responseCall() {
    this._myCommunicationService.loaderDataEmitChange(false);
  }

}

@Injectable()
export class InterceptorService implements HttpInterceptor {

  constructor(private util: UtilsService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const loggedInUser: any = this.util.getLoggedInUser();

    let authReq;
    if (loggedInUser !== undefined && req.headers.get('Authorization') === null) {
      authReq = req.clone({
        headers: req.headers.set('Content-Type', 'application/json')
          .set('Accept', 'application/json')
          .set('Authorization', loggedInUser.sessionId)
      });
    } else {
      authReq = req.clone({
        headers: req.headers.set('Content-Type', 'application/json')
          .set('Accept', 'application/json')
      });
    }

    if ((req.method === 'POST' || req.method === 'PUT' || req.method === 'DELETE') && (req.url.includes('feed/post') || req.url.includes('https://metatags.io/api'))) {
      authReq = req.clone({
        headers: req.headers.delete('Content-Type')
          .set('Accept', '*/*')
      });
    }

    // send the newly created request
    return next.handle(authReq).pipe(catchError(err => {
      if (err.status === 401) {
        // auto logout if 401 response returned from api
        this.util.applogout();
        location.reload(true);
      }
      const error = err.error.message || err.statusText;
      return throwError(error);
    }));
  }
}
