import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UtilsService } from 'app/helpers/services';

@Component({
  selector: 'app-time-card',
  templateUrl: './time-card.component.html',
  styleUrls: ['./time-card.component.scss']
})
export class TimeCardComponent implements OnInit {

  @Output() formSubmitted: EventEmitter<any> = new EventEmitter();

  rating: any;
  constructor(public utils: UtilsService) { }

  ngOnInit() { }

  onStarClick(rating: number) {
    this.rating = rating;
  }

  onFormSubmitted() {
    // Hide the time-card component when the form is successfully submitted
    this.formSubmitted.emit();
  }
}
