import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class SharedProjectsService {

    projectObject = new Subject<any>();
    projectObjectChangeEmitted1$ = this.projectObject.asObservable();
    projectObjectEmitChange1(myMessage: any) {
        this.projectObject.next(myMessage);
    }

    constructor() { }

}
