import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NguiAutoCompleteModule } from '@ngui/auto-complete';
import { CommonComponent } from './common.component';
import { FilterPipe } from '../../helpers/directives/index';
import { ColorPickerModule } from 'ngx-color-picker';
import { Daterangepicker } from 'ng2-daterangepicker';
import { TrainingDirectiveComponent } from '../training-directive/training-directive.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  CalendarModule, ChartModule, DataTableModule, ScheduleModule, DialogModule,
  ConfirmDialogModule
} from 'primeng/primeng';
import { CheckboxModule, ChipsModule, TooltipModule, SliderModule, GrowlModule } from 'primeng/primeng';
import { CalendarParentComponent } from '../calendar-parent/calendar-parent.component';
import { QuillEditorModule } from 'ngx-quill-editor';
import { PopoverModule } from 'ngx-bootstrap';
import { SafehtmlPipe } from '../../helpers/directives/index';
import { DateAgoPipe } from '../../helpers/directives/index';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { NotesParentComponent } from '../notes-parent/notes-parent.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DragDropModule } from 'primeng/primeng';
import { InterceptorService, DataService } from '../../helpers/services/core/interceptor.service';
import 'jquery';
import 'moment';
import { LoaderComponent } from './loader/loader.component';
import { SubNavigationComponent } from './sub-navigation/sub-navigation.component';
import { FiltersComponent } from './components/filters/filters.component';
import { ProfileCardsComponent } from './components/profile-cards/profile-cards.component';
import { ProfileImageComponent } from './components/profile-image/profile-image.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { ContactCardComponent } from './components/contact-card/contact-card.component';
import { NoteCardComponent } from './components/note-card/note-card.component';
import { ProjectCardComponent } from './components/project-card/project-card.component';
import { FormComponent } from './components/form/form.component';
import { ContractCardComponent } from './components/contract-card/contract-card.component';
import { ContractDetailsCardComponent } from './components/contract-details-card/contract-details-card.component';
import { ReportTemplateComponent } from './components/report-template/report-template.component';
import { TimeCardComponent } from './components/time-card/time-card.component';
import { FeedbackCardComponent } from './components/feedback-card/feedback-card.component';
import { TrackingTableComponent } from './components/tracking-table/tracking-table.component';
import { DataSelectionListComponent } from './components/data-selection-list/data-selection-list.component';
import { OnboardingCardComponent } from './components/onboarding-card/onboarding-card.component';
import { OnboardingFeedbackFormComponent } from './components/onboarding-feedback-form/onboarding-feedback-form.component';
import { IndustrySolutionCardComponent } from './components/industry-solution-card/industry-solution-card.component';
import { AccountDetailsCardComponent } from './components/account-details-card/account-details-card.component';
import { GlobalSearchComponent } from './components/global-search/global-search.component';


@NgModule({
  imports: [
    CommonModule,
    ColorPickerModule,
    Daterangepicker,
    FormsModule,
    ScheduleModule,
    DialogModule,
    CalendarModule,
    CheckboxModule,
    QuillEditorModule,
    ChipsModule,
    PopoverModule,
    ChartModule,
    GrowlModule,
    AccordionModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    HttpClientModule,
    DataTableModule,
    TooltipModule,
    SliderModule,
    ConfirmDialogModule,
    ReactiveFormsModule,
    NguiAutoCompleteModule,
    DragDropModule,
  ],
  declarations: [CommonComponent, LoaderComponent, FilterPipe, SafehtmlPipe, DateAgoPipe, TrainingDirectiveComponent, CalendarParentComponent,
    NotesParentComponent, SubNavigationComponent, FiltersComponent, ProfileCardsComponent, ProfileImageComponent, TabsComponent, ContactCardComponent,
    NoteCardComponent, ProjectCardComponent, FormComponent, ContractCardComponent, ContractDetailsCardComponent, ReportTemplateComponent, TimeCardComponent, FeedbackCardComponent,
    TrackingTableComponent, DataSelectionListComponent, OnboardingCardComponent, OnboardingFeedbackFormComponent, IndustrySolutionCardComponent, AccountDetailsCardComponent, GlobalSearchComponent],

  providers: [DataService, {
    provide: HTTP_INTERCEPTORS,
    useClass: InterceptorService,
    multi: true
  }],
  exports: [FilterPipe, SafehtmlPipe, DateAgoPipe, ChipsModule, AccordionModule, BsDatepickerModule, ColorPickerModule, Daterangepicker,
    TrainingDirectiveComponent, TimepickerModule, CalendarParentComponent, QuillEditorModule, NotesParentComponent, PopoverModule,
    ChartModule, DataTableModule, TooltipModule, SliderModule, ScheduleModule, LoaderComponent, GrowlModule, ConfirmDialogModule,
    ReactiveFormsModule, FiltersComponent, ProfileCardsComponent, ProfileImageComponent, TabsComponent, ContactCardComponent,
    NoteCardComponent, ProjectCardComponent, ContractCardComponent, FormComponent, ReportTemplateComponent, TimeCardComponent, FeedbackCardComponent,
    NoteCardComponent, ProjectCardComponent, ContractCardComponent, ContractDetailsCardComponent, FormComponent, ReportTemplateComponent, TimeCardComponent, FeedbackCardComponent,
    TrackingTableComponent, DataSelectionListComponent, OnboardingCardComponent, OnboardingFeedbackFormComponent, IndustrySolutionCardComponent, AccountDetailsCardComponent, GlobalSearchComponent]

})
export class SharedModule { }
