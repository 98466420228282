import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SharedSkillService {
  private skillTagList: string[] = [];

  add(item: string): void {
    this.skillTagList.push(item);
  }

  remove(item: string): void {
    const index = this.skillTagList.indexOf(item);
    if (index !== -1) {
      this.skillTagList.splice(index, 1);
    }
  }

  clear(): void {
    this.skillTagList = [];
  }

  getList(): string[] {
    return this.skillTagList;
  }
}
