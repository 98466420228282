export const API = {
    'LOGIN': 'login',
    'LOGOUT': 'logout',
    'CHANGE_PASSWORD': 'changePassword',
    'GET_VERSION': 'getVersion',
    'GET_ROLES_BY_ORG': 'getRolesByOrg',
    'GET_ALL_ROLES_BY_ORG': 'getAllRolesByOrg',
    'GET_AUTHORIZED_PERMISSIONS': 'getAuthorizedPermissionsByRoleId',
    'GET_ALL_COUNTRIES': 'getAllCountry',
    'GET_ALL_USERS': 'getAllUsersByOrg',
    'GET_ALL_USERS_BY_BASIC_DETAILS': 'getAllUsersByOrgBasicDetails',
    'GET_USERS_BY_DISTANCE': 'getUsersByDistance',
    'GET_ALL_SITES': 'getAllSiteByOrg',
    'ADD_SITE': 'addSite',
    'UPDATE_SITE': 'updateSite',
    'DELETE_SITE': 'deleteSite',
    'GET_COMPLIANCE': 'getComplianceData',
    'GET_TOTAL_TIME_REPORTED': 'getTotalReportingTimePerUser',
    'GET_TOTAL_LEAVE_HOURS': 'getTotalLeaveByHoursPerUser',
    'GET_TOTAL_LEAVE_COUNT': 'getTotalLeaveByCategoryPerUser',
    'GET_USER_BY_ID': 'getUserProfile',
    'GET_TOTAL_LEAVE_BY_YEAR_PER_USER': 'getTotalLeaveBreakupPerMonth',
    'GET_ROLE_PERMISSION': 'getRolePermission',
    'UPDATE_ROLE_PERMISSION': 'updateRolePermission',
    'ADD_ORG_ROLE': 'addOrgRoles',
    'UPDATE_ORG_ROLES': 'updateOrgRoles',
    'DELETE_ORG_ROLES': 'deleteOrgRoles',
    'GET_ALL_USERS_BY_ROLE': 'getAllUsersByRole',
    'ADD_USER_ROLE': 'addUserRole',
    'REMOVE_USER_ROLE': 'removeUserRole',
    'GET_SKILL_APPROVAL': 'getSkillApproval',
    'ADD_APPROVED_SKILL': 'addApprovedSkill',
    'GET_UNAPPROVED_SKILL': 'getUnApprovedSkill',
    'DELETE_APPROVED_SKILL': 'deleteApprovedSkill',
    'GET_MENTEE_MANAGEREE': 'getMenteesManagerees',
    'CHECK_MODULE_ENABLED_FOR_ORG': 'checkModuleEnabledForOrg',

    // profile --> Insights
    'GET_USER_WISE_PROJECT_DETAILS': 'getUserWeekWiseProjectDetails',
    'GET_PROJECT_WISE_TASK_WISE_DETAILS': 'getUserWeekWiseTaskDetails',
    'GET_DAY_WISE_PROJECT_DETAILS': 'getUserDayWiseProjectDetails',
    'GET_ALL_PROJECT_WORKED': 'getAllProjectWorkedPerUser',
    'GET_ALL_PROJECTS': 'getAllProject',
    'GET_ALL_ACCOUNTS_PROJECTS': 'getAllAccountProjects',
    'GET_ALL_TEAMS': 'getAllTeamByOrg',
    'GET_TEAMWISE_USER_COUNT': 'getOrgTeamUserCount',
    'GET_ALL_TASKS': 'getAllTask',
    'ADD_USER': 'addUser',
    'UPDATE_USER': 'updateUserDetails',
    'UPDATE_USER_TIMEZONE': 'updateUserTimezone',
    'UPDATE_USER_LOCATION': 'updateUserLocation',
    'UPDATE_OTHER_USER_PROFILE': 'updateOtherUserDetails',
    'GET_USERS_BY_SKILL': 'getAllUserPerSkillSet',
    'GET_ALL_TEMPLATES': 'getAllTemplateByOrg',
    'GET_ALL_TASK': 'getAllTask',
    'GET_ALL_UNITS': 'getAllUnitByOrg',
    'CREATE_BUSINESS_UNIT': 'createBusinessUnit',
    'GET_DESIGNATIONS_BY_ORG': 'getDesignationByOrg',
    'ADD_DESIGNATION': 'addDesignation',
    'UPDATE_DESIGNATION': 'updateDesignation',
    'DELETE_DESIGNATION': 'deleteDesignation',
    'ADD_TASK_TO_TEMPLATE': 'addTaskToTemplate',
    'REMOVE_TASK_TO_TEMPLATE': 'removeTaskFromTemplate',
    'GET_ALL_TASK_BY_TEMPLATE': 'getAllTaskByTemplate',
    'GET_ALL_BUSINESS_UNIT': 'getBusinessUnitByOrg',
    'GET_ALL_DESIGNATION': 'getDesignationByOrg',
    'GET_ALL_TEMPLATE': 'getAllTemplateByOrg',
    'ADD_NEW_PROJECT': 'createProject',
    'GET_PROJECT_BY_ID': 'getProjectDetails',
    'DELETE_PROJECT': 'deleteProject',
    'GET_ALL_ADMIN_PROJECTS': 'getProjectListForProjectAdmin',
    'DELETE_PROJECT_UPDATE': 'deleteProjectUpdate',
    'GET_PROJECT_DETAILS_BY_ID': 'getProjectDetailsById',
    'ADD_UPDATE_PROJECT_EVENT': 'addUpdateProjectEvent',
    'DELETE_PROJECT_EVENT': 'deleteProjectEvent',
    'GET_PROJECT_EVENT': 'getProjectEventByMonth',
    'GET_PROJECT_MEMBER_LEAVES': 'getLeavesByTeamOrProject',
    'ADD_PROJECT_MEMBER': 'addNewUserToProject',
    'ADD_PROJECT_MEMBER_AS_ADMIN': 'addUserToProjectAsAdmin',
    'GET_PROJECT_MEMBERS': 'getUsersForProject',
    'DELETE_PROJECT_MEMBERS': 'deleteUserToProject',
    'DELETE_PROJECT_MEMBERS_AS_ADMIN': 'deleteUserToProjectAsAdmin',
    'UPDATE_PROJECT_MEMBERS': 'updateUserProjectRole',
    'UPDATE_PROJECT_MEMBERS_AS_ADMIN': 'UpdateUserProjectRoleAsAdmin',
    'ADD_TEAM': 'addTeam',
    'UPDATE_TEAM': 'updateTeam',
    'DELETE_TEAM': 'deleteTeam',
    'ADD_TASK': 'createTask',
    'UPDATE_TASK': 'updateTask',
    'DELETE_TASK': 'removeTask',
    'ADD_NEW_ORG': 'addOrganization',
    'CREATE_TEMPLATE': 'createTemplate',
    'UPDATE_UNIT': 'updateUnit',
    'DELETE_UNIT': 'deleteUnit',
    'ADD_NEW_QUESTION_TYPE': 'addQuestionType',
    'ADD_NEW_PROJECT_UPDATE': 'addProjectUpdate',
    'UPDATE_PROJECT_UPDATE': 'updateProjectUpdates',
    'GET_PROJECT_UPDATES': 'getProjectUpdate',
    'UPDATE_PROJECT': 'updateProject',
    'GET_BUSINESS_UNIT_REPORT': 'businessUnitReport',
    'GET_PROJECT_UNIT_REPORT': 'getProjectInvoiceReports',
    'GET_PROJECT_INVOICE_REPORTS': 'getProjectInvoiceReports',
    'GET_ADMIN_PROJECT_REPORT': 'getProjectAdminReport',
    'GET_PROJECT_INVOICE_REPORTS_FOR_PROJECT': 'getProjectInvoiceReportsNew',
    'ADD_PROJECT_EST': 'addProjectEstimation',
    'GET_ESTIMATIONS': 'getProjectEstimationDetails',
    'GET_INSIGHT_ESTIMATIONS': 'getProjectInsightEstimation',
    'UPDATE_ESTIMATIONS': 'updateProjectEstimation',
    'DELETE_ESTIMATIONS': 'deleteProjectEstimation',
    'DELETE_TEMPLATE': 'deleteTemplate',
    'GET_YEARS_FOR_LOCK': 'GetYearsForLock',
    'SAVE_TASK_FOR_PROJECT': 'saveTaskTimeForProject',
    'UPDATE_TASK_FOR_PROJECT': 'updateTaskTimeForProject',
    'DELETE_TASK_FOR_PROJECT': 'deleteTask',
    'GET_ACCOUNT_REPORTS': 'accountWiseReport',
    'GET_COUNTRY_REPORTS': 'getCountryWiseReport',
    'GET_FOOD_REPORT': 'getFoodBookingReport',
    'GET_ALL_LOCK_MONTH': 'GetLockOfYear',
    'UPDATE_LOCK_SYSTEM': 'updateLockSystem',
    'GET_USER_TIME_LOCK': 'getUserTimeLock',
    'UPDATE_USER_TIME_LOCK': 'updateUserTimeLock',
    'GET_USER_SKILL_LIST': 'getAllUserSkillsList',
    'GET_USER_REPORT_MONTH': 'getMonthInvoiceReports',
    'GET_TASK_WISE_DOWNLOAD_REPORTS': 'getTaskWiseDownloadReports',
    'GET_WEEK_WISE_DOWNLOAD_REPORTS': 'getWeekWiseDownloadReports',
    'GET_PROJECT_WISE_DOWNLOAD_REPORTS': 'getProjectWiseDownloadReports',
    'GET_FEATURE_WISE_DOWNLOAD_REPORTS': 'getFeatureWiseDownloadReports',

    'GET_TASK_WISE_DOWNLOAD_REPORTS_BY_ID': 'getTaskWiseDownloadReportsByUserId',
    'GET_WEEK_WISE_DOWNLOAD_REPORTS_BY_ID': 'getWeekWiseDownloadReportsByUserId',
    'GET_PROJECT_WISE_DOWNLOAD_REPORTS_BY_ID': 'getProjectWiseDownloadReportsByUserId',
    'GET_FEATURE_WISE_DOWNLOAD_REPORTS_BY_ID': 'getFeatureWiseDownloadReportsByUserId',

    'GET_TEAMWISE_DOWNLOAD_REPORTS': 'teamReports',
    'GET_ALL_PROJECT_TASK': 'getAllProjectTask',
    'GET_WEEK_ACTIVITY': 'web/getTimeOverViewWeb',

    'ADD_BULK_USER': 'bulkAddUser',
    'DOWNLOAD_EXCEL_TEMPLATE': 'downloadExcelTemplate',

    'UPDATE_PIN': 'updatePin',
    'FORGOT_PASSWORD': 'forgotPassword',
    'GET_NO_OF_WORKING_DAYS': 'getNoOfWorkingDays',
    'GENERATE_ALLOCATION_REPORT': 'generateAllocationReport',
    'GET_LEAVE_GENERAL_REPORT': 'getLeaveAndGeneralReport',
    'GET_SUBSCRIBED_REPORT': 'getSubscribedReport',
    'SUBSCRIBE_REPORT': 'subscribeReport',
    'DELETE_SUBSCRIPTION': 'deleteSubscription',
    'GET_REPORT_SUMMARY': 'getReportSummary',
    'GET_ACANDO_REPORT': 'getAcandoProjectReport',
    'GET_CUSTOMIZED_REPORT': 'getCustomizedReport',
    'GET_WORK_LOCATION_REPORT': 'getWorkLocationReport',

    'GET_ALL_CHANNEL': 'getAllChannels',
    'ADD_HOLIDAY': 'addHoliday',
    'GET_ALL_HOLIDAY': 'getAllHolidayByOrg',
    'UPDATE_HOLIDAY': 'updateHoliday',
    'DELETE_HOLIDAY': 'deleteHoliday',
    'GET_ALL_ORG': 'getAllOrg',
    'GET_ORG_BY_ID': 'getOrgById',
    'UPDATE_ORG_BY_ID': 'updateOrgById',
    'DELETE_ORG_BY_ID': 'deleteOrg',
    'GET_ALL_UPCOMINGS': 'getAllUpcomings',
    'GET_DATE_RANGE_INFO': 'getDateRangeInfo',
    'MONTHLY_WORKING_DAYS': 'monthlyWorkingDays',

    'GET_ALL_CALENDAR_HOLIDAY': 'getCalenderYearHolidays',
    'GET_ALL_CALENDAR_LEAVES_BY_MONTH': 'getLeavesByMonth',
    'GET_ALL_CALENDAR_EVENTS_BY_MONTH': 'getAllEvents',
    'GET_PROJECT_DAILYTIME_BY_MONTH': 'getDailyWorkHour',

    'ACTIVE_EXPENSE_REPORT': 'getAllExpensesByUser',
    'PAST_EXPENSE_REPORT': 'getAllPastExpenses',
    'EXPENSE_CATEGORY': 'getAllExpenseCategory',
    'ADD_EXPENSE': 'addExpenseCategory',
    'UPDATE_EXPENSE': 'updateExpenseCategory',
    'DELETE_EXPENSE': 'deleteExpenseCategory',
    'ADD_EXPENSE_CLAIM': 'addExpense',
    'EXPENSE_USER_REPORT': 'userExpenseReport',
    'GET_EXPENSE_DETAILS': 'getExpenseDetails',
    'APPROVE_EXPENSE': 'approveExpense',

    'ADD_NEW_FEATURE': 'createFeature',
    'UPDATE_FEATURE': 'updateFeature',
    'DELETE_FEATURE': 'deleteFeature',
    'GET_ALL_FEATURE': 'getAllFeatures',
    'GET_ALL_USERS_GROUP': 'getAllUserGroups ',
    'ADD_USER_GROUP': 'addUserGroup',
    'GET_USER_FROM_GROUPS': 'getUserFromGroups',
    'UPDATE_USER_GROUP': 'updateUserGroup',
    'ADD_USER_TO_GROUPS': 'addUserToGroups',
    'ADD_BULK_USERS_TO_GROUPS': 'addBulkUsersToGroups',
    'DELETE_USER_GROUP': 'deleteUserGroup ',
    'REMOVE_USER_FROM_GROUPS': 'removeUserFromGroups',
    'REMOVE_BULK_USERS_FROM_GROUPS': 'removeBulkUsersFromGroups',
    'GET_ACCOUNT_DOWNLOAD_REPORTS': 'accountWiseReport',
    'GET_TRAINING_REPORT': 'getTrainingReport',
    'GET_YEAR_LOCK': 'getLockOfYear',
    'UPDATE_LOCK': 'updateLock',

    'GET_ALL_MODULES': 'getAllModules',
    'GET_ORG_WISE_MODULES': 'getOrgWiseModules',
    'ASSIGN_ORG_WISE_MODULES': 'assignModulesToOrg',
    'DELETE_ORG_WISE_MODULES': 'deleteModulesFromOrg',
    'ADD_ADMIN': 'addAdmin',
    'REMOVE_ADMIN': 'removeAdmin',
    'ADD_NEW_LOCATION': 'addLocations',
    'UPDATE_LOCATION': 'updateLocations',
    'DELETE_LOCATION': 'deleteLocations',
    'GET_ALL_LOCATION': 'getAllLocations',
    'GET_ALL_CONFERENCE_ROOMS': 'getAllConferenceRoomsByOrg',
    'ADD_NEW_CONFERENCE_ROOM': 'addConferenceRooms',
    'UPDATE_CONFERENCE_ROOMS': 'updateConferenceRooms',
    'DELETE_CONFERENCE_ROOMS': 'deleteConferenceRooms',
    'GET_ALL_QUESTION_TYPES': 'getAllQuestionTypeByOrg',
    'UPDATE_QUESTION_TYPE': 'updateQuestionType',
    'DELETE_QUESTION_TYPE': 'deleteQuestionType',
    'GET_ALL_THOR_QUERIES': 'getAllThorGeneralQueries',
    'ADD_UPDATE_THOR_QUERY': 'addUpdateThorQuery',
    'DELETE_THOR_QUERY': 'deleteThorQuery',
    'ADD_NEW_BANNER' : 'addBanner',
    'UPDATE_BANNER': 'updateBanner',
    'DELETE_BANNER': 'deleteBanner',
    'GET_ACTIVE_BANNERS' : 'getActiveBanners',
    'GET_INACTIVE_BANNERS' : 'getInactiveBanners',

    'GET_ZOHO_LEAVES_BY_MONTH': 'userWiseLeaveCount',
    'GET_KRONOS_LEAVES_BY_MONTH': 'getUserWiseLeaveCountDetails',
    //links
    'ADD_LINK': 'link/addLink',
    'ADD_ORG_LINK': 'link/addOrgLink',
    'DELETE_LINK': 'link/deleteLink',
    'UPDATE_LINK': 'link/updateLink',
    'UPDATE_ORG_LINK': 'link/updateOrgLink',
    'GET_ALL_LINKS': 'link/getAllLinks',
    'GET_ALL_LINKS_TAG': 'getallLinkTags',
    'ADD_LINK_TAGS': 'addLinkTag',
    'DELETE_LINK_TAG': 'deleteLinkTag',
    'GET_ALL_BULK_UPLOAD_TEMPLATES': 'getAllBulkUploadTemplatesByOrg',
    'CREATE_BULK_UPLOAD_TEMPLATE': 'createBulkUploadTemplate',
    'DELETE_BULK_UPLOAD_TEMPLATE': 'deleteBulkUploadTemplate',
    'VALIDATE_CONSULTANT_PROFILE_DATA': 'validationCheckForConsultantProfile',
    'BULK_UPLOAD': 'uploadBulkConsultantProfileLinks',
    'DESIGNATION_BULK_UPLOAD': 'uploadBulkDesignations',
    'GET_ALL_BULK_UPLOAD_FILES': 'getAllBulkUploadFiles',
    'GET_BULK_UPLOAD_FILE_BY_ID': 'getBulkUploadFileById',
    'UPDATE_LINK_CATEGORY': 'updateLinkCategory',
    "DELETE_LINK_CATEGORY": "deleteLinkCategory",
    //project collation and sharing
    'SHARE_PROJECT': 'projectAccess',
    'GET_SHARED_PROJECTS': 'getAllSharedProjectByOrg',
    'UPDATE_SHARED_PROJECT': 'updateProjectAccess',
    'ADD_PROJECT_GROUP': 'addGroup',
    'GET_PROJECT_GROUPS': 'getAllGroups',
    'GET_SHARED_PROJ_OF_GROUP': 'getAllSharedProjectsOfGroups',
    'ADD_PROJ_TO_GROUP': 'addProjectToGroup',
    'REMOVE_PROJ_FRM_GRP': 'removeProjectFromGroup',
    'GROUP_REPORTS': 'getGroupReports',
    //Tags
    'GET_TAGS': 'tags',
    'SEARCH_BASED_ON_TAGS': 'searchBasedOnTags',
    //Project Requests
    'CREATE_PROJECT_REQUEST': 'createProjectRequest',
    'GET_USER_PROJECT_REQUEST': 'getProjectRequestByUserId',
    'GET_ADMIN_PROJECT_REQUEST': 'getProjectRequestForAdmin',
    'UPDATE_PROJECT_REQUEST': 'updateProjectRequest',
    'CHANGE_PROJECT_REQUEST_STATUS': 'changeProjectRequestStatus',
    'UPDATE_PROJECT_REQUEST_BY_IBU_OPS': 'updateProjectRequestByIBUOps',

    //Announcements
    'GET_ALL_ANNOUNCEMENTS': 'getAllAnnouncements',
    'GET_ANNOUNCEMENT_BY_ID': 'getAnnouncementById',
    'ADD_ANNOUNCEMENTS': 'addAnnouncement',
    'UPDATE_ALL_ANNOUNCEMENT': 'updateAllAnnouncement',
    'UPDATE_ANNOUNCEMENTS': 'updateAnnouncement',
    'DELETE_ANNOUNCEMENT': 'deleteAnnouncement',
    'DELETE_ALL_ANNOUNCEMENT': 'deleteAllAnnouncement',
    'ADD_ANNOUNCEMENT_COMMENT': 'addAnnouncementComment',
    'UPDATE_ANNOUNCEMENT_COMMENT': 'updateAnnouncementComment',
    'DELETE_ANNOUNCEMENT_COMMENT': 'deleteAnnouncementComment',
    'GET_ANNOUNCEMENT_COMMENT_BY_COMMENT_ID': 'getAnnouncementCommentByCommentId',
    'GET_ALL_COMMENTS_BY_ANNOUNCEMENT_ID': 'getAllCommentsByAnnouncementId',
    'UPDATE_LIKES_BY_USER_ID': 'updateLikesByUserId',
    'INCREMENT_VIEWED_USER_ID': 'incrementViewedUserId',

    // Org OnBoarding
    'REGISTER_ORG': 'registerOrganization',

    //Time for ekstep
    'SAVE_TASK_FOR_PROJECT_FEATURE': 'saveTaskTimeForProjectFeature',
    'UPDATE_TASK_FOR_PROJECT_FEATURE': 'updateTaskTimeForProjectFeature',
    'GET_WEEK_ACTIVITY_EKSTEP': 'web/getTimeOverViewWebFeature',

    'GET_DEFAULTER_LIST_REPORT': 'defaultersReport',
    'GET_FEATURE_REPORT': 'getFeatureInvoiceReports',

    //events
    'GET_ALL_EVENTS': 'getAllEvents',
    'ADD_EVENT': 'addEvent',
    'UPDATE_ALL_EVENT': 'updateAllEvent',
    'UPDATE_EVENT': 'updateEvent',
    'DELETE_EVENT': 'deleteEvent',
    'DELETE_ALL_EVENT': 'deleteAllEvent',
    'ADD_USEREVENT_RSVP': 'addEventUserDetails',
    'UPDATE_USEREVENT_RSVP': 'updateEventUserDetails',
    'GET_ALL_EVENT_USER_DATA': 'getAllEventsUserDetails',


    //Exclude users
    'GET_EXCLUDE_USERS': 'getAllExcludeUser',
    'MAKE_EXCLUDE_USER': 'excludeUser',
    'DELETE_EXCLUDE_USER': 'deleteExcludeUser',

    //Location
    'GET_ALL_LOCATIONS': 'getAllLocations',
    'ADD_LOCATION': 'addLocations',

    //Training
    'GET_ALL_TRAININGS': 'getAllTrainings',
    'GET_TRAININGS_BYID': 'getTrainingById',
    'ADD_TRAININGS': 'createTraining',
    'DELETE_TRAININGS': 'deleteTraining',
    'DELETE_ALL_TRAININGS': 'deleteAllTraining',
    'UPDATE_ALL_TRAININGS': 'updateAllTraining',
    'UPDATE_TRAINING': 'updateTraining',
    'JOIN_TRAININGS': 'joinTraining',
    'MY_TRAININGS': 'getAllTrainingsByUserId',
    'GET_FEEDBACK_TRAINING': 'getUserTrainingFeedback',
    'GET_FEEDBACK_TEMPLATE_QUESTION_BY_ORG': 'getFeedbackTemplateQuestionByOrg',
    'USER_TRAINING_FEEDBACK': 'userTrainingFeedback',
    'INSERT_TEMPLATE': 'insertFeedbackTemplate',
    'GET_USER_TRAINING_FEEDBACK': 'getUserTrainingFeedback',
    'ADD_TRAINING_REQUEST': 'createTrainingRequest',
    'GET_ALL_TRAINING_REQUESTS': 'getAllTrainingRequest',
    'GET_TRAINING_REQUEST_BY_ID': 'getTrainingRequestById',
    'DELETE_TRAINING_REQUEST_BY_ID': 'deleteTrainingRequest',
    'CLOSE_TRAINING_REQUEST': 'closeTrainingRequest',
    'REQUEST_FOR_A_TRAINING': 'addTrainingRequestCount',
    'GET_TRAININS_DATA_PER_UNIT': 'getTotalNumberOfTrainingsPerUnit',
    'TRAININGS_TAKEN': 'getTrainingDataPerUser',
    'TRAININGS_TAKEN_DURATION': 'getDurationOfTrainingDataPerUser',
    'TRAININGS_ATTENDED': 'getNoOfTrainingsAttendedPerUser',
    'TRAININGS_ATTENDED_DURATION': 'getDurationOfTrainingsAttendedPerUser',
    'TRAINING_RATINGS': 'getRatingsForTrainingPerUser',
    'MONTHWISE_TRAINING_ATTENDED': 'getMonthWiseDurationOfTrainingAttendedPerUser',
    'MONTHWISE_TRAINING_TAKEN': 'getMonthWiseDurationOfTrainingConductedPerUser',

    //Skills
    'GET_ALL_SKILLS': 'getAllSkillsList',
    'UPDATE_USER_SKILL': 'updateUserSkill',
    'DELETE_SKILL': 'deleteSkills',
    'ADD_SKILL': 'addNewSkill',
    'UPDATE_SKILL': 'updateSkills',
    'GET_ALL_SKILLS_AND_COUNT': 'getAllSkillsAndUserCount',
    'GET_ALL_USER_SKILLS': 'getAllUserSkills',

    //Learning Resources
    'GET_ALL_LEARNING_RESOURCES': 'getAllLearningResources',
    'GET_ALL_SKILLS_AND_RESOURCE_COUNT': 'getAllSkillsAndResourceCount',
    'ADD_LEARNING_RESOURCE': 'addLearningResource',
    'DELETE_LEARNING_RESOURCE': 'deleteLearningResource',
    'UPDATE_LEARNING_RESOURCE': 'updateLearningResource',

    //Bucket info
    'GET_BUCKETNAME': 'getUserBucketDetails',

    //Category
    'GET_ALL_CATEGORIES': 'getAllCategoryByOrg',
    'ADD_CATEGORY': 'addNewCategory',
    //Policy
    'ADD_POLICY': 'addPolicy',
    'GET_ALL_POLICY': 'getAllPoliciesByOrg',
    'UPDATE_POLICY': 'updatePolicies',
    'DELETE_POLICY': 'deletePolicy',
    'DELETE_POLICY_CAT': 'deleteCategory',

    //Analytics
    'TOTAL_TIME_SPENT_PER_PROJECT': 'getTotalTimeSpentPerProject',
    'TOTAL_TIME_SPENT_PER_PROJECT_ID': 'getTotalTimeSpentPerProjectId',
    'MONTH_WISE_PER_PROJECT_ID': 'getMonthWiseTimeSpentPerProjectId',
    'DAY_WISE_PER_PROJECT_ID': 'getDayWiseTimeSpentPerProjectId',
    'GET_TOTALTIME_SPENT_PER_PROJECT_PER_TASK': 'getTotalTimeSpentPerProjectPerTask',

    //Project invoice
    'GET_ALL_CURRENCY': 'getAllCurrency',
    'ADD_INVOICE': 'addProjectInvoice',
    'GET_PROJECT_INVOICES': 'getAllProjectInvoice',
    'DELETE_PROJECT_INVOICES': 'deleteProjectInvoice',
    'GET_PROJECT_TIME': 'projectTotalHours',
    'UPDATE_INVOICE': 'updateProjectInvoice',

    //Audit Template
    'CREATE_QUESTION_TEMPLATE': 'addNewQuestion',
    'GET_QUESTION_TEMPLATE': 'getAllQuestions',
    'DELETE_QUESTION_TEMPLATE': 'deleteQuestion',
    'UPDATE_QUESTION_TEMPLATE': 'updateQuestion',
    'ADD_QUESTION_TO_TEMPLATE': 'addQuestionToTemplate',
    'REMOVE_QUESTION_FROM_TEMPLATE': 'removeQuestionFromTemplate',
    'GET_ALL_AUDIT_TEMPLATES': 'getAllTemplates',
    'CREATE_NEW_AUDIT_TEMPLATES': 'createNewTemplate',
    'DELETE_AUDIT_TEMPLATE': 'deleteTemplate',
    'GET_AUDIT_TEMPLATE_BY_ID': 'getTemplateByTemplateId',
    'ADD_AUDIT_RESPONSE': 'addAuditResponse',
    'GET_AUDIT_TEMPLATE_BY_TEMPLATE_ID': 'getTemplateByTemplateId',
    'GET_ALL_AUDIT_RESPONSES': 'getallAuditResponses',
    'EDIT_AUDIT_RESPONSE_BY_ID': 'getallAuditResponses',
    'UPDATE_AUDIT_RESPONSE': 'updateAuditResponse',
    'DELETE_AUDIT': 'deleteAuditResponse',
    'GET_ALL_AUDIT_TAGS': 'getallAuditTags',
    'ADD_AUDIT_TAGS': 'addAuditTag',
    'DELETE_AUDIT_TAG': 'deleteAuditTag',
    //Add currency
    'ADD_CURRENCY': 'addCurrency',
    'GET_CURRENCY': 'getAllCurrency',
    'UPDATE_CURRENCY': 'updateCurrency',
    'DELETE_CURRENCY': 'deleteCurrency',
    'GET_ALL_CONVERTING_CURRENCIES': 'getAllConvertingCurrencies',
    'ADD_CONVERTING_CURRENCIES': 'addConvertingCurrencies',
    'GET_ALL_CURRENCY_RATES': 'getAllCurrencyRates',
    'ADD_CURRENCY_RATES': 'addCurrencyRates',

    //Yearly OH values & Benefits
    'GET_ALL_DELIVERY_TYPE_BY_ORG' : 'getAllDeliveryTypeByOrg',
    'GET_YEARLY_OH_VALUES':'getYearlyOHValues',
    'ADD_UPDATE_YEARLY_OH_VALUES': 'addOrUpdateYearlyOHValues',
    'GET_YEARLY_BENEFITS':'getYearlyBenefits',
    'SAVE_UPDATE_YEARLY_BENEFITS':'saveOrUpdateYearlyBenefits',
    'GET_YEARLY_CURRENCY_CONVERSION_RATES':'getYearlyCurrencyConversionRates',
    'ADD_UPDATE_YEARLY_CURRENCY_CONVERSION_RATES' : 'addOrUpdateYearlyCurrencyConversionRates',

    //Notes
    'ADD_NEW_NOTES': 'addNewNote',
    'DELETE_NOTES': 'deleteNote',
    'UPDATE_NOTES': 'updateNote',
    'GET_ALL_NOTES': 'getAllNotes',

    //shareable updates
    'GET_PARENT_PROJECT_UPDATES': 'getParentProjectUpdates',

    //ProjectHealth
    'UPDATE_PROJECT_HEALTH': 'updateProjectHealthCheck',

    //GetRecentAuditResponsesForOrg
    'GET_RECENT_ORG_AUDITS': 'getAllRecentAuditResponsesForOrg',
    //Allocation
    'ADD_ALLOCATION': 'addProjectAllocationForUser',
    'GET_ALL_ALLOCATION': 'getUserAllocationReport',
    'UPDATE_ALLOCATION': 'updateProjectAllocationForUser',
    'DELETE_ALLOCATION': 'deleteProjectAllocationForUser',
    'GET_MEMBER_UTILIZATION': 'getAllocationForAllTheMembers',
    'GET_PROJECT_INVOICE_TIME': 'getProjectWiseInvoiceTime',
    'UPDATE_USER_BILLING_STATUS': 'updateUserBillingApproval',

    //lessons and risks
    'GET_ALL_LESSONS': 'getAllProjectRiskOrLessonLearningForProject',
    'ADD_LESSONS_RISKS': 'addProjectRiskOrLessonLearningToProject',
    'DELETE_LESSONS_RISKS': 'deleteProjectRiskOrLessonLearningToProject',
    'UPDATE_LESSONS_RISKS': 'updateProjectRiskOrLessonLearningToProject',
    'GET_ALL_COMMENTS': 'getCommentsForFeature',
    'ADD_COMMENTS': 'addCommentForFeature',
    'DEL_COMMENTS': 'deleteCommentForFeature',
    'PROJECT_USER_TIME_SPENT': 'getProjectUserForAllocationReport',
    // 'DEL_COMMENTS': 'deleteCommentForFeature',

    //Asset Module
    'ONBOARD_NEW_ASSET': 'onBoardNewAsset',
    'GET_ALL_ASSET_BY_ORG': 'getAllAssetsByOrgId',
    'GET_ASSET_BY_ID': 'getAssetById',
    'ASSIGN_NEW_USER_TO_ASSET': 'assigneAssetToNewUser',
    'UPDATE_ONBOARDED_ASSET': 'updateAsset',
    'GET_LESSONSRISKS_TAGS': 'getAllTagsForProjectRiskOrLessonLearning',
    'DELETE_ASSET': 'removeAssetFromUser',
    'GET_ASSET_BY_USER': 'getAssetsByUserId',
    'GET_USER_ASSEST': 'getAllUsersWithAsset',
    'GET_ASSETS': 'getAssets',

    //Resource management
    'ADD_RESOURCE_ALLOCATION': 'addUserWithTimeRangeToProjects',
    'UPDATE_RESOURCE_ALLOCATION': 'updateUserWithTimeRangeToProjects',
    'GET_ALL_RESOURCE_ALLOCATION': 'getAllUserWithTimeRangeToProjects',
    'DELETE_RESOURCE_ALLOCATION': 'deleteUserWithTimeRangeToProjects',
    'GET_BENCH_REPORT': 'getBenchReport',
    'GET_SALES_PLANNER': 'getTeamMonthWiseDataWithTimeRangeToProjects',
    'ADD_OPPORTUNITY': 'addOpportunity',
    'UPDATE_OPPORTUNITY': 'updateOpportunity',
    'GET_ALL_OPPORTUNITY': 'getAllOpportunities',
    'DELETE_OPPORTUNITY': 'deleteOpportunity',
    'GET_USER_ALLOCATION': 'getUserMonthWiseDataWithTimeRangeToProjects',

    /*enhanced Reports*/
    'GET_ENHANCED_PROJECT_REPORT': 'getProjectInvoiceReportsNew',
    'GET_ENHANCED_TEAM_REPORT': 'teamReportNew',
    'GET_ENHANCED_ACCOUNT_REPORT': 'accountWiseReportNew',
    // PMS
    'GET_USER_TEMPLATE': 'getUserGoal',
    'UPDATE_USER_TEMPLATE': 'updateUserGoal',
    'ADD_GOAL': 'addGoals',
    'GET_ALL_GOAL': 'getGoals',
    'UPDATE_GOAL': 'updateGoals',
    'DELETE_GOAL': 'deleteGoals',
    'UPDATE_SECTION': 'updateSection',

    //Expense
    'GET_ALL_EXPENSE': 'getAllExpenses',
    'GET_EXPENSES_BY_APPROVER': 'getExpensesByApprover',
    'GET_USER_EXPENSE': 'getUserExpenses',
    'ADD_NEW_EXPENSE': 'addNewExpense',
    'UPDATE_USER_EXPENSE': 'updateExpense',
    'DELETE_USER_EXPENSE': 'deleteExpenses',
    'GET_EXPENSE_BY_ID': 'getExpenseById',
    'UPDATE_EXPENSE_STATUS': 'updateExpenseStatus',
    'ADD_UPDATE_EXPENSE_TYPE': 'addUpdateExpenseType',
    'GET_EXPENSE_CATEGORY': 'getExpenseCategory',

    // Awards
    'GET_ALL_AWARD': 'getAllAwards',
    'CREATE_UPDATE_AWARD': 'createOrUpdateAward',
    'GET_AWARD_BY_ID': 'getAwardById',
    'GET_AWARD_BY_ROLE': 'getMappedAwardListByRoleId',
    'GET_RECEIVED_AWARD': 'getReceivedAward',
    'GET_RECEIVED_AWARD_BY_USERID': 'getReceivedAwardByUserId',
    'GET_ISSUED_AWARD': 'getIssuedAward',
    'ISSUE_AWARD': 'issueAward',
    'UPDATE_ISSUED_AWARD': 'updateIssuedAward',
    'GET_LATEST_RECEIVED_AWARD': 'getLatestReceivedAward',
    'GET_AWARD_LEADERBOARD': 'getAwardLeaderboard',

    // Allocation
    'ADD_UPDATE_AVAILABILITY': 'addUpdateUserAvailability',
    'GET_USER_AVAILABILITY': 'getUserAvailability',

    // projects
    'GET_ALL_DOMAIN': 'getAllDomain',
    'ADD_UPDATE_DOMAIN': 'addUpdateDomain',
    'DELETE_DOMAIN': 'deleteDomain',
    'PUBLIC_PROJECTS_UPDATE': 'publicProjectsUpdate',
    'UPDATE_PROJECT_CONTRACT': 'updateProjectContract',
    'GET_ALL_USER_PROJECTS': 'getAllUserProjects',
    'ADD_PROJECT_MILESTONE_BILLING': 'addProjectMilestoneBilling',
    'GET_PROJECT_MILESTONE_BILLING': 'getProjectMilestoneBilling',
    'GET_MONTHLY_PROJECT_BILLING': 'getMonthlyProjectBilling',
    'UPDATE_PROJECT_BILLING_STATUS': 'updateProjectBillingStatus',
    'ADD_USER_PROJECT_BILLING_RATE': 'addUserProjectBillingRate',
    'GET_USER_PROJECT_BILLING_RATE': 'getUserProjectBillingRate',
    'GET_ALL_PROJECT_USER_DETAILS': 'getAllProjectUserDetails',
    'GET_PROJECT_EXTENDED_DETAILS': 'getProjectExtendedDetails',
    'ADD_UPDATE_PROJECT_EXTENDED_DETAILS': 'addProjectExtendedDetails',
    'ADD_REVENUE_FORECAST_DETAILS': 'addRevenueForecastDetails',
    'GET_ALL_PROJECT_SKILLS': 'getAllProjectSkills',
    'GET_ALL_SKILLS_BY_PROJECT_ID': 'getAllSkillsByProjectId',
    'ADD_PROJECT_SKILL': 'addProjectSkill',
    'DELETE_PROJECT_SKILL': 'deleteProjectSkill',
    'GET_PROJECTS_BY_UNIT_ID': 'getProjectsByUnitId',
    'GET_PROJECTS_BY_SOLUTION_ID': 'getAllProjectListBySolutionId',

    // Project CheckIn
    'ADD_QUESTION': 'addNewQuestion',
    'GET_QUESTIONS': 'getAllQuestions',
    'CREATE_UPDATE_TEMPLATE': 'createUpdateTemplate',
    'GET_ALL_CHECKIN_TEMPLATE': 'getAllTemplates',
    'GET_ALL_CHECKIN_TEMPLATE_BY_ID': 'getTemplatesById',
    'MAP_CHECKIN_TEMPLATE': 'mapCheckinTemplateToProject',
    'USER_PROJECT_CHECKIN_FEEDBACK': 'userProjectCheckinFeedback',
    'GET_USER_PROJECT_CHECKIN_FEEDBACK': 'getUserProjectCheckinFeedback',
    'GET_PROJECT_CHECKIN_FEEDBACK': 'getProjectwiseCheckinFeedback',

    // CSAT
    'CREATE_UPDATE_QUESTION': 'createUpdateFeedbackquestion',
    'CREATE_UPDATE_FB_TEMPLATE': 'createUpdateFeedbackTemplate',
    'CLIENT_FEEDBACK': 'clientFeedback',
    'GET_FEEDBACK_QUESTION': 'getFeedbackQuestions',
    'GET_FEEDBACK_TEMPLATE_BY_ID': 'getFeedbackTemplateById',
    'GET_ALL_FEEDBACK_TEMPLATE': 'getFeedbackTemplate',
    'CREATE_UPDATE_FORM': 'createUpdateForm',
    'GET_FEEDBACK_FORM': 'getFeedbackForm',
    'GET_PROJECT_CSAT': 'getProjectCSAT',
    'GET_MY_FEEDBACK': 'getMyFeedback',
    'SHARE_FEEDBACK_FORM': 'shareFeedbackForm',

    //Forecast
    'GET_RESOURCE_ALLOCATION': 'getAllocation',
    'ADD_UPDATE_ALLOCATION': 'addUpdateAllocation',
    'GET_ORG_FORECAST_DATA': 'getOrgForecastData',

    //Ibu-space
    'GET_ALL_USER_PROJECT_ALLOCATION': 'getAllUserProjectAllocation',
    'GET_USER_PROJECT_ALLOCATION_OVERVIEW': 'getUserProjectAllocationOverview',
    'ADD_BULK_USER_TO_PROJECTS': 'addBulkUsersToProjects',
    'GET_WEEKLY_USER_PROJECT_REPORT': 'getWeeklyUserProjectReport',
    'GET_WEEKLY_PROJECT_USER_REPORT': 'getWeeklyProjectUserReport',
    'GET_NON_ALLOCATED_USERS': 'getNonAllocatedUsers',
    'GET_PROJECTS_BY_UNIT': 'getProjectsByUnit',
    'GET_ALL_PROJECT_REVENUE_FORECAST': 'getAllProjectRevenueForecast',
    'ADD_REVENUE_FORECAST_FOR_PROJECT': 'addRevenueForecastForProject',
    'UPADTE_REVENUE_FORECAST_FOR_PROJECT': 'updateRevenueForecastForProject',
    'DELETE_REVENUE_FORECAST_FOR_PROJECT': 'deleteRevenueForecastForProject',
    'GET_PROJECT_REVENUE_FORECAST_OVERVIEW': 'getProjectRevenueForecastOverview',
    'IBU_LEAVE_REPORT': 'orgWiseLeaveCount',

    //CustomerSpace
    'GET_ALL_ACCOUNTS': 'getAllAccountByOrg',
    'GET_ACCOUNT_DETAILS_BY_ID': 'getAccountDetailsById',
    'GET_ALL_ACCOUNT_PROJECTS': 'getProjectsByAccountId',
    'ADD_NEW_ACCOUNT': 'addAccount',
    'UPDATE_ACCOUNT': 'updateAccount',
    'ADD_UPDATE_ACCOUNT': 'addUpdateAccount',
    'DELETE_ACCOUNT': 'deleteAccount',
    'GET_ALL_INDUSTRY': 'getAllIndustry',
    'ADD_UPDATE_INDUSTRY': 'updateIndustry',
    'DELETE_INDUSTRY': 'deleteIndustry',
    'GET_ACCOUNT_PROJECT_DETAILS': 'getAccountProjectDetailsById',
    'CREATE_ACCOUNTS_PROJECT': 'createAccountsProject',
    'UPDATE_ACCOUNTS_PROJECT': 'updateAccountsProject',
    'DELETE_ACCOUNTS_PROJECT': 'deleteAccountsProject',
    'GET_ALL_CUSTOMER_NOTES': 'getAllCustomerNotes',
    'ADD_UPDATE_CUSTOMER_NOTE': 'addUpdateCustomerNote',
    'DELETE_CUSTOMER_NOTE': 'deleteCustomerNote',
    'GET_ACCOUNT_CONTACTS': 'getAccountContacts',
    'ADD_UPDATE_ACCOUNT_CONTACT': 'addUpdateAccountContact',
    'DELETE_ACCOUNT_CONTACT': 'deleteAccountContact',
    'GET_CUSTOMER_CONTRACT': 'getCustomerContract',
    'ADD_UPDATE_CUSTOMER_CONTRACT': 'addUpdateCustomerContract',
    'DELETE_CUSTOMER_CONTRACT': 'deleteCustomerContract',
    'GET_ACCOUNT_CUSTOMER_CONTRACT': 'getAccountWiseCustomerContract',
    'CHECK_ACTIVE_CONTRACTS': 'checkActiveContracts',

    // FoodRegister
    'GET_USER_FOOD_STATUS': 'getUserFoodStatus',
    'GET_ALL_USERS_FOOD_STATUS': 'getAllUsersFoodStatus',
    'FOOD_BOOKING': 'foodBooking',

    // Finance
    'GET_ALL_PROJECT_INVOICE': 'getAllProjectInvoice',
    'UPDATE_USER_FINANCE_DATA': 'updateUserFinanceData',
    'BULK_UPDATE_USER_FINANCE_DATA': 'bulkUpdateUserFinanceData',
    'ADD_BILLING_REMINDER': 'addBillingReminder',
    'GET_ALL_FINANCE_INVOICES': 'getAllFinanceInvoices',
    'ADD_FINANCE_INVOICE': 'addFinanceInvoice',
    'UPDATE_FINANCE_INVOICE': 'updateFinanceInvoice',
    'GET_INVOICE_COUNTS': 'getInvoiceCounts',
    'GET_NON_PUBLIC_PROJECTS': 'getAllNonPublicProjects',
    'GET_ALL_PROJECTS_FOR_FINANCE': 'getAllProjectsForFinance',

    // MoodOfTheCrew
    'MOOD_OF_THE_CREW_FEEDBACK': 'moodOfTheCrew',
    'VIEW_FORMS': 'viewForms',

    // Know-Kronos
    'ADD_KNOW_KRONOS_CARD': 'addKnowKronosCard',
    'GET_KNOW_KRONOS_CARDS': 'getAllCards',
    'DELETE_KNOW_KNONOS_CARD': 'deleteCardById',
    'VIEW_FORM_BY_ID': 'viewFormById',

    //Know-Kronos-FAQ
    'GET_FAQ_DATA': 'getFaqData',
    'ADD_FAQ_DATA': 'addFaqData',
    'DELETE_FAQ_DATA': 'deleteFaqData',
    'UPDATE_FAQ_DATA': 'updateFaqData',

    //Know-Kronos-Release-Notes
    'GET_RELEASE_NOTES': 'getReleaseNote',
    'ADD_RELEASE_NOTES': 'addReleaseNote',
    'DELETE_RELEASE_NOTES': 'deleteReleaseNote',
    'EDIT_RELEASE_NOTES': 'editReleaseNote',

    //FEED
    'FEED_POSTS': 'post',
    'FEED_HOME': 'home',
    'POST_REACTIONS': 'reaction/',
    'POST_COMMENTS': 'comment',
    'POST_LIKES': 'like',
    'CHANNEL': 'channel',
    'RECENT_ACTIVITY':'recent',
    'SUBSCRIBE':'/subscribe',


    //Vendors
    'GET_ALL_VENDORS_BY_ORG' : 'getAllVendorsByOrg',
    'ADD_UPDATE_VENDOR' : 'addUpdateVendor',
    'DELETE_VENDOR' : 'deleteVendor',
    'GET_VENDOR_DETAILS_BY_ID' : 'getVendorDetailsById',
    'GET_SUB_CONTRACTORS_BY_VENDOR' : 'getSubContractorsByVendor',
    'ADD_UPDATE_SUBCONTRACTOR' : 'addUpdateSubContractor',
    'DELETE_SUBCONTRACTOR' : 'deleteSubContractor',

    'GET_ALL_VENDOR_NOTES': 'getAllVendorNotes',
    'ADD_UPDATE_VENDOR_NOTE': 'addUpdateVendorNote',
    'DELETE_VENDOR_NOTE': 'deleteVendorNote',

    'GET_VENDOR_CONTRACTS': 'getVendorContracts',
    'ADD_UPDATE_VENDOR_CONTRACT': 'addUpdateVendorContract',
    'DELETE_VENDOR_CONTRACT': 'deleteVendorContract',
    'GET_ALL_FILES_BY_VENDOR_ID': 'getAllFilesByVendorId',
    'ADD_VENDOR_FILE' : 'addVendorFile',
    'UPDATE_VENDOR_FILES' : 'updateVendorFiles',

    
    //Industries
    'GET_ALL_INDUSTRIES_BY_ORG': 'getAllIndustriesByOrg',
    'GET_ALL_ACCOUNTS_BY_INDUSTRY': 'getAllAccountsByIndustry',
    'ADD_INDUSTRY_SOLUTION_FILE': 'addIndustrySolutionFile',
    'GET_INDUSTRY_FILE': 'getAllIndustryFiles',
    'ADD_INDUSTRY_SOLUTION_SET': 'addIndustrySolutionSet',
    'GET_INDUSTRY_SOLUTION_SET': 'getAllIndustrySolutionSet',
    'DELETE_INDUSTRY_SOLUTION_SET': 'deleteIndustrySolutionSet',
    'DELETE_INDUSTRY_FILE': 'deleteIndustryFile',
    'GET_ALL_INDUSTRY_NOTES': 'getAllIndustryNotes',
    'ADD_UPDATE_INDUSTRY_NOTES': 'addUpdateIndustryNote',
    'DELETE_INDUSTRY_NOTES': 'deleteIndustryNote',
    'DELETE_SOLUTION_INDUSTRY': 'deleteSolutionIndustry',
    'ADD_INDUSTRY_TO_SOLUTION': 'addIndustryToSolutionSet',

    // Solutions
    'GET_ALL_SOLUTION_SET': 'getAllSolutionSet',
    'ADD_SOLUTION_SET': 'addSolutionSet',
    'ADD_SOLUTION_FILE': 'addSolutionFile',
    'ADD_UPDATE_SOLUTION_SKILLS': 'addUpdateSolutionSkills',
    'ADD_UPDATE_SOLUTION_CONTACTS': 'addUpdateSolutionContacts',
    'ADD_UPDATE_SOLUTION_INDUSTRIES': 'addUpdateSolutionIndustries',
    'UPDATE_SOLUTION_FILES': 'updateSolutionFiles',
    'ADD_SOLUTION_FILES': 'addSolutionFile',
    'UPDATE_SOLUTION_SET': 'updateSolutionSet',
    'DELETE_SOLUTION_SET': 'deleteSolutionSet',

    //MICRO LEARNING
    'VIEW_POSTS':'view',
    'LIKE_POST':'like',
    'COMMMENT_POST':'comment'
}

