import { Injectable } from '@angular/core';
import { DataService } from '../core/interceptor.service';
import { KRONOS } from '../../../app-constants';
import { API } from '../../../constants/index';

@Injectable()
export class AccountsService {
  account: any = {};

  constructor(private dataService: DataService) { }

  getAll() {
    const req = {
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_ACCOUNTS, req);
  }

  getAllAccountProjects(id) {
    const req = {
      id: id
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_ACCOUNT_PROJECTS, req);
  }

  getAllCountry() {
    const req = {
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_COUNTRIES, req);
  }

  addNew(account) {
    this.account = account;
    const req = {
      name: this.account.name, countryId: this.account.countryId, active: this.account.active
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ADD_NEW_ACCOUNT, req);
  }

  updateAccount(account) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.UPDATE_ACCOUNT, account);
  }

  deleteAccount(id) {
    const req = {
      id: id
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.DELETE_ACCOUNT, req);
  }

}
