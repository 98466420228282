import { Injectable } from '@angular/core';
import { KRONOS } from '../../../app-constants';
import { API } from '../../../constants/index';
import { DataService } from '../core/interceptor.service';

@Injectable({
  providedIn: 'root'
})
export class IndustriesService {

  constructor(private dataService: DataService) { }

  getAllIndustriesByOrg() {
    const req = {};
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_INDUSTRIES_BY_ORG, req);
  }

  getAllAccountsByIndustry(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_ACCOUNTS_BY_INDUSTRY, req);
  }

  addIndustrySolutionFile(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ADD_INDUSTRY_SOLUTION_FILE, req);
  }

  getAllIndustryFiles(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_INDUSTRY_FILE, req);
  }

  addIndustrySolutionSet(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ADD_INDUSTRY_SOLUTION_SET, req);
  }

  getAllIndustrySolutionSet(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_INDUSTRY_SOLUTION_SET, req);
  }

  deleteIndustrySolutionSet(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.DELETE_INDUSTRY_SOLUTION_SET, req);
  }

  deleteIndustryFile(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.DELETE_INDUSTRY_FILE, req);
  }

  getAllIndustryNotes(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_INDUSTRY_NOTES, req);
  }

  addUpdateIndustryNote(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ADD_UPDATE_INDUSTRY_NOTES, req);
  }

  deleteIndustryNote(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.DELETE_INDUSTRY_NOTES, req);
  }

  deleteSolutionIndustry(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.DELETE_SOLUTION_INDUSTRY, req);
  }

  addIndustryToSolutionSet(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ADD_INDUSTRY_TO_SOLUTION, req);
  }

}

