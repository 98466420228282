import { Injectable } from '@angular/core';
import { DataService } from '../core/interceptor.service';
import { KRONOS } from '../../../app-constants';
import { API, MESSAGES, ROUTERLINKS } from '../../../constants/index';
import { CommunicationService } from '../core/communication.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ProjectCheckInService {

  constructor(private dataService: DataService, private _myCommunicationService: CommunicationService,
    private router: Router) { }

  addQuestion(request) {
    return this.dataService.post(KRONOS.BASE + KRONOS.PROJECTCHECKIN + API.ADD_QUESTION, request);
  }

  getAllQuestions() {
    return this.dataService.get(KRONOS.BASE + KRONOS.PROJECTCHECKIN + API.GET_QUESTIONS);
  }

  createUpdateTemplate(request) {
    return this.dataService.post(KRONOS.BASE + KRONOS.PROJECTCHECKIN + API.CREATE_UPDATE_TEMPLATE, request);
  }

  getAllTemplate() {
    return this.dataService.get(KRONOS.BASE + KRONOS.PROJECTCHECKIN + API.GET_ALL_CHECKIN_TEMPLATE);
  }

  mapCheckinTemplateToProject(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.PROJECTCHECKIN + API.MAP_CHECKIN_TEMPLATE, req);
  }

  getTemplatesById(id) {
    return this.dataService.get(KRONOS.BASE + KRONOS.PROJECTCHECKIN + API.GET_ALL_CHECKIN_TEMPLATE_BY_ID + '/' + id);
  }

  addUserCheckInFeedback(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.PROJECTCHECKIN + API.USER_PROJECT_CHECKIN_FEEDBACK, req);
  }

  getUserProjectCheckinFeedback(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.PROJECTCHECKIN + API.GET_USER_PROJECT_CHECKIN_FEEDBACK, req);
  }

  getProjectwiseCheckinFeedback(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.PROJECTCHECKIN + API.GET_PROJECT_CHECKIN_FEEDBACK, req);
  }

}
