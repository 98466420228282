export class OrgOnboarding {
    id: number
    orgName: string
    orgUrl: string
    orgEmailDomain: string
    noOfUsersAllowed: number
    authenticationType: number
    adminUserName: string
    adminPhone: string
    adminFirstName: string
    adminLastName: string
    weeklyWorkDays: number
    dailyWorkHours: number
    pmoEmail: string
    pmoCheck: boolean
    active: boolean
    dailyEmailCheck: boolean
    weeklyEmailCheck: boolean
    adminEmail: string
}