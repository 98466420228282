export class DashboardBanner {
    id: number;
    createdBy: number;
    createdDate: string;
    orgId: string;
    startDate: string;
    endDate: string;
    fileName: string;
    actionLink: string;
    toolTipContent: string;
}