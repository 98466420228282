import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';

@Injectable()
export class CommunicationService {
    private navbarStickyState = new BehaviorSubject<boolean>(false);
    navbarStickyState$ = this.navbarStickyState.asObservable();
    redirectUrl: string;
    urlId: any;

    private emitChangeSource = new Subject<any>();
    changeEmitted$ = this.emitChangeSource.asObservable();

    private loggedInDataSource = new Subject<any>();
    loggedInDataChangeEmitted$ = this.loggedInDataSource.asObservable();

    // for any related create event
    private createEvent: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    createEventEmitted$: Observable<any> = this.createEvent.asObservable();

    // for any related update Event
    private editEvent: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    editEventEmitted$: Observable<any> = this.editEvent.asObservable();

    // for any related getId event
    private getIdEvent = new Subject<any>();
    getIdEventEmitted$ = this.getIdEvent.asObservable();

    // changeEvent

    private getChangeEvent: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    getChangeEventEmitted$: Observable<any> = this.getChangeEvent.asObservable();

    // request and response call emitter
    private loaderData = new Subject<any>();
    loaderDataChangeEmitted$ = this.loaderData.asObservable();

    constructor() { }
    // Service message
    emitChange(myMessage: any) {
        this.emitChangeSource.next(myMessage);
    }

    // Service message
    loggedInDataemitChange(myMessage: any) {
        this.loggedInDataSource.next(myMessage);
    }

    // Service message

    createEventEmitChange(myMessage: any) {
        this.createEvent.next(myMessage);
    }


    editEventEmitChange(myMessage: any) {
        this.editEvent.next(myMessage);
    }


    getIdEventEmitChange(myMessage: any) {
        this.getIdEvent.next(myMessage);
    }


    getChangeEventEmitChange(myMessage: any) {
        this.getChangeEvent.next(myMessage);
    }

    loaderDataEmitChange(myMessage: any) {
        this.loaderData.next(myMessage);
    }

    updateNavbarStickyState(isSticky: boolean) {
        this.navbarStickyState.next(isSticky);
    }

}
