import { Component, OnInit, Input, Output, EventEmitter, ElementRef } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-data-selection-list',
  templateUrl: './data-selection-list.component.html',
  styleUrls: ['./data-selection-list.component.scss']
})
export class DataSelectionListComponent implements OnInit {

  modalstatus: boolean = false;
  filteredItemArr: any;
  @Input() activeList: any = [];
  @Input() filterItems: any;
  @Input() selectedItem: String;
  @Input() projFilterData: any;
  @Output() getSelectedEvent = new EventEmitter<string>();
  @Output() openNewFilterModalEvent = new EventEmitter<string>();
  @Output() filterEvent = new EventEmitter<string>();
  constructor() { }

  ngOnInit() {
    if (!this.selectedItem) {
      this.selectType(this.activeList[0]);
    } 
  }

  selectType(type: any) {
    this.getSelectedEvent.emit(type);
  }

  filter(data) {
    this.filteredItemArr = [];
    if (data.status.length > 0) {
      this.filterItems.forEach(item => {
        if ((data.status.length == 0 || (data.status.includes('Active') && item.active) ||
          (data.status.includes('Inactive') && !item.active))) {
          this.filteredItemArr.push(item);
        }
      });
    } else {
      this.filteredItemArr = this.filterItems;
    }
    // if (!this.filteredItemArr.map(u => u.id).includes(this.selectedItem) && this.filteredItemArr[0]) {
    //   this.selectType(this.filteredItemArr[0]);
    // }
  }


  openNewFilterModal() {
    this.modalstatus = true;
    $('#newFilterModal').modal('show');
  }
}
