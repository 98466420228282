import { Component, OnInit, Input, Output, EventEmitter, } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { Router } from '@angular/router'
import { UtilsService, CompRouterService } from '../../../../helpers/services/index';
import { ROUTERLINKS } from 'app/constants';

@Component({
  selector: 'app-industry-solution-card',
  templateUrl: './industry-solution-card.component.html',
  styleUrls: ['./industry-solution-card.component.scss']
})
export class IndustrySolutionCardComponent implements OnInit {

  @Input() data: any;
  @Input() resource: any;
  @Input() searchString: any;
  @Input() solutionList: any;
  @Output() add = new EventEmitter<Object>();
  @Output() edit = new EventEmitter<Object>();
  @Output() delete = new EventEmitter<Object>();
  @Output() submit = new EventEmitter<Object>();

  constructor(public utils: UtilsService, private router: Router) { }

  ngOnInit() {
  }

  addModal() {
    this.add.emit();
  }

  editModal(data) {
    this.edit.emit(data);
  }

  deleteModal(data) {
    this.delete.emit(data);
  }

  openFile(filePath: string) {
    window.open(filePath, '_blank');
  }

  routeToProfile(id) {
    this.router.navigate([ROUTERLINKS.USER_PROFILE], { queryParams: { id: this.utils.idEncryption(id) } })
  }  

}

