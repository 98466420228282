export class Time {
    date: string;
    pid: number;
    tid: number;
    fid: number;
    minute: number;
    note: string;
    billable: boolean;
    onSite: boolean;
    activityRefnumber: string;
}