import { Injectable } from '@angular/core';
import { DataService } from '../core/interceptor.service';
import { KRONOS } from '../../../app-constants';
import { API } from '../../../constants/index';
import { Subject } from 'rxjs';

@Injectable()
export class BulkUploadService {
  object = new Subject<any>();
  changeEmitted1$ = this.object.asObservable();
  question: any = {};
  emitChange1(myMessage: any) {
    this.object.next(myMessage);
  }
  constructor(private dataService: DataService) {
  }

  getAll() {
    const req = {
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_BULK_UPLOAD_TEMPLATES, req);
  }

  createBulkUploadTemplate(template) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.CREATE_BULK_UPLOAD_TEMPLATE, template);
  }

  deleteBulkUploadTemplate(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.DELETE_BULK_UPLOAD_TEMPLATE, req);
  }

  validationCheckForConsultantProfile(consultantProfile) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.VALIDATE_CONSULTANT_PROFILE_DATA, consultantProfile);
  }

  bulkUpload(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.BULK_UPLOAD, req);
  }

  getAllBulkUploadFiles(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_BULK_UPLOAD_FILES, req);
  }

  getBulkUploadFileById(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_BULK_UPLOAD_FILE_BY_ID, req);
  }

  designationBulkUpload(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.DESIGNATION_BULK_UPLOAD, req);
  }

}
