import { Injectable } from '@angular/core';
import { KRONOS } from '../../../app-constants';
import { API } from '../../../constants/index';
import { DataService } from '../core/interceptor.service';

@Injectable({
  providedIn: 'root'
})
export class KnowKronosService {

  constructor(private dataService: DataService) { }

  addKnowKronosCard(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ADD_KNOW_KRONOS_CARD, req);
  }

  getAllCards(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_KNOW_KRONOS_CARDS, req);
  }

  deleteCardById(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.DELETE_KNOW_KNONOS_CARD, req);
  }

  addFAQdata(req){
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ADD_FAQ_DATA,req);
  }

  getFAQdata(req){
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_FAQ_DATA,req);
  }

  updateFAQdata(req){
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.UPDATE_FAQ_DATA,req);
  }

  deleteFAQdata(req){
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.DELETE_FAQ_DATA,req);
  }

  getReleaseNotes(req){
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_RELEASE_NOTES,req);
  }

  deleteReleaseNotes(req){
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.DELETE_RELEASE_NOTES,req);
  }

  editReleaseNotes(req){
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.EDIT_RELEASE_NOTES,req);
  }

  addReleaseNotes(req){
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ADD_RELEASE_NOTES,req);
  }
}
