import { Injectable } from '@angular/core';
import { KRONOS } from '../../../app-constants';
import { API } from '../../../constants/index';
import { DataService } from '../core/interceptor.service';

@Injectable()
export class TrainingService {
  constructor(private dataService: DataService) { }

  getAllTraining(startDate, endDate) {
    const req = {
      startDate,
      endDate
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_TRAININGS, req);
  }

  joinTraining(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.JOIN_TRAININGS, req);
  }

  addTraining(training) {
    const requestObj = training;
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ADD_TRAININGS, requestObj);
  }

  getAllConferenceRoom() {
    const req = {
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_CONFERENCE_ROOMS, req);
  }

  getTrainingById(id) {
    const req = {
      id: id
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_TRAININGS_BYID, req);
  }

  updateAllTraining(training) {
    const requestObj = training;
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.UPDATE_ALL_TRAININGS, requestObj);
  }

  updateTraining(training) {
    const requestObj = training;
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.UPDATE_TRAINING, requestObj);
  }

  deleteTraining(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.DELETE_TRAININGS, req);
  }

  deleteAllTraining(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.DELETE_ALL_TRAININGS, req);
  }

  getMyTrainings(startDate, endDate) {
    const req = {
      startDate,
      endDate
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.MY_TRAININGS, req);
  }

  getFeedbackOfTraining(id) {
    const req = {
      trainingId: id
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_FEEDBACK_TRAINING, req);
  }
  getAllFeedbackQuestions() {
    const req = {
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_FEEDBACK_TEMPLATE_QUESTION_BY_ORG, req);
  }
  userFeedBack(trainingFeedbackQuestions) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.USER_TRAINING_FEEDBACK, trainingFeedbackQuestions);
  }

  getUserTrainingFeedback(trainingreport) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_USER_TRAINING_FEEDBACK, trainingreport);
  }

  // Training Requests CRUD Services

  createTrainingRequest(trainingRequest) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ADD_TRAINING_REQUEST, trainingRequest);
  }

  getAllTrainingRequests() {
    const req = {
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_TRAINING_REQUESTS, req);
  }

  getTrainingRequestById(id) {
    const req = {
      id: id
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_TRAINING_REQUEST_BY_ID, req);
  }

  // request for an added training request
  requestForATraining(request) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.REQUEST_FOR_A_TRAINING, request);
  }

  deleteTrainingRequest(deleteRequest) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.DELETE_TRAINING_REQUEST_BY_ID, deleteRequest);
  }

  closeTrainingRequest(closeRequest) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.CLOSE_TRAINING_REQUEST, closeRequest);
  }

  // Analytics data
  getTrainingsPerUnit() {
    const req = {
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.ANALYTICS + API.GET_TRAININS_DATA_PER_UNIT, req);
  }

  // No. of trainings taken
  trainingsTaken(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.ANALYTICS + API.TRAININGS_TAKEN, req);
  }
  // No. of taken trainings duration
  trainingsTakenDuration(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.ANALYTICS + API.TRAININGS_TAKEN_DURATION, req);
  }
  // No. of trainings attended
  trainingsAttended(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.ANALYTICS + API.TRAININGS_ATTENDED, req);
  }
  // No. of attended trainings duration
  trainingsAttendedDuration(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.ANALYTICS + API.TRAININGS_ATTENDED_DURATION, req);
  }
  // avg ratings of trainings taken
  trainingsRating(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.ANALYTICS + API.TRAINING_RATINGS, req);
  }

  monthwiseTrainingAttended(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.ANALYTICS + API.MONTHWISE_TRAINING_ATTENDED, req);
  }

  monthwiseTrainingTaken(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.ANALYTICS + API.MONTHWISE_TRAINING_TAKEN, req);
  }
}

