export class Events {
    heading: string;
    startDate: string;
    endDate: string;
    description: string;
    colour: string;
    labels: any;
    id: number;
    url: string;
    duration: string;
    categeory: string;
    location: string;
    emailCheck: boolean;
    themeName: string;
    userEventDetails: any;
    startTime: string;
    createdBy: any;
}