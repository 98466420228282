import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { CommunicationService } from '../services/index';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router, private _cookieService: CookieService, private commServive: CommunicationService) { }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        this.commServive.redirectUrl = state.url;
        this.commServive.urlId = route.queryParams.id;
        // if (this._cookieService.get('loggedInUser') != undefined && this.urlCheck(state.url)) {
        if (this._cookieService.get('loggedInUser') !== undefined) {
            // logged in so return true
            return true;
        }

        // not logged in so redirect to login page with the return url , { queryParams: { returnUrl: state.url }}
        this.router.navigate(['/login']);
        return false;
    }
    urlCheck(currentUrl) {
        // const loggedInUser = JSON.parse(this._cookieService.get('loggedInUser'));
        const moduleList = JSON.parse(localStorage.getItem('moduleList'));
        if (currentUrl !== '/') {
            const url = currentUrl.split('/')[1];
            if (moduleList.length > 0) {
                let bool = false;
                moduleList.forEach((module) => {
                    if (module.name.toLowerCase() === url.toLowerCase()) {
                        bool = true;
                    }
                })
                return bool;
            }
        } else {
            return true;
        }
    }
}


