import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UtilsService, CompRouterService } from '../../../../helpers/services/index';
import { ANIMATION } from '../../../../helpers/animation/animation';

@Component({
  selector: 'app-profile-cards',
  templateUrl: './profile-cards.component.html',
  styleUrls: ['./profile-cards.component.scss'],
  animations: [ANIMATION.SLIDE_IN]
})

export class ProfileCardsComponent implements OnInit {
  @Input() resource: any;
  @Input() list: any;
  @Input() chipData: any;
  @Input() type: any;
  @Input() loading: any;

  @Output() routeToProfile = new EventEmitter<Object>();
  @Output() removeAllFilters = new EventEmitter<Object>();
  searchString: any;
  name: any;
  constructor(public utils: UtilsService, public compRouter: CompRouterService) { }

  ngOnInit() {
    this.name = this.resource.nameField;
    this.type = this.type;
  }

  routing(data) {
    this.routeToProfile.emit(data);
  }

  getFilterKey(search) {
    return { [this.resource.nameField]: search }
  }

  clearFilters() {
    this.removeAllFilters.emit();
  }

}
