import { Injectable } from '@angular/core';
import { KRONOS } from '../../../app-constants';
import { API } from '../../../constants/index';
import { Links } from '../../../helpers/models/index';
import { DataService } from '../core/interceptor.service';

@Injectable()
export class LinksService {

  constructor(private links: Links, private dataService: DataService) { this.links = links }

  getAll(obj, linkTypeId) {
    this.links.linkTypeId = linkTypeId;
    this.links.selectedTag = obj.selectedTag;
    return this.dataService.post(KRONOS.BASE + API.GET_ALL_LINKS, this.links);
  }

  add(links) {
    this.links = links;
    return this.dataService.post(KRONOS.BASE + API.ADD_LINK, this.links);
  }

  addOrgLink(links) {
    return this.dataService.post(KRONOS.BASE + API.ADD_ORG_LINK, links);
  }

  updateLink(links) {
    this.links = links;
    return this.dataService.post(KRONOS.BASE + API.UPDATE_LINK, this.links);
  }

  updateOrgLink(links) {
    return this.dataService.post(KRONOS.BASE + API.UPDATE_ORG_LINK, links);
  }

  deleteLink(id) {
    const req = {
      id: id
    }
    return this.dataService.post(KRONOS.BASE + API.DELETE_LINK, req);
  }

  getAllTags() {
    const req = {
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.LINK + API.GET_ALL_LINKS_TAG, req);
  }

  addLinkTag(obj) {
    return this.dataService.post(KRONOS.BASE + KRONOS.LINK + API.ADD_LINK_TAGS, obj);
  }

  deleteLinkTag(obj) {
    return this.dataService.post(KRONOS.BASE + KRONOS.LINK + API.DELETE_LINK_TAG, obj);
  }

  updateLinkCategory(obj) {
    return this.dataService.post(KRONOS.BASE + KRONOS.LINK + API.UPDATE_LINK_CATEGORY, obj);
  }

  deleteLinkCategory(obj) {
    return this.dataService.post(KRONOS.BASE + KRONOS.LINK + API.DELETE_LINK_CATEGORY, obj);
  }
}
