export class Project {
    sessioId: string
    id: string
    name: string
    accountId: number
    accountName: string
    unitId: number
    userUnitId : number
    participatingUnits : any
    tempId: number
    parentProjectId: number
    projectCode: string
    channel: string
    active: boolean
    visible: boolean
    Discription: string
    projectEndDate: string
    projectStartDate: string
    notes: string
    projectId: string
    projectName: string
    projUpdateName: string
    desc: string
    userName: string
    adminList: any
    tagList: string
    auditTemplate: any
    description: string
    projUpdateSharable: boolean;
    billable = false;
    billingType:any;
    geography: any
    domain: any
    geographyId: any
    domainId: any
    checkInTemplate: any;
    checkInTemplateName: any;
    contract : boolean;
    contractLink : string;
    contractNote : string;
    budget : any;
    currency: any;
    estScope : any;
    stage: any;
    probabilityPercentage: any;
    accountManager: any;
    contractType:any;
    approvalStatus:any;
    solutionList : any
}