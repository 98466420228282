import { Injectable } from '@angular/core';
import { KRONOS } from '../../../app-constants';
import { API } from '../../../constants/index';
import { DataService } from '../core/interceptor.service';

@Injectable({
  providedIn: 'root'
})
export class AllocationService {

  constructor(private dataService: DataService) { }

  addUpdateAvailability(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.AVAILABILITY + API.ADD_UPDATE_AVAILABILITY, req);
  }

  getUserAvailability() {
    return this.dataService.get(KRONOS.BASE + KRONOS.AVAILABILITY + API.GET_USER_AVAILABILITY);
  }
}
