export class Customer {
    id: number;
    accName: string;
    site: string;
    countryId: number;
    countryName: string;
    domainId: number;
    domainName: string;
    description: string;
    walletPower: string;
    size: string;
    csat: number;
    imagePath: string;
    imageUrl: any;
    active: boolean;
    deleted: boolean;
    createdDate: string;
    updatedDate: string;
    createdBy: string;
    updatedBy: string;
}
