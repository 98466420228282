import { Injectable } from '@angular/core';
import { DataService } from '../core/interceptor.service';
import { KRONOS } from '../../../app-constants';
import { API } from '../../../constants/index';

@Injectable()
export class CountryService {

  constructor(private dataService: DataService) { }

  getAll() {
    const req = {
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_COUNTRIES, req);
  }

}
