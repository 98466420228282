import { Injectable } from '@angular/core';
import { DataService } from '../core/interceptor.service';
import { KRONOS } from '../../../app-constants';
import { API } from '../../../constants/index';

@Injectable()
export class CurrencyService {

  constructor(private dataService: DataService) { }

  addCurrency(currency) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ADD_CURRENCY, currency);
  }

  updateCurrency(currency) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.UPDATE_CURRENCY, currency);
  }

  deleteCurrency(id) {
    const req = {
      id: id
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.DELETE_CURRENCY, req);
  }

  getAllCurrency() {
    const req = {
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_CURRENCY, req);
  }

  getAllConvertingCurrencies(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.FINANCE + API.GET_ALL_CONVERTING_CURRENCIES, req);
  }

  addConvertingCurrencies(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.FINANCE + API.ADD_CONVERTING_CURRENCIES, req);
  }

  getAllCurrencyRates(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.FINANCE + API.GET_ALL_CURRENCY_RATES, req);
  }

  addCurrencyRates(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.FINANCE + API.ADD_CURRENCY_RATES, req);
  }

}

