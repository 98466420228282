import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { UsersService, UtilsService, FeedbackCardService, CommunicationService } from 'app/helpers/services';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { NgForm } from '@angular/forms';
import { SharedCommunicationService } from 'app/helpers/services/communication.service';


@Component({
  selector: 'app-onboarding-feedback-form',
  templateUrl: './onboarding-feedback-form.component.html',
  styleUrls: ['./onboarding-feedback-form.component.scss'],
  providers: [UsersService, UtilsService, FeedbackCardService, CommunicationService],
})
export class OnboardingFeedbackFormComponent implements OnInit {

  constructor(private feedbackservice: FeedbackCardService, private usersService: UsersService, public utils: UtilsService, public communicationService: CommunicationService, private route: ActivatedRoute, private router: Router, private sharedCommunicationService: SharedCommunicationService) { }
  formSubmitted: boolean = false;
  flag: boolean = false;
  formData: any;

  questionArr: any[] = [];
  currentPageIndex: number = 0;
  loggedInUser: any;
  userId: any;
  formId: number;
  formSubmittedAttempted = false;
  textareaRequiredError: boolean = false;
  questionNumber: number;
  // allQuestionsAnswered = true;
  @Input() formInfo: any;
  @Input() response: any;
  @ViewChild('feedbackForm') feedbackForm!: NgForm;

  ngOnInit() {
    this.sharedCommunicationService.formId$.subscribe((formId) => {
      this.loadFormData(formId);
      localStorage.setItem('formId', formId.toString());
    });
    // this.viewForm();
    this.loggedInUser = this.utils.getLoggedInUser();
    this.userId = this.loggedInUser.id
  }

  viewForm() {
    this.feedbackservice.viewForms().subscribe((response: any) => {
      if (this.utils.isResponseSuccess(response)) {
        if (response.responseData && response.responseData.length > 0) {
          response.responseData.forEach(response => {
            if (response.responseData.id == 1691055764222 || 1689238029405 || 1689238642373) {
              this.formData = response.responseData;

            }
          });
          this.getFormData();
        }
      }
    }
    );
  }

  getFormData() {
    this.formId = this.formData.id;
    this.questionArr = [];
    let questionNumber = 1; // Initialize the question number
    if (this.formData.fields && this.formData.fields.length > 0) {
      this.formData.fields.forEach((field) => {
        const question = {
          questionNumber: field.fieldType !== 'heading' ? questionNumber : null, // Assign the question number conditionally
          label: field.name.trim(),
          type: field.fieldType,
          required: field.isRequired,
          values: field.values,
          selectedValue: [] // Add a new property to store the selected value (for radio and checkbox)
        };

        if (field.fieldType !== 'heading') {
          this.questionArr.push(question);
          questionNumber++; // Increment the question number for non-heading fields
        } else {
          this.questionArr.push(question); // Add heading to the array without assigning a question number
        }
      });
      this.questionArr.forEach((field) => {
        if (field.type === 'date') {
          field.selectedValue = null;
        }
      });
    }
  }

  submitForm() {
    this.formSubmittedAttempted = true;
  
    // Check if all required fields are valid
    const allFieldsValid = this.questionArr.every(field => {
      if (this.isRequiredFieldNotFilled(field)) {
        return false; // Required field not filled
      }
      return true;
    });
  
    if (allFieldsValid) {
      // Form is valid, proceed with form submission
      const answersObject = {};
      this.questionArr.forEach((field) => {
        answersObject[field.label] = field.selectedValue;
      });
      this.submitMoodOfTheCrew(answersObject);
      this.formSubmitted = true;
    }
  }

  isRequiredFieldNotFilled(field: any): boolean {
    if (field.type === 'radio' || field.type === 'checkbox') {
      return field.required && (!field.selectedValue || field.selectedValue.length === 0);
    } else if (field.type === 'dropdown') {
      return field.required && !field.selectedValue;
    } else {
      return field.required && (!field.selectedValue || (Array.isArray(field.selectedValue) && field.selectedValue.length === 0));
    }
  }


  checkTextareaRequiredError() {
    // This method is called when the textarea's ngModel value changes
    this.textareaRequiredError = this.formSubmittedAttempted && this.questionArr.some(field => field.type === 'textarea' && field.required && !field.selectedValue);
  }

  submitMoodOfTheCrew(answersObject) {
    //bind the input to request body
    const req = {
      formId: this.formId,
      userId: this.userId,
      feedbackResponse: answersObject,
    }

    this.feedbackservice.moodOfTheCrewFeedback(req).subscribe((result: any) => {
      if (this.utils.isResponseSuccess(result) && result.responseData) {
      } else {
        if (result.statusCode === 306) {
          this.utils.applogout()
        }
      }
    })
  }


  isFieldInvalidAndRequired(field: any): boolean {
    return this.formSubmittedAttempted && field.required && (!field.selectedValue || (Array.isArray(field.selectedValue) && field.selectedValue.length === 0));
  }

  selectedButton(field: any, item: any) {
    const index = field.selectedValue.indexOf(item.value);
    if (index !== -1) {
      field.selectedValue.splice(index, 1);
    } else {
      field.selectedValue.push(item.value);
    }
  }

  selectedRadio(field: any, item: any) {
    field.selectedValue = item.value;
  }

  selectOption(field: any, optionValue: any) {
    field.selectedValue = optionValue;
  }

  dateToChange(field: any, event: any) {
    const date = moment(event).format('YYYY-MM-DD');
    field.selectedValue = date;
  }

  selectStar(value: number, field: any) {
    field.selectedValue = value;
  }

  // Give numbering to the questions in current page from 1
  getCurrentPageQuestions(): any[] {
    const currentPageIndex = this.currentPageIndex;
    const questionsOnCurrentPage = this.getQuestionsForPageIndex(currentPageIndex);
    return questionsOnCurrentPage;
  }


  getQuestionsForPageIndex(pageIndex: number): any[] {
    if (!this.questionArr) {
      return [];
    }

    const questionsOnPage = [];
    let headingCount = 0;
    for (let i = 0; i < this.questionArr.length; i++) {
      const field = this.questionArr[i];
      if (field.type === 'heading') {
        headingCount++;
      }
      if (headingCount - 1 === pageIndex) {
        if (field.type === 'heading') {
          if (questionsOnPage.length > 0) {
            break; // Stop when a new heading is found after the first one
          }
        }
        questionsOnPage.push(field);
      }
    }
    return questionsOnPage;
  }

  // Helper method to check if there are more pages to show
  hasMorePages(): boolean {
    const nextPageIndex = this.currentPageIndex + 1;
    return nextPageIndex < this.getTotalPageCount();
  }

  // Helper method to check if there are previous pages to show
  hasPreviousPage(): boolean {
    return this.currentPageIndex > 0;
  }

  // Helper method to calculate the total number of pages
  getTotalPageCount(): number {
    let headingCount = 0;
    for (let i = 0; i < this.questionArr.length; i++) {
      const field = this.questionArr[i];
      if (field.type === 'heading') {
        headingCount++;
      }
    }
    return headingCount;
  }
  // getTotalPageCount(): number {
  //   let headingCount = 0;
  //   for (let i = 0; i < this.questionArr.length; i++) {
  //     const field = this.questionArr[i];
  //     if (field.type === 'heading') {
  //       headingCount++;
  //     }
  //   }
  //   if (headingCount === 0) {
  //     return 1; // If no headings, then it is a single page
  //   }
  //   return headingCount;
  // }

  // Handler for the "Next" button
  goToNextPage() {
    if (this.hasMorePages()) {
      this.currentPageIndex++;
    }
  }

  // Handler for the "Back" button
  goToPreviousPage() {
    if (this.hasPreviousPage()) {
      this.currentPageIndex--;
    }
  }

  goToDashboard() {
    this.router.navigate(['dashboard']);
  }

  loadFormData(formId: number) {
    const storedFormId = localStorage.getItem('formId');
    if (storedFormId) {
      formId = +storedFormId; // Convert string to number
    }
    // Now call the service method to fetch form data by formId
    this.feedbackservice.viewFormById(formId).subscribe((response: any) => {
      if (this.utils.isResponseSuccess(response)) {
        if (response.responseData) {
          if (response.responseData.id == 1691055764222 || 1689238029405 || 1689238642373) {
            this.formData = response.responseData;
          }
          this.getFormData(); // Update the questionArr here
        } else {
          if (response.statusCode === 306) {
            this.utils.applogout();
          }
        }
      }
    });
  }
}
