export class ModuleName {
    static accounts = 'Accounts';
    static assetManagement = 'Asset Management';
    static auditTemplate = 'Audit Template';
    static bulkUploadTemplate = 'Bulk Upload Templates';
    static countries = 'Countries';
    static currencies = 'Currencies';
    static designations = 'Designations';
    static excludedUsers = 'Excluded Users';
    static locations = 'Locations';
    static lockStatus = 'Lock Status';
    static orgProfile = 'Organization Profile';
    static skills = 'Skills';
    static sites = 'Sites';
    static teams = 'Teams';
    static taskTemplates = 'Task Templates';
    static units = 'Units';
    static userGroups = 'User Groups';
    static roles = 'Roles';
    static features = 'Features';
    static checkIn = 'Project Check-In';
    static expense = 'Expense Category';
    static csat = 'CSAT Template';
    static publicProjects = 'Public Projects';
    static industries = 'Industries';
    static thor = 'Thor';
    static dashboardBanner = 'Dashboard Banner';
}

export const AdminModules = {

    Modules: [
        {
            name: ModuleName.accounts,
            route: 'accounts'
        },
        {
            name: ModuleName.auditTemplate,
            route: 'audits'
        },
        {
            name: ModuleName.bulkUploadTemplate,
            route: 'bulkUpload'
        },
        {
            name: ModuleName.countries,
            route: 'country'
        },
        {
            name: ModuleName.csat,
            route: 'csat'
        },
        {
            name: ModuleName.currencies,
            route: 'currencies'
        },
        {
            name: ModuleName.dashboardBanner,
            route: 'dashboardBanner'
        },
        {
            name: ModuleName.designations,
            route: 'designation'
        },
        {
            name: ModuleName.excludedUsers,
            route: 'excludeUsers'
        },
        {
            name: ModuleName.expense,
            route: 'expenseCategory'
        },
        {
            name: ModuleName.features,
            route: 'features'
        },
        {
            name: ModuleName.industries,
            route: 'industries'
        },
        {
            name: ModuleName.locations,
            route: 'location'
        },
        {
            name: ModuleName.lockStatus,
            route: 'lock'
        },
        {
            name: ModuleName.orgProfile,
            route: 'organization'
        },
        {
            name: ModuleName.checkIn,
            route: 'checkIn'
        },
        {
            name: ModuleName.publicProjects,
            route: 'publicProjects'
        },
        {
            name: ModuleName.roles,
            route: 'orgRoles'
        },
        {
            name: ModuleName.skills,
            route: 'adminSkills'
        },
        {
            name: ModuleName.sites,
            route: 'sites'
        },
        // {
        //     name: 'Tasks',
        //     route: 'tasks'
        // },
        {
            name: ModuleName.teams,
            route: 'teams'
        },
        {
            name: ModuleName.taskTemplates,
            route: 'templates'
        },
        {
            name: ModuleName.units,
            route: 'units'
        },
        {
            name: ModuleName.userGroups,
            route: 'groupUsers'
        },
        {
            name: ModuleName.thor,
            route: 'thor'
        }
    ],

    // Org 
    Asset: {
        name: ModuleName.assetManagement,
        route: 'asset-list'
    }
}
