import { Component, OnInit, Input, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UsersService, UtilsService, FeedbackCardService, CommunicationService } from 'app/helpers/services';
import { FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';

interface Star {
  value: number;
  selected: boolean;
  interactable: boolean;
}

interface Feedback {
  formId: string;
  rating: number;
  goodThings: any;
  badThings: any;
  goodOther: string;
  badOther: string;
}

declare var $: any;

@Component({
  selector: 'app-feedback-card',
  templateUrl: './feedback-card.component.html',
  styleUrls: ['./feedback-card.component.scss'],
  providers: [UsersService, UtilsService, FeedbackCardService, CommunicationService],
})

export class FeedbackCardComponent implements OnInit, OnChanges {

  @Input() selectedRating: number;
  @Output() formSubmitted: EventEmitter<any> = new EventEmitter();
  selectedModalRating: number = 0;
  loggedInUser: any;
  userId: any;
  MoodOfTheCrewForm: FormGroup; //formgroup variable
  MoodOfTheCrewForm2: FormGroup; //formgroup variable 

  good: FormArray; //array to store good things
  bad: FormArray; //array to store bad things

  goodThings: any[] = [];
  badThings: any[] = [];

  feedback: Feedback = {
    formId: '',
    rating: 0,
    goodThings: [],
    badThings: [],
    goodOther: '',
    badOther: '',
  };

  stars: Star[] = [
    { value: 1, selected: false, interactable: false },
    { value: 2, selected: false, interactable: false },
    { value: 3, selected: false, interactable: false },
    { value: 4, selected: false, interactable: false },
    { value: 5, selected: false, interactable: false },
  ];
  formId: any;


  constructor(private http: HttpClient, private feedbackservice: FeedbackCardService, private usersService: UsersService, public utils: UtilsService, private formBuilder: FormBuilder, public communicationService: CommunicationService) {
    this.MoodOfTheCrewForm = this.formBuilder.group({
      checkArray: this.formBuilder.array([])
    })
    this.MoodOfTheCrewForm2 = this.formBuilder.group({
      checkArray2: this.formBuilder.array([])
    })
  }

  ngOnInit() {
    // Set the initial selected rating to the input value
    this.selectRating(this.selectedRating);
    this.viewForm();
    this.loggedInUser = this.utils.getLoggedInUser();
    this.userId = this.loggedInUser.id
  }

  viewForm() {
    this.feedbackservice.viewForms().subscribe((response: any) => {
      if (this.utils.isResponseSuccess(response)) {
        if (response.responseData && response.responseData.length > 0) {
          response.responseData.forEach(response => {
            const responseData = response.responseData;
            if (responseData.title == "Mood of the crew") {
              const fields = responseData.fields;
              this.formId = responseData.id;
              const goodThingsField = fields.find((field: any) => field.name === 'What went good?');
              const badThingsField = fields.find((field: any) => field.name === 'What could have been better');

              if (goodThingsField) {
                this.goodThings = goodThingsField.values.map((value: any) => ({
                  value: value.key,
                  label: value.value
                }));
              }

              if (badThingsField) {
                this.badThings = badThingsField.values.map((value: any) => ({
                  value: value.key,
                  label: value.value
                }));
              }
            }
          });
        }
      }
    }
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    const currentValue = changes.selectedRating.currentValue;
    this.selectModalRating(currentValue);
  }

  onStarClick(star: Star) {
    this.selectModalRating(star.value);
  }

  onRatingOptionClickedModal(option: Star) {
    this.selectModalRating(option.value);
  }

  selectModalRating(rating: number) {
    this.selectedModalRating = rating;
    this.stars.forEach(star => {
      star.selected = star.value <= rating;
    });
  }

  selectRating(rating: number) {
    // Update the selected rating
    this.selectedRating = rating;
  }

  //function to bind input to feedback object
  onCheckboxChange(e: any, formGroup: FormGroup, formArrayName: string) {
    const formArray = formGroup.get(formArrayName) as FormArray;
    if (!e.target.classList.contains('active')) {
      formArray.push(new FormControl(e.target.querySelector('input').value));
    } else {
      let i: number = 0;
      formArray.controls.forEach((item: FormControl) => {
        if (item.value == e.target.querySelector('input').value) {
          formArray.removeAt(i);
          return;
        }
        i++;
      });
    }
    return formArray;
  }

  //function to call binding depending on field 'What went good?'
  onCheckboxChangeGood(e: any) {
    this.onCheckboxChange(e, this.MoodOfTheCrewForm, 'checkArray');
    this.good = this.MoodOfTheCrewForm.get('checkArray').value as FormArray;
  }
  //function to call binding depending on field 'What could have been better?'
  onCheckboxChangeBad(e: any) {
    this.onCheckboxChange(e, this.MoodOfTheCrewForm2, 'checkArray2');
    this.bad = this.MoodOfTheCrewForm2.get('checkArray2').value as FormArray;
  }

  //final submit button function and binding
  submitForm() {
    this.feedback.rating = this.selectedRating;
    if (!this.good) {
      this.good = new FormArray([]).value;
    }
    this.feedback.goodThings = this.good;

    if (!this.bad) {
      this.bad = new FormArray([]).value;
    }
    this.feedback.badThings = this.bad;
    this.submitMoodOfTheCrew();//to submit the feedback
    // Do some form submission
    // Close the modal
    $('#feedbackModal').modal('hide');
    // Emit the event when the form is successfully submitted
    this.formSubmitted.emit();
  }

  submitMoodOfTheCrew() {
    //bind the input to request body
    const formResponse = {
      userId: this.userId,
      rating: this.feedback.rating,
      whatWentGood: this.feedback.goodThings,
      whatWentBad: this.feedback.badThings,
      goodOthers: this.feedback.goodOther,
      badOthers: this.feedback.badOther
    }
    const req = {
      formId: this.formId,
      userId: this.userId,
      feedbackResponse: formResponse
    }
    this.feedbackservice.moodOfTheCrewFeedback(req).subscribe((result: any) => {
      if (this.utils.isResponseSuccess(result) && result.responseData) {
      } else {
        if (result.statusCode === 306) {
          this.utils.applogout()
        }
      }
    })
  }
}

