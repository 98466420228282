import { Injectable } from '@angular/core';
import { DataService } from '../core/interceptor.service';
import { KRONOS } from '../../../app-constants';
import { API } from '../../../constants/index';

@Injectable()
export class EventsService {

  constructor(private dataService: DataService) { }

  getAll(startDate, endDate) {
    const req = {
      startDate, endDate
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_EVENTS, req);
  }

  add(event) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ADD_EVENT, event);
  }

  updateAllEvent(event) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.UPDATE_ALL_EVENT, event);
  }

  updateEvent(event) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.UPDATE_EVENT, event);
  }

  delete(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.DELETE_EVENT, req);
  }

  deleteAllEvent(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.DELETE_ALL_EVENT, req);
  }

  eventUserRSVP(req, stat) {
    if (!stat) {
      return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ADD_USEREVENT_RSVP, req);
    } else {
      return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.UPDATE_USEREVENT_RSVP, req);
    }
  }

  getEventUserData(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_EVENT_USER_DATA, req);
  }
}
