export class Training {
  id: string;
  firstName: string;
  lastName: string;
  userId: number;
  title: string;
  description: string;
  type: string;
  date: string;
  time: string;
  duration: string;
  location: string;
  trainerFirstName: string;
  trainerLastName: string;
  trainerId: string;
  labels: any;
  color: string;
  trainingType: string;
  external: boolean;
  internal: boolean;
  skills: any[] = [];
  trainingLevel: string;
}