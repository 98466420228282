import { Injectable } from '@angular/core';
import { KRONOS } from '../../../app-constants';
import { API } from '../../../constants/index';
import { DataService } from '../core/interceptor.service';

@Injectable()
export class FinanceService {
  constructor(private dataService: DataService) { }

  getAllProjectInvoice(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.FINANCE + API.GET_ALL_PROJECT_INVOICE, req);
  }

  bulkUpdateUserFinanceData(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.FINANCE + API.BULK_UPDATE_USER_FINANCE_DATA, req);
  }

  updateUserFinanceData(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.FINANCE + API.UPDATE_USER_FINANCE_DATA, req);
  }

  addBillingReminder(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.FINANCE + API.ADD_BILLING_REMINDER, req);
  }

  //Contracts Apis

  checkActiveContracts(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.CUSTOMER + API.CHECK_ACTIVE_CONTRACTS, req);
  }

  //Invoice Apis

  getAllFinanceInvoices(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.FINANCE + API.GET_ALL_FINANCE_INVOICES, req);
  }

  addFinanceInvoice(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.FINANCE + API.ADD_FINANCE_INVOICE, req);
  }

  updateFinanceInvoice(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.FINANCE + API.UPDATE_FINANCE_INVOICE, req);
  }

  getInvoiceCounts(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.FINANCE + API.GET_INVOICE_COUNTS, req);
  }

  getAllNonPublicProjects() {
    const req = {
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_NON_PUBLIC_PROJECTS, req);
  }

  getAllProjectsForFinance(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.FINANCE + API.GET_ALL_PROJECTS_FOR_FINANCE, req);
  }

  getYearlyOHValues(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.FINANCE + API.GET_YEARLY_OH_VALUES, req);
  }

  addOrUpdateYearlyOHValues(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.FINANCE + API.ADD_UPDATE_YEARLY_OH_VALUES, req);
  }

  getAllDeliveryTypeByOrg(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_DELIVERY_TYPE_BY_ORG, req);
  }

  getYearlyBenefits(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.FINANCE + API.GET_YEARLY_BENEFITS, req);
  }

  saveOrUpdateYearlyBenefits(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.FINANCE + API.SAVE_UPDATE_YEARLY_BENEFITS, req);
  }

  getYearlyCurrencyConversionRates(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.FINANCE + API.GET_YEARLY_CURRENCY_CONVERSION_RATES, req);
  }
  
  addOrUpdateYearlyCurrencyConversionRates(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.FINANCE + API.ADD_UPDATE_YEARLY_CURRENCY_CONVERSION_RATES, req);
  }
}
