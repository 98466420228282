import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CookieService } from 'ngx-cookie';
@Injectable()
export class LoginGuard implements CanActivate {

    constructor(private router: Router, private _cookieService: CookieService, ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this._cookieService.get('loggedInUser') != undefined) {
            // logged in so return false
            this.router.navigate(['dashboard']);
            return false;
        }
        // not logged in so redirect to login page with the return url ,
        return true;
    }
}
