import { Component, OnInit } from '@angular/core';
import { CommunicationService , CompRouterService } from '../../../helpers/services/index';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})

export class LoaderComponent implements OnInit {
  show = false;
  
  constructor(private _myCommunicationService: CommunicationService) {
    this._myCommunicationService.loaderDataChangeEmitted$.subscribe(res => {
      this.show = res;
    });
  }

  ngOnInit() {
  }

}
