import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable()
export class GlobalEventsManager {

    private _showNavBar: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    public showNavBarEmitter: Observable<boolean> = this._showNavBar.asObservable();

    private _showSideBar: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    public showSideBarEmitter: Observable<boolean> = this._showSideBar.asObservable();

    private _showManageSideBar: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    public showManageSideBarEmitter: Observable<boolean> = this._showManageSideBar.asObservable();

    private _showMaintainSideBar: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    public showMaintainSideBarEmitter: Observable<boolean> = this._showMaintainSideBar.asObservable();

    constructor() { }

    showNavBar(ifShow: boolean) {
        this._showNavBar.next(ifShow);
    }

    showSideBar(ifShow: boolean) {
        this._showSideBar.next(ifShow);
    }

    showManageSideBar(ifShow: boolean) {
        this._showManageSideBar.next(ifShow);
    }

    showMaintainSideBar(ifShow: boolean) {
        this._showMaintainSideBar.next(ifShow);
    }
}
