import { Injectable } from '@angular/core';
import { DataService } from '../core/interceptor.service';
import { KRONOS } from '../../../app-constants';
import { API } from '../../../constants/index';

@Injectable({
  providedIn: 'root'
})
export class ThorService {

  constructor(private dataService: DataService) { }

  getAllThorGeneralQueries() {
    const req = {}
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_THOR_QUERIES, req);
  }

  addUpdateThorQuery(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ADD_UPDATE_THOR_QUERY, req);
  }

  deleteThorQuery(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.DELETE_THOR_QUERY, req);
  }
}