export const MESSAGES = {
    // growl status
    'SUCCESS': 'success',

    'POPUP_BLOCKED': 'Popups are blocked. Allow popups to continue.',

    'LOGOUT_SUCCESSFUL': 'User logged out successfully',
    'ERROR': 'Oops! Something Went Wrong',
    'ADD_USER_SUCCESS': 'User Added Successfully',
    'UPDATE_USER_SUCCESS': 'User Updated Successfully',
    'UPDATE_TIMEZONE_SUCCESS': 'Timezone Updated Successfully',
    'SOMETHING_WRONG': 'Oops! Something went wrong',
    'TRY_AGAIN': 'Please try again',
    'SESSION_EXPIRED': 'Session expired',
    'TRY_AGAIN_LATER': 'please try again Later...',
    'PLEASE_LOGIN': 'Please login again.',
    'ADD_ACCOUNT_SUCCESS': 'Account Added Successfully',
    'ADD_PROJECT_UPDATE_SUCCESS': 'Updates added sucessfully',
    'EDIT_PROJECT_UPDATE_SUCESS': 'Updated sucessfully',
    'DELETE_PROJECT_UPDATE': 'Project Update Deleted Successfully',
    'ADD_PROJECT_SUCCESS': 'Project Added Successfully',
    'PROJECT_EVENT_UPDATE': 'Project Event Added Successfully',
    'DELETE_EVENT_SUCCESS': 'Project Event Deleted Successfully',
    'DELETE_EVENT_FAILURE': 'Failed to Delete',
    'PROJECT_MEMBER_ADD': 'Project Member Added Successfully',
    'PROJECT_MEMBER_DELETE': 'Project Member Removed Successfully',
    'UPDATE_MEMBER_SUCCESS': 'Project Member Updated Successfully',
    'ONLY_IMAGES': 'Only images (jpeg, jpg, png)',
    'IMAGE_SIZE': 'Only image of size (48px X 48px)',
    //project lessons and risks
    'DELETE_LESSON_SUCCESS': 'Lesson deleted successfully',
    'UPDATE_LESSON_SUCCESS': 'Lesson updated successfully',
    'ADD_LESSON_SUCCESS': 'Lesson added successfully',
    'UPDATE_RISK_SUCCESS': 'Risk update successfully',
    'DELETE_RISK_SUCCESS': 'Risk deleted successfully',
    'ADD_RISK_SUCCESS': 'Risk added successfully',
    'POST_COMMENT_SUCCESS': 'Posted successfuly',
    'DEL_COMMENT_SUCCESS': 'Deleted successfully',
    'ADD_RISK_FAILED': 'Failed to add risk',

    'DATA_NOT_DELETED': 'Data not deleted',
    'UPDATE_MEMBER_FAILURE': 'Failed to Update',
    'DELETE_PROJECT': 'Project Deleted Successfully',
    'DELETE_PROJECT_FAILURE': 'Project Deletion Failed',
    'DELETE_ACCOUNT_SUCCESS': 'Account Deleted Successfully',
    'UPDATE_ACCOUNT_SUCCESS': 'Account updated Successfully',
    'ADD_TEAM_SUCCESS': 'Team Added Successfully',
    'ADD_TEAM_FAILURE': 'Team Not Added',
    'UPDATE_TEAM_SUCCESS': 'Team Updated Successfully',
    'UPDATE_TEAM_FAILURE': 'Team Updation Failed',
    'DELETE_TEAM_SUCCESS': 'Team Deleted Successfully',
    'DELETE_TEAM_FAILURE': 'Failed to Delete Team',
    'ADD_TASK_SUCCESS': 'Task Added Successfully',
    'ADD_TASK_FAILURE': 'Task Not Added',
    'UPDATE_TASK_SUCCESS': 'Task Updated Successfully',
    'UPDATE_TASK_FAILURE': 'Task Updation Failed',
    'DELETE_TASK_SUCCESS': 'Task Deleted Successfully',
    'DELETE_TASK_FAILURE': 'Failed to Delete Task',
    'ADD_ORG_SUCCESS': 'Organization Added Successfully',
    'UPDATED_ORG_SUCCESS': 'Organization Updated Successfully',
    'DELETE_ORG_SUCCESS': 'Organization Deleted Successfully',
    'DELETE_ORG_FAILURE': 'Organization Deletion Failed',
    'ADD_TASK_TO_TEMPLATE': 'Task Added to Template',
    'REMOVE_TASK_TO_TEMPLATE': 'Task Removed From Template',
    'ADD_TASK_TOTEMPLATE_FAILED': 'Failed to add Task to Template',
    'REMOVE_TASK_TO_TEMPLATE_FAILED': 'Failed to remove task from template',
    'GET_ALL_TASK_BY_TEMPLATE_FAILED': 'Failed to get task by Template',
    'GET_ALL_TASK_FAILED': 'Failed to get all Task',
    'ADD_TEMPLATE_SUCCESS': 'Template Added Successfully',
    'ADD_TEMPLATE_FAILURE': 'Failed to add template',
    'GET_TEMPLATE': 'Failed to get Template',
    'GET_ALL_UNITS_FAILED': 'Failed to get All the units',
    'CREATE_UNIT_SUCCESS': 'Business Unit Created Successfully',
    'CREATE_UNIT_FAILED': 'Failed to create Business Unit',
    'GET_ALL_DESIGNATION_FAILED': 'Failed to get All Designation',
    'ADD_DESIGNATION_SUCCESS': 'Designation Added Successfully',
    'ADD_DESIGNATION_FAILED': 'Failed to Add Designation',
    'UPDATE_DESIGNATION_SUCCESS': 'Designation Updated Successfully',
    'UPDATE_DESIGNATION_FAILED': 'Failed to update Designation',
    'DELETE_DESIGNATION_SUCCESS': 'Designation Deleted Successfully',
    'DELETE_DESIGNATION_FAILED': 'Failed to delete Designation',
    'UPDATE_UNIT_SUCCESS': 'Unit Updated Successfully',
    'UPDATE_UNIT_FAILED': 'Failed to update Unit',
    'DELETE_UNIT_SUCCESS': 'Unit Deleted Successfully',
    'DELETE_UNIT_FAILED': 'Failed to delete Unit',
    'DELETE_LOCATION_FAILED': 'Failed to delete Location',
    'LOADING': 'Loading...',
    'NO_MATCH_FOUND': 'No Match Found...',
    'NO_PROJECT_UPDATE': 'No updates found. Want to add one?',
    'DELETE_TEMPLATE_SUCCESS': 'Template Deleted Successfully',
    'DELETE_TEMPLATE_FAILED': 'Failed to Delete Template',
    'UPDATE_PROJECT_SUCCESS': 'Project Updated Successfully',
    'UPDATE_PROJECT_FALIOUR': 'Project Updation Failed',
    'UPDATE_SUCCESS': 'Updated Successfully',
    'UPDATE_FALIOUR': 'Updation Failed',
    'DELETE_SUCCESS': 'Deleted Successfully',

    'NO_TRAININGS_FOUND': 'No Trainings found. Want to add one ?',
    'ADD_ACTIVITY_SUCCESS': 'Activity added successfully',
    'UPDATE_ACTIVITY_SUCCESS': 'Activity updated successfully',

    'ADDITIONAL_PROJECT_DATA_ADDED_SUCCESSFULLY': 'Additional project data added successfully',
    'ADDITIONAL_PROJECT_DATA_FAILED': 'Failed to add additional project data',

    //Lock
    'UPDATED_SUCCESSFULLY': 'Updated Successfully',
    'DELETED_SUCCESSFULLY': 'Deleted Successfully',
    'UNLOCK_USER': 'User unlocked Successfully',
    'MONTH_LOCK_SUCCESS': 'Month is Locked',
    'USER_UNABLE_TO_EDIT': 'User will be unable to change his time entry',
    'MONTH_UNLOCK_SUCCESS': 'Month is UnLocked',
    'USER_CAN_EDIT': 'User will be able to change his time entry',
    'DELETE_ACTIVITY_SUCCESS': 'Activity Deleted Successfully',

    'VISIT_GREENPINE': 'Please Visit Greenpine for Forgot Password Link',
    'VISIT_GMAIL': 'Please Visit your Gmail for Forgot Password Link',
    'INVALID_CREDENTIALS': 'Invalid Credentials. Please Try Again',
    'ORGANIZATION_CREATION_FAILED': 'Organization Creation Failed',
    'PROJECT_CREATION_FAILED': 'Project Creation Failed',
    'MONTH_IS_LOCKED': 'This particular Date & Month is locked',
    'MONTH_LOCKED_DETAILS': 'Please contact your manager',
    'DOWNLOAD_WEEK_REPORT': 'Download Week Report',
    'DOWNLOAD_PROJECT_REPORT': 'Download Project Report',
    'DOWNLOAD_TASK_REPORT': 'Download Task Report',
    'DOWNLOAD_HR_REPORT': 'Download HR Report',
    'DOWNLOAD_BUSINESS_REPORT': 'Download Department Report',
    'DOWNLOAD_FEATURE_REPORT': 'Download Feature Report',
    'DOWNLOAD_PROJECT_GROUP_REPORT': 'Download Project Group Report',
    'DOWNLOAD_USER_INVOICE_REPORT': 'Download User Invoice Report',
    'DOWNLOAD_TEAM_REPORT': 'Download Team Report',
    'PROJECT_NEED': 'Please select the Project',
    'ACTIVITY_NEED': 'Activity is required',
    'TASK_NEED': 'Please select the Task',
    'FEATURE_NEED': 'Please select the Feature',
    'PROJECT_REQUIRE': 'Project is Required',
    'TASK_REQUIRE': 'Task is Required',
    'VALID_TIME': 'Please Enter the Valid Time',
    'ALLOCATION_REPORT_GENERATED': 'Allocation Report Generated Successfully',
    'NAME_ALREADY_EXISTS': 'Name already exists',
    'TASK_ALREADY_EXISTS': 'Task Name already exists',
    'TEAM_ALREADY_EXISTS': 'Team Already Exists',
    'UNIT_ALREADY_EXISTS': 'Business Unit Already Exists',
    'DESIGNATION_ALREADY_EXISTS': 'Designation Already Exists',
    'ALLOCATION_REPORT_NOT_GENERATED': 'There is no allocation Report for this Month',
    'TRY_DIFFERENT_NAME': 'Please try with different name',
    'ADD_HOLIDAY_SUCCESS': 'Holiday Added Successfully',
    'ADD_HOLIDAY_FAILURE': 'Failed to add holiday',
    'UPDATE_HOLIDAY_SUCCESS': 'Holiday updated successfully',
    'UPDATE_HOLIDAY_FAILED': 'Failed to update holiday',
    'DELETE_HOLIDAY_SUCCESS': 'Holiday deleted successfully',
    'DELETE_TRAINING_SUCCESS': 'Training deleted successfully',

    'DELETE_HOLIDAY_FAILED': 'Failed to delete holiday',
    'NO_DATA_AVAILABLE': 'No data available',
    'NEW_CATEGORY_ADDED_SUCCESSFULLY': 'New expense category added successfully',
    'CATEGORY_UPDATED_SUCCESSFUL': 'Category updated successfully',
    'CATEGORY_DELETE_SUCCESS': 'Expense category deleted successfully',
    'CATEGORY_DELETE_FAILURE': 'Failed to delete expense category',
    'NEW_EXPENSES_ADDED_SUCCESSFUL': 'New claim added successfully',
    'NEW_EXPENSES_ADDED_FAILURE': 'Failure to add new claim',
    'EXPENSE_APPROVED_SUCCESS': 'Expense is been approved',
    'EXPENSE_APPROVED_FAILURE': 'Expense is not approved',
    'EXPENSE_REJECT_SUCCESS': 'Expense is been rejected',
    'EXPENSE_REJECT_FAILURE': 'Expense is not rejected',
    'MAIL_EXISTS': 'Email already exist',

    'ADD_FEATURE_SUCCESS': 'New Feature added successfully',
    'DELETE_FEATURE_SUCCESS': 'Feature Deleted Successfully',
    'REMOVE_TASK_TO_FEATURE': 'Feature Removed',
    'REMOVE_TASK_TO_FEATURE_FAILED': 'Failed to remove Feature ',
    'ADD_TASK_TO_FEATURE': 'Feature Added Successfully',
    'ADD_TASK_TO_FEATURE_FAILED': 'Failed to add Feature',
    'UPDATE_FEATURE_SUCCESS': 'Feature updated successfully',
    'DOWNLOAD_DEFAULTER_REPORT': 'Download Defaulter Report',
    'DOWNLOAD_TRAINING_REPORT': 'Download Training Report',
    'ADD_GROUP_SUCCESS': 'New Group added successfully',
    'ADD_GROUP_FAILURE': 'Failed to added Group',
    'UPDATE_GROUP_SUCCESS': 'Group updated successfully',
    'DELETE_USER_FAILED': 'Failed to Delete Group',
    'DELETE_USER_SUCCESS': 'Group Deleted Successfully',
    'ADD_USER_TO_GROUP': 'User added Successfully',
    'ADD_USER_TO_GROUP_FAILED': 'Failed to add User',
    'REMOVE_USER_TO_GROUP': 'User Removed',
    'REMOVE_USER_TO_GROUP_FAILED': 'Failed to Remove User',
    'ADD_MODULES_ORG': 'New module added successfully',
    'ADD_MODULES_ORG_FAILED': 'Failed to add module',
    'REMOVE_MODULES_ORG': 'Removed module successfully',
    'REMOVE_MODULES_ORG_FAILED': 'Failed to remove module',
    'DOWNLOAD_ACCOUNT_REPORT': 'Download Account Report',
    'ADD_LOCATION_SUCCESS': 'New Location added successfully',
    'UPDATE_LOCATION_SUCCESS': 'Location updated successfully',
    'DELETE_LOCATION_SUCCESS': 'Location Deleted Successfully',
    'ADD_LOCATION_FAILED': 'Failed to add Location',
    'LOCATIONS_ALREADY_EXISTS': 'Locations already Exits',
    'ADD_ROLE': 'Role added successfully',
    'UPDATE_ROLE': 'Role updated successfully',
    'DELETE_ROLE': 'Role deleted successfully',
    'UPDATE_PERMISSION': 'Permission updated successfully',
    'ADD_USER_ROLE': 'Member added successfully',
    'REMOVE_USER_ROLE': 'Member removed successfully',

    'ADD_QUESTION_TYPE_SUCCESS': 'Question type added successfully',
    'UPDATE_QUESTION_TYPE_SUCCESS': 'Question type is updated successfully',
    'DELETE_QUESTION_TYPE_SUCCESS': 'Question type is deleted successfully',
    //links
    'LINK_ADDED_SUCCESSFUL': 'New link is added successfully',
    'LINK_UPDATED': 'Link is updated successfully',
    'LINK_DELETED': 'Link deleted successfully',
    'LINK_TAG_DELETED': 'Link Tag deleted successfully',
    //project collation and sharing
    'SHARE_SUCCESS': 'Project shared successfully.',
    'COPY_CODE': 'please copy the shareable code to share',
    'SHARED_PROJ_UPDATE_SUCCESS': 'Shared project updated successfully',
    'NEW_GROUP_SUCCESS': 'New project group added successfully',
    'GROUP_ALREADY_EXISTS': 'Group already Exits',
    'NEW_PROJECT_SHARED_SUCCESS': 'New shared project added to group successfully',
    'CODE_COPIED': 'Shareable code is copied',
    'PLEASE_SHARE': 'You can now share the code',
    'DELETED_PROJ_FROM_GRP_SUCCESS': 'Project is deleted successfully from the group',
    'PROJ_NOT_DELETED': 'Project not deleted',
    //Announcement

    'ANNOUNCEMENT_ADDED': 'New feed is added successfully',
    'ANNOUNCEMENT_UPDATED': 'Feed is updated successfully',
    'ANNOUNCEMENT_DELETED': 'Feed is deleted successfully',
    'ANNOUNCEMENT_UPDATE_FAILED': 'Failed to update feed',
    // Org OnBoarding

    'ORG_ADDED': 'Organization Added Successfully',
    'ORGONBOARD_LOGOUT': "Please login again to access you're organization data",
    'UNAUTHORIZE_CHECK': 'You are unauthorized to access this page',
    'ADD_LOCATION_FAILURE': 'Failed to add location',
    //Events
    'EVENT_ADDED': 'New Event is added successfully',
    'EVENT_UPDATED': 'Event is updated successfully',
    'EVENT_DELETED': 'Event is deleted successfully',
    'EVENT_UPDATE_FAILED': 'Failed to update Event',
    //Exclude users
    'ADD_EXCLUDE_USER': 'Added to exclude users list',
    'DELETE_EXCLUDE_USER': 'Removed from exclude users list',
    'PROCESS_FAILED': 'Process failed. Please try again.',

    //conference rooms
    'ADD_ROOM_SUCCESS': 'Conference room added successfully',
    'UPDATE_ROOM_SUCCESS': 'Conference room updated successfully',
    'UPDATE_ROOM_FAILED': 'Failed to update conference room',
    'DELETE_ROOM_SUCCESS': 'Conference room deleted successfully',
    'DELETE_ROOM_FAILED': 'Failed to delete conference room',
    'ADD_ROOMS_FAILED': 'Failed to add Conference Rooms',
    'ROOMS_ALREADY_EXISTS': 'Conference Rooms already Exits',
    //Training
    'TRAINING_ADDED': 'New Training is added successfully',
    'JOIN_TRAINING': 'Joined sucessfully',
    'UPDATE_TRAINING': 'Training updated successfully',
    'FEEDBACK_TRAINING': 'Feedback submitted successfully',
    'FEEDBACK_TRAINING_WARNING': 'Feedback ratings required',

    'TRAINING_REQUEST_SUCCESS': 'Training request created successfully',
    'REQUEST_TRAINING_SUCCESS': 'Requested Successfully',
    'DELETE_TRAINING_REQUEST_SUCCESS': 'Training request deleted successfully',
    'DELETE_TRAINING_REQUEST_FAILURE': 'Failed to delete training request',
    'CLOSE_TRAINING_REQUEST_SUCCESS': 'Training request closed successfully',
    'CLOSE_TRAINING_REQUEST_FAILURE': 'Failed to close training request',

    //Skills
    'ADD_SKILL_SUCCESS': 'Skill added successfully',
    'UPDATE_SKILL_SUCCESS': 'Skill updated successfully',
    'UPDATE_SKILL_FAILURE': 'Failed to update Skill',
    'SKILL_ALREADY_EXISTS': 'Skill already Exits',
    'DELETE_SKILL_SUCCESS': 'Skill deleted successfully',
    'DELETE_SKILL_FAILURE': 'Failed to delete Skill',
    'APPROVE_SKILL': 'Skill approved successfully',
    'EDIT_APPROVE_SKILL': 'Skill updated and approved successfully',
    'WARNING_SKILL': 'Skill already selected',
    'INVALID_SEARCH': 'Invalid search query',
    'SELECT_SKILL': 'Cannot select 2 operators consecutively',
    'SELECT_SKILL_FIRST': 'Please select a skill, not an operator.',
    'WARNING_OPERATOR': 'Please select an operator, not a skill.',

    //Learning Resources
    'ADD_RESOURCE_SUCCESS': 'Resource Added Successfully',
    'ADD_RESOURCE_FAILED': 'Failed to Add Resource',
    'UPDATE_RESOURCE_SUCCESS': 'Resource Updated Successfully',
    'UPDATE_RESOURCE_FAILED': 'Failed to update Resource',
    'DELETE_RESOURCE_SUCCESS': 'Resource Deleted Successfully',
    'DELETE_RESOURCE_FAILED': 'Failed to delete Resource',
    'RESOURCE_LINK_ALREADY_EXISTS': 'Resource link already Exists',
    'TRY_DIFFERENT_LINK': 'Try a different Link',

    //Category
    'ADD_CATEGORY_SUCCESS': 'Category added successfully',

    //policy
    'ADD_POLICY_SUCCESS': 'Policy added successfully',
    'POLICY_NAME_VALIDATION': 'Policy name required',
    'POLICY_CONTENT_VALIDATION': 'Policy content required',
    'POLICY_CATID_VALIDATION': 'Please select category',
    'UPDATE_POLICY_SUCCESS': 'Policy updated successfully',
    'DELETE_POLICY_SUCCESS': 'Policy deleted successfully',
    'DELETE_POLICY_CAT_SUCCESS': 'Policy category deleted successfully',

    //project request
    'CREATE_PROJECT_REQUEST_USER': 'Project request created successfully',
    'UPDATE_PROJECT_REQUEST_USER': 'Project updated successfully',
    'PROJECT_ACCEPTED_MESSAGE': 'Project request accepted',
    'PROJECT_REJECTED_MESSAGE': 'Project request rejected',
    'PROJECT_REQUEST_UPDATION_FAILED': 'cannot be updated',

    //Project invoice
    'ADD_INVOICE_SUCCESS': 'Invoice added successfully',
    'PROJECT_INVOICE_DELETE': 'Invoice deleted successfully',
    'UPDATE_INVOICE_SUCCESS': 'Invoice updated successfully',

    //audit template
    'ADD_QUESTION_SUCCESS': 'Question added successfully',
    'UPDATE_QUESTION_SUCCESS': 'Question updated successfully',
    'UPDATE_QUESTION_FAILURE': 'Failed to update Question',
    'REMOVE_QUESTION_SUCCESS': 'Removed Question successfully',
    'DELETE_QUESTION_SUCCESS': 'Question deleted successfully',
    'DELETE_QUESTION_FAILURE': 'Failed to delete Question',
    'DELETE_AUDIT_FAILED': 'Failed to delete Audit',
    'AUDIT_DELETE': 'Audit deleted successfully',
    'AUDIT_UPDATE': 'Audit updated successfully',
    'AUDIT_ADD': 'Audit added successfully',
    'AUDIT_WARN': 'Audit name is required',
    'AUDIT_TAG_DELETE': 'Audit Tag deleted successfully',
    'AUDIT_TITLE': 'Audit title is required',
    //currency
    'ADD_CURRENCY_SUCCESS': 'Currency added successfully',
    'UPDATE_CURRENCY_SUCCESS': 'Currency updated successfully',
    'DELETE_CURRENCY_SUCCESS': 'Currency deleted successfully',

    //Overheads 
    'YEARLY_OH_VALUE_UPDATED_SUCCESS': 'Overhead updated successfully',
    'NEW_YEARLY_OH_ENTRY_SUCCESS': 'Overhead added successfully',
    'UPDATE_YEARLY_BENEFITS_SUCCESS': 'Benefits updated successfully',
    'ADD_YEARLY_BENEFITS_SUCCESS': 'Benefits added successfully',
    'UPDATE_YEARLY_CURRENCY_RATE_SUCCESS': 'Currency rate updated successfully',
    'ADD_YEARLY_CURRENCY_RATE_SUCCESS': 'Currency rate added successfully',

    //Thor General Query
    'ADD_QUERY_SUCCESS': 'Query added successfully',
    'UPDATE_QUERY_SUCCESS': 'Query updated successfully',
    'DELETE_QUERY': 'Query deleted sucessfully',

    // Notes
    'ADD_NOTES_SUCCESS': 'Notes added successfully',
    'UPDATE_NOTES_SUCCESS': 'Notes updated successfully',
    'DELETE_NOTES_SUCCESS': 'Notes Deleted successfully',
    'ADD_NOTES_FAILURE': 'Failed to add notes',
    'UPDATE_NOTES_FAILURE': 'Failed to update notes',
    'DELETE_NOTES_FAILURE': 'Failed to delete notes',

    // Allocation
    'ADD_ALLCATION': 'Allocation added successfully',
    'UPDATE_ALLCATION': 'Allocation updated successfully',
    'DELETE_ALLCATION': 'Allocation deleted successfully',
    'DELETE_USER_RESOURCE': 'User deleted successfully',
    'ADD_OPPORTUNITY': 'Opportunity added successfully',
    'UPDATE_OPPORTUNITY': 'Opportunity updated successfully',
    'DELETE_OPPORTUNITY_SUCCESS': 'Opportunity deleted successfully',
    'DELETE_OPPORTUNITY_FAILED': 'Failed to delete opportunity',

    // Asset-Management
    'ADD_NEW_ASSET': 'New Asset added successfully',
    'ASSIGN_NEW_USER': 'User assigned successfully',
    'UPDATE_ASSET': 'Asset udated successfully',
    'ASSET_DELETE': 'Asset removed successfully',

    // Sites
    'ADD_SITE_SUCCESS': 'Site added successfully',
    'SITE_ALREADY_EXISTS': 'Site already exists',
    'UPDATE_SITE_SUCCESS': 'Site updated successfully',
    'DELETE_SITE_SUCCESS': 'Site deleted successfully',

    // Estimation
    'ADD_ESTIMATION_SUCCESS': 'Estimation added successfully',
    'UPDATE_ESTIMATION_SUCCESS': 'Estimation updated successfully',
    'DELETE_ESTIMATION_SUCCESS': 'Estimation deleted successfully',
    //PMS
    'UPDATE_USER_GOAL_SUCCESS': 'Review added successfully',
    'ADD_GOAL_SUCCESS': 'Goal added successfully',
    'UPDATE_GOAL_SUCCESS': 'Goal updated successfully',
    'DELETE_GOAL_SUCCESS': 'Goal deleted successfully',
    'UPDATE_SECTION_SUCCESS': 'Section updated successfully',

    //Expense
    'ADD_NEW_EXPENSE': 'Expense Added Successfully',
    'UPDATE_EXPENSE': 'Expense Updated Successfully',
    'DELETE_EXPENSE': 'Expense Deleted Successfully',

    //contract
    'CONTRACT_NOTE_SUCCESS': 'Contract note added successfully',

    //Project milestone
    'ADD_MILESTONE_SUCCESS': 'Milestone added successfully',

    //Customer Space
    'ADD_CUSTOMER': 'Customer added successfully',
    'UPDATE_CUSTOMER': 'Customer updated successfully',
    'DELETE_CUSTOMER': 'Customer deleted successfully',
    'ADD_CUSTOMER_NOTES': 'Notes added successfully',
    'UPDATE_CUSTOMER_NOTES': 'Notes updated successfully',
    'DELETE_CUSTOMER_NOTES': 'Notes deleted sucessfully',
    'ADD_ACCOUNT_CONTACT': 'Contact added successfully',
    'UPDATE_ACCOUNT_CONTACT': 'Contact updated successfully',
    'DELETE_ACCOUNT_CONTACT': 'Contact deleted successfully',
    'ADD_CUSTOMER_CONTRACT': 'Contract added successfully',
    'UPDATE_CUSTOMER_CONTRACT': 'Contract updated successfully',
    'DELETE_CUSTOMER_CONTRACT': 'Contract deleted successfully',
    'DELETE_CONTRACT_REQUEST_FAILURE': 'Failed to delete contract request',


    //Finance
    'REMINDER_MAIL_SENT': 'Mail sent',
    'ADD_FINANCE_INVOICE': 'Invoice added successfully',
    'UPDATE_FINANCE_INVOICE': 'Invoice updated successfully',

    //Know-Kronos
    'ADD_FAQ_DATA': 'FAQ Added successfully',
    'DELETE_KNOW_KRONOS_CARD': 'Card deleted successfully',
    'DELETE_FAQ_DATA': 'FAQ deleted successfully',
    'UPDATE_FAQ_DATA': 'FAQ updated successfully',
    'DELETE_RELEASE_NOTES': 'Release note deleted successfully',
    'EDIT_RELEASE_NOTES': 'Release note updated successfully',
    'ADD_RELEASE_NOTES': 'Release notes added successfully',

    //IBU Space
    'DELETE_REVENUE_FORECAST_FOR_PROJECT': 'Revenue data deleted successfully',
    'EXPORTED_REVENUE_FORECAST_SUCCESSFULLY': 'Revenue forecast data exported successfully',
    'FAILED_TO_EXPORT_REVENUE_FORECAST': 'Failed to export revenue forecast data',

    //FEEDS
    'POST_UPLOADED': 'Post uploaded successfully',
    'POST_DELETED': 'Post deleted successfully',
    'POST_EDITED': 'Post edited successfully',


    //Vendors
    'ADD_VENDOR': 'Vendor added successfully',
    'UPDATE_VENDOR': 'Vendor updated successfully',
    'DELETE_VENDOR': 'Vendor deleted successfully',
    'ADD_SUB_CONTRACTOR': 'Subcontractor added successfully',
    'UPDATE_SUB_CONTRACTOR': 'Subcontractor updated successfully',
    'DELETE_SUB_CONTRACTOR': 'Subcontractor deleted successfully',
    'ADD_VENDOR_NOTES': 'Notes added successfully',
    'UPDATE_VENDOR_NOTES': 'Notes updated successfully',
    'DELETE_VENDOR_NOTES': 'Notes deleted sucessfully',
    'ADD_VENDOR_CONTRACT': 'Contract added successfully',
    'UPDATE_VENDOR_CONTRACT': 'Contract updated successfully',
    'DELETE_VENDOR_CONTRACT': 'Contract deleted successfully',
    'DELETE_VENDOR_REQUEST_FAILURE': 'Failed to delete contract request',
    'FILE_ADDED_SUCCESSFULLY': "File added successfully",
    'FILES_UPDATED_SUCCESSFULLY': "Files updated successfully",

    //Solutions
    'DELETE_SOLUTION_REQUEST_FAILURE': 'Failed to delete solution set',
    'DELETE_FILE_REQUEST_FAILURE': 'Failed to delete file',

}
