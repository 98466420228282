import { Injectable } from '@angular/core';
import { DataService } from '../core/interceptor.service';
import { KRONOS } from '../../../app-constants';
import { API } from '../../../constants/index';
import { Subject } from 'rxjs';

@Injectable()
export class TemplatesService {
  object = new Subject<any>();
  changeEmitted1$ = this.object.asObservable();
  question: any = {};
  emitChange1(myMessage: any) {
    this.object.next(myMessage);
  }
  constructor(private dataService: DataService) {
  }

  getAll() {
    const req = {
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_TEMPLATES, req);
  }

  getAllTask() {
    const req = {
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_TASK, req);
  }

  getAllTaskByTemplate(id) {
    const req = {
      id: id
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_TASK_BY_TEMPLATE, req);
  }

  addTaskToTemplate(task) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ADD_TASK_TO_TEMPLATE, task);
  }

  removeTaskFromTemplate(removeTask) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.REMOVE_TASK_TO_TEMPLATE, removeTask);
  }

  createTemplate(template) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.CREATE_TEMPLATE, template);
  }

  deleteTemplate(id) {
    const req = {
      id: id
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.DELETE_TEMPLATE, req);
  }

  /*Audit Template*/
  addQuestion(question) {
    const req = {
      question: question
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.AUDIT + API.CREATE_QUESTION_TEMPLATE, req);
  }

  getAllQuestion() {
    const req = {
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.AUDIT + API.GET_QUESTION_TEMPLATE, req);
  }

  deleteQuestion(id) {
    this.question.id = id;
    return this.dataService.post(KRONOS.BASE + KRONOS.AUDIT + API.DELETE_QUESTION_TEMPLATE, this.question);
  }

  updateQuestion(obj) {
    return this.dataService.post(KRONOS.BASE + KRONOS.AUDIT + API.UPDATE_QUESTION_TEMPLATE, obj);
  }

  addQuestionToTemplate(obj) {
    return this.dataService.post(KRONOS.BASE + KRONOS.AUDIT + API.ADD_QUESTION_TO_TEMPLATE, obj);
  }

  removeQuestionFromTemplate(obj) {
    return this.dataService.post(KRONOS.BASE + KRONOS.AUDIT + API.REMOVE_QUESTION_FROM_TEMPLATE, obj);
  }

  getAllAuditTemplates() {
    const req = {
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.AUDIT + API.GET_ALL_AUDIT_TEMPLATES, req);
  }

  createAuditTemplate(templateObj) {
    return this.dataService.post(KRONOS.BASE + KRONOS.AUDIT + API.CREATE_NEW_AUDIT_TEMPLATES, templateObj);
  }

  deleteAuditTemplate(id) {
    const req = {
      id: id
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.AUDIT + API.DELETE_AUDIT_TEMPLATE, req);
  }

  getAuditTemplateById(id) {
    const req = {
      id: id
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.AUDIT + API.GET_AUDIT_TEMPLATE_BY_ID, req);
  }

  addAuditResponse(obj) {
    return this.dataService.post(KRONOS.BASE + KRONOS.AUDIT + API.ADD_AUDIT_RESPONSE, obj);
  }

  getTemplateByTemplateId(id) {
    const req = {
      id: id
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.AUDIT + API.GET_AUDIT_TEMPLATE_BY_TEMPLATE_ID, req);
  }

  getAllAuditResponses(obj) {
    return this.dataService.post(KRONOS.BASE + KRONOS.AUDIT + API.GET_ALL_AUDIT_RESPONSES, obj);
  }

  editAuditResponsesById(id) {
    const req = {
      id: id
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.AUDIT + API.EDIT_AUDIT_RESPONSE_BY_ID, req);
  }

  updateAuditResponse(obj) {
    return this.dataService.post(KRONOS.BASE + KRONOS.AUDIT + API.UPDATE_AUDIT_RESPONSE, obj);
  }

  deleteAudit(id) {
    const req = {
      id: id
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.AUDIT + API.DELETE_AUDIT, req);
  }
}
