import { DomSanitizer, SafeValue } from '@angular/platform-browser';
import { FormControl } from '@angular/forms';
import { SecurityContext } from '@angular/core';

export function sanitizeInputFactory(sanitizer: DomSanitizer) {
  return () => {
    FormControl.prototype['_oldSetValue'] = FormControl.prototype.setValue;

    FormControl.prototype.setValue = function (value: any, options: any) {

      if (typeof value === 'string') {
        const sanitizedValue: SafeValue | null = sanitizer.sanitize(
          SecurityContext.HTML,
          value
        );

        if (sanitizedValue !== null) {
          let restoredValue = sanitizedValue.toString();
          restoredValue = restoredValue.replace(/&lt;/g, '<')
            .replace(/&gt;/g, '>')
            .replace(/&amp;/g, '&')
            .replace(/&quot;/g, '"')
            .replace(/&#39;/g, "'")
            .replace(/&#10;/g, '\n')
            .replace(/&#13;/g, '\r')
            .replace(/&#9;/g, '\t')
            .replace(/&#8;/g, '\b')
            .replace(/&#32;/g, ' ');
          arguments[0] = restoredValue;
        } else {
          arguments[0] = '';
        }
      }

      return this['_oldSetValue'].apply(this, arguments);
    };
  };
}
