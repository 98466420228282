import { Injectable } from '@angular/core';
import { KRONOS } from '../../../app-constants';
import { API } from '../../../constants/index';
import { DataService } from '../core/interceptor.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ExpenseService {
  pending: boolean = true;
  approved: boolean = false;
  rejected: boolean = false;
  waiting: boolean = true;
  filteredType = 'All';
  startDate: any;
  endDate: any;
  subStartDate: any = moment().subtract(29, 'days').format('YYYY-MM-DD');
  subEndDate: any = moment().format('YYYY-MM-DD');

  constructor(private dataService: DataService) { }

  updatePending(value: boolean) {
    this.pending = value;
  }

  updateApproved(value: boolean) {
    this.approved = value;
  }

  updateRejected(value: boolean) {
    this.rejected = value;
  }

  updateWaiting(value: boolean) {
    this.waiting = value;
  }

  updateFilteredType(value: string) {
    this.filteredType = value;
  }

  updateStartDate(value: string) {
    this.startDate = value;
  }

  updateEndDate(value: string) {
    this.endDate = value;
  }

  updateSubStartDate(value: string) {
    this.subStartDate = value;
  }

  updateSubEndDate(value: string) {
    this.subEndDate = value;
  }

  getUserExpenses(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.EXPENSES + API.GET_USER_EXPENSE, req);
  }

  getAllExpenses(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.EXPENSES + API.GET_ALL_EXPENSE, req);
  }
  getExpensesByApprover(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.EXPENSES + API.GET_EXPENSES_BY_APPROVER, req);
  }

  addNewExpense(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.EXPENSES + API.ADD_NEW_EXPENSE, req);
  }



  updateExpense(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.EXPENSES + API.UPDATE_USER_EXPENSE, req);
  }

  deleteExpenses(id) {
    const req = {
      id: id
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.EXPENSES + API.DELETE_USER_EXPENSE, req);
  }

  getExpenseById(id) {
    const req = {
      id: id
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.EXPENSES + API.GET_EXPENSE_BY_ID, req);
  }

  updateExpenseStatus(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.EXPENSES + API.UPDATE_EXPENSE_STATUS, req);
  }

  addUpdateExpenseType(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.EXPENSES + API.ADD_UPDATE_EXPENSE_TYPE, req);
  }

  getExpenseCategory() {
    return this.dataService.get(KRONOS.BASE + KRONOS.EXPENSES + API.GET_EXPENSE_CATEGORY);
  }

}
