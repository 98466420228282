import { Injectable } from '@angular/core';
import { DataService } from '../core/interceptor.service';
import { KRONOS } from '../../../app-constants';
import { API } from '../../../constants/index';


@Injectable()
export class TasksService {

  constructor(private dataService: DataService) { }
  getAll() {
    const req = {
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_TASKS, req);
  }

  add(name) {
    const req = {
      taskName: name
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ADD_TASK, req);
  }

  update(id, name) {
    const req = {
      id: id, taskName: name
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.UPDATE_TASK, req);
  }

  delete(id) {
    const req = {
      id: id
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.DELETE_TASK, req);
  }
}
