import { Component, OnInit, Input, Output, EventEmitter, } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { UtilsService, CompRouterService } from '../../../../helpers/services/index';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit {
  loggedInUser: any;
  default: any;
  selectedFilter: any = {};
  localFilters: any = {};
  searchStrings: any = {};
  @Input() filters: any;
  @Input() resource: any;
  @Output() filter = new EventEmitter<Object>();
  constructor(public utils: UtilsService, public compRouter: CompRouterService, private _cookieService: CookieService) {
  }

  ngOnInit() {
    if (this._cookieService.get('loggedInUser') != undefined) {
      this.loggedInUser = JSON.parse(this._cookieService.get('loggedInUser'));
    }
    if (localStorage.getItem('filters') != null) {
      this.localFilters = JSON.parse(this.utils.idDecryption(localStorage.getItem('filters')));
      if (this.localFilters[this.resource.type]) {
        this.selectedFilter = this.localFilters[this.resource.type];
        this.setLocals();
      } else {
        this.selectedFilter = {};
      }
    }
    this.filters.forEach(filter => {
      this.searchStrings[filter.type] = "";
      if (this.resource.default && this.loggedInUser) {
        if (filter.type === this.resource.default.type) {
          filter.list.forEach(element => {
            if (this.selectedFilter != null && this.selectedFilter[this.resource.default.type]
              && this.selectedFilter[this.resource.default.type].length > 0) {
              if (this.selectedFilter[this.resource.default.type].includes(element[filter.idField])) {
                element.selected = true;
                this.default = false;
              }
            } else if (element[filter.idField] === this.loggedInUser[this.resource.default.dataField]) {
              element.selected = true;
              this.default = true;
            }
          });
        }
      }
    });
    this.getFilters();
  }

  setLocals() {
    this.filters.forEach(filterData => {
      if (this.selectedFilter[filterData.type] && this.selectedFilter[filterData.type].length > 0) {
        filterData.list.forEach(filter => {
          if (this.selectedFilter[filterData.type].includes(filter[filterData.idField])) {
            filter.selected = true;
          }
        })
      }
    });
  }

  selectFilter(obj, type) {
    obj.selected = !obj.selected;
    if (this.resource.default && type == this.resource.default.type) {
      this.default = false;
    }
    this.getFilters();
  }

  getFilters() {
    this.filters.forEach(filterData => {
      this.selectedFilter[filterData.type] = this.getFilteredId(filterData.list, filterData.idField);
    });
    this.filter.emit(this.selectedFilter);
    if (this.default) {
      this.selectedFilter[this.resource.default.type] = [];
    }
    this.localFilters[this.resource.type] = this.selectedFilter;
    const encryptedObj = (this.utils.idEncryption(JSON.stringify(this.localFilters)));
    localStorage.setItem('filters', encryptedObj);
  }

  getFilteredId(list, field) {
    const filteredId = [];
    list.forEach(obj => {
      if (obj.selected) {
        filteredId.push(obj[field]);
      }
    });
    
    return filteredId;
  }

  checkItems(arrayList, event, type) {
    arrayList.forEach(obj => {
      obj.selected = event
    });
    if (this.resource.default && type == this.resource.default.type) {
      this.default = false;
    }
    this.getFilters();
  }

  getFilterKey(search, name) {
    return { [name]: search };
  }

}
