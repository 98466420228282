import { Injectable } from '@angular/core';
import { DataService } from '../core/interceptor.service';
import { KRONOS } from '../../../app-constants';
import { UtilsService } from '../core/utils.service';
import { API } from '../../../constants/index';

@Injectable()
export class ConferenceService {
  conferenceRooms: any = {};

  constructor(private dataService: DataService, private utils: UtilsService) {
  }

  getAllConferenceRooms() {
    const req = {
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_CONFERENCE_ROOMS, req);
  }

  addNewConferenceRoom(conferenceRoom) {
    this.conferenceRooms = conferenceRoom;
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ADD_NEW_CONFERENCE_ROOM, this.conferenceRooms);
  }

  updateConferenceRoom(conferenceRoom) {
    this.conferenceRooms = conferenceRoom;
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.UPDATE_CONFERENCE_ROOMS, this.conferenceRooms);
  }

  deleteConferenceRoom(id) {
    const req = {
      id: id
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.DELETE_CONFERENCE_ROOMS, req);
  }
}
