import { Injectable } from '@angular/core';
import { KRONOS } from '../../../app-constants';
import { API } from '../../../constants/index';
import { DataService } from '../core/interceptor.service';

@Injectable()
export class ResourceManagementService {

  constructor(private dataService: DataService) { }

  addNew(obj) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ADD_RESOURCE_ALLOCATION, obj);
  }
  updateByUser(obj) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.UPDATE_RESOURCE_ALLOCATION, obj);
  }
  getAll(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_RESOURCE_ALLOCATION, req);
  }
  deleteByUser(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.DELETE_RESOURCE_ALLOCATION, req);
  }
  getBenchReport(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_BENCH_REPORT, req);
  }
  getSalesPlanner() {
    const req = {
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_SALES_PLANNER, req);
  }
  newOpportunity(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ADD_OPPORTUNITY, req);
  }
  updateOpportunity(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.UPDATE_OPPORTUNITY, req);
  }
  deleteOpportunity(id) {
    const req = {
      id: id
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.DELETE_OPPORTUNITY, req);
  }
  getAllOpportunity() {
    const req = {
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_OPPORTUNITY, req);
  }
  getUserAllocation(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_USER_ALLOCATION, req);
  }
}
