import { Injectable } from '@angular/core';
import { DataService } from '../core/interceptor.service';
import { KRONOS } from '../../../app-constants';
import { API } from '../../../constants/index';

@Injectable()
export class ExcludeUsersService {

  constructor(private dataService: DataService) { }
  getAll() {
    const req = {
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_EXCLUDE_USERS, req);
  }

  excludeUser(userId) {
    const req = {
      userId: userId
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.MAKE_EXCLUDE_USER, req);
  }

  deleteExcludeUser(userId) {
    const req = {
      userId: userId
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.DELETE_EXCLUDE_USER, req);
  }
}
