import { Injectable } from '@angular/core';
import { DataService } from '../core/interceptor.service';
import { KRONOS } from '../../../app-constants';
import { API } from '../../../constants/index';
import { OrgOnboarding } from '../../../helpers/models/org-onboarding/org-onboarding';

@Injectable()
export class OrgOnboardingService {

  constructor(private dataService: DataService, private orgOnboarding: OrgOnboarding) {
    this.orgOnboarding = orgOnboarding;
  }

  add(org) {
    this.orgOnboarding = org;
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.REGISTER_ORG, this.orgOnboarding);
  }

}
