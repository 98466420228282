export class ExpenseReport {
	itemName: string
	description: string
	startDate: string
	endDate: string
	billNo: string
	amount: string
	unitId: number
	projectId: number
	approverId: number
	catId: number
}