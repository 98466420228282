import { Component, OnInit, Input, Output, EventEmitter, } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { UtilsService, CompRouterService } from '../../../../helpers/services/index';
import { ROUTERLINKS } from 'app/constants';
import { Router } from '@angular/router';

@Component({
  selector: 'app-account-details-card',
  templateUrl: './account-details-card.component.html',
  styleUrls: ['./account-details-card.component.scss']
})
export class AccountDetailsCardComponent implements OnInit {

  @Input() resource: any;
  @Input() accounts: any;

  constructor(public utils: UtilsService, public router: Router) { }

  sizeList: string[] = ['xs', 's', 'm', 'l', 'xl'];

  ngOnInit() {
  }

  routeToAccount(data) {
    this.router.navigate([ROUTERLINKS.CUSTOMER], { queryParams: { id: this.utils.idEncryption(data.id) } })
  }

}
