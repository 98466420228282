import { Injectable } from '@angular/core';
import { KRONOS } from '../../../app-constants';
import { API } from '../../../constants/index';
import { DataService } from '../core/interceptor.service';

@Injectable({
  providedIn: 'root'
})
export class FoodRegisterService {

  constructor(private dataService: DataService) { }

  getUserFoodStatus(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.FOOD + API.GET_USER_FOOD_STATUS, req);
  }

  getAllUsersFoodStatus(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.FOOD + API.GET_ALL_USERS_FOOD_STATUS, req);
  }

  foodBooking(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.FOOD + API.FOOD_BOOKING, req);
  }
  
}
