import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  routerObj: any;
  name: any;

  constructor() { }
}
