export class Vendor {
    id: number;
    name: string;
    address: string;
    authorizedSignatoryName: string;
    authorizedDesignationId: number;
    authorizedDesignationName: string;
    authorizedSignatoryEmailId: string;
    companyCINNumber: string;
    domainId: number;
    domainName: string;
    contactPersonEmailId: string;
    createdDate: string;
    updatedDate: string;
    createdBy: string;
    updatedBy: string;
    active: boolean;
    deleted: boolean;
    userId: number | null;
    orgId: number | null;
    imagePath: string;
  imageUrl: boolean;

}
