import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UtilsService, CompRouterService } from '../../../../helpers/services/index';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit {
  @Input() tabs: any;
  @Input() selectedTab: any;
  @Output() selectTab = new EventEmitter<Object>();

  constructor(public utils: UtilsService, public compRouter: CompRouterService) { }

  ngOnInit() {
  }

  getSelectedTab(tab) {
    this.selectTab.emit(tab);
  }

  getTabStatus(tab) {
    if (this.selectedTab === tab) {
      return true;
    }
    return false;
  }
}
