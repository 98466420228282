import { Injectable } from '@angular/core';
import { DataService } from '../core/interceptor.service';
import { KRONOS } from '../../../app-constants';
import { API } from '../../../constants/index';

@Injectable()
export class DashboardBannerService {

  constructor(private dataService: DataService) { }

  addNewBanner(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.ADMIN + API.ADD_NEW_BANNER, req);
  }

  updateBanner(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.ADMIN + API.UPDATE_BANNER, req);
  }

  deleteBanner(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.ADMIN + API.DELETE_BANNER, req);
  }

  getActiveBanners() {
    const req = {}
    return this.dataService.post(KRONOS.BASE + KRONOS.ADMIN + API.GET_ACTIVE_BANNERS, req);
  }

  getInactiveBanners() {
    const req = {}
    return this.dataService.post(KRONOS.BASE + KRONOS.ADMIN + API.GET_INACTIVE_BANNERS, req);
  }
}