import { Injectable } from '@angular/core';
import { DataService } from '../core/interceptor.service';
import { KRONOS } from '../../../app-constants';
import { API } from '../../../constants/index';

@Injectable({
  providedIn: 'root'
})
export class CsatService {

  constructor(private dataService: DataService) { }

  createUpdateFeedbackquestion(request) {
    return this.dataService.post(KRONOS.BASE + KRONOS.FEEDBACK + API.CREATE_UPDATE_QUESTION, request);
  }

  createUpdateFeedbackTemplate(request) {
    return this.dataService.post(KRONOS.BASE + KRONOS.FEEDBACK + API.CREATE_UPDATE_FB_TEMPLATE, request);

  }

  clientFeedback(request) {
    return this.dataService.post(KRONOS.BASE + KRONOS.FEEDBACK + API.CLIENT_FEEDBACK, request);
  }

  getFeedbackQuestions() {
    return this.dataService.get(KRONOS.BASE + KRONOS.FEEDBACK + API.GET_FEEDBACK_QUESTION)
  }

  getFeedbackTemplateById(id) {
    return this.dataService.get(KRONOS.BASE + KRONOS.FEEDBACK + API.GET_FEEDBACK_TEMPLATE_BY_ID + '/' + id)
  }

  getFeedbackTemplate() {
    return this.dataService.get(KRONOS.BASE + KRONOS.FEEDBACK + API.GET_ALL_FEEDBACK_TEMPLATE);
  }

  createUpdateForm(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.FEEDBACK + API.CREATE_UPDATE_FORM, req);
  }

  getFeedbackForm(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.FEEDBACK + API.GET_FEEDBACK_FORM, req);
  }

  getMyFeedback(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.FEEDBACK + API.GET_MY_FEEDBACK, req);
  }

  getProjectCSAT(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.FEEDBACK + API.GET_PROJECT_CSAT, req);
  }

  shareFeedbackForm(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.FEEDBACK + API.SHARE_FEEDBACK_FORM, req);
  }

}
