export class User {
  id: number;
  username: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  dob: string;
  gender: string;
  roleId = [];
  designationId: number;
  businessUnitId: number;
  technology: string;
  empId: string;
  teamId: string;
  bloodgroup: string;
  doj: string;
  dofj: string;
  dateofleaving: string;
  probationDate: string;
  careerGap: string;
  userType: string;
  emergencyContactList = [];
  socialContactList = [];
  imagePath: string;
  consultantProfileUrl: string;
  siteId: number;
  countryId: number;
  mentorIds = [];
  managerIds = [];
  dol: string;
}
