import { Component, OnInit, Input, Output, EventEmitter, } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { UtilsService, CompRouterService } from '../../../../helpers/services/index';

@Component({
  selector: 'app-contact-card',
  templateUrl: './contact-card.component.html',
  styleUrls: ['./contact-card.component.scss']
})
export class ContactCardComponent implements OnInit {
  @Input() resource: any;
  @Input() contacts: any;
  @Output() edit = new EventEmitter<Object>();

  constructor(public utils: UtilsService) { }

  ngOnInit() {
  }

  onEdit(data) {
    this.edit.emit(data);
  }

}
