import { Injectable } from '@angular/core';
import { DataService } from '../core/interceptor.service';
import { KRONOS } from '../../../app-constants';
import { API } from '../../../constants/index';

@Injectable()
export class DesignationService {

  constructor(private dataService: DataService) { }

  getAll() {
    const req = {}
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_DESIGNATIONS_BY_ORG, req);
  }

  addDesignation(designation) {
    const req = {
      name: designation
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ADD_DESIGNATION, req);
  }

  updateDesignation(designation) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.UPDATE_DESIGNATION, designation);
  }

  deleteDesignation(id) {
    const req = {
      id: id
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.DELETE_DESIGNATION, req);
  }
}
