export class Location{
    id:number
    name:string
    //updateLocationName:string
    description: string
    //updateDescription: string
    source: string
    userId: number
    active: boolean
    orgId: number
    code: string
    locIcon: string
}