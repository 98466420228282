import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-tracking-table',
  templateUrl: './tracking-table.component.html',
  styleUrls: ['./tracking-table.component.scss']
})
export class TrackingTableComponent implements OnInit {

  @Input() monthAllocationMap: Map<string, any>; // Input data for the table
  @Input() editMonthTracking: Map<string, any>;
  @Input() itemMap: Map<string, any>;
  @Input() nestedItemMap: Map<string, any>;
  @Input() projectMap: Map<string, any>;
  @Input() itemSelected: any;
  @Input() categorySelected: any;
  @Input() columns: string[]; // Column names for the table
  @Input() editMode: boolean; // Flag to enable edit mode
  @Input() weeksData: string[];
  @Input() isHovered: any = null;
  @Input() editingRowData: any;
  @Output() editingEvent = new EventEmitter<string>();
  @Output() cancelTrackingEditingEvent = new EventEmitter<string>();
  @Output() saveTrackingEvent = new EventEmitter<string>();
  @Output() hoursConverted: EventEmitter<any> = new EventEmitter<any>();
  @Output() viewItemEvent: EventEmitter<any> = new EventEmitter<any>();
  constructor() { }

  ngOnInit() {
  }

  getKeys(map) {
    return Array.from(map.keys());
  }

  getTotal(map, data, field) {
    let total = 0;
    if (data) {
      this.getKeys(map).forEach((id) => {
        if (map.get(id).has(data)) {
          let item = map.get(id).get(data)[field]
          if (item && !Number.isNaN(item)) {
            total += item;
          }
        }
      })
    } else {
      this.getKeys(map).forEach((id) => {
        let item = map.get(id)[field]
        if (item && !Number.isNaN(item)) {
          total += item;
        }
      })
    }
    return total;
  }

  editing(type: string) {
    this.editingEvent.emit(type);
  }

  cancelTrackingEditing(type: string) {
    this.cancelTrackingEditingEvent.emit(type);
  }

  saveTracking(type: string) {
    this.saveTrackingEvent.emit(type);
  }

  hoursConversion(obj: any, value: string) {
    switch (value) {
      case 'allocation':
        obj.allocation = Math.round(obj.allocationHrs * 60);
        this.hoursConverted.emit(obj);
        break;
      case 'billable':
        obj.billable = Math.round(obj.billableHrs * 60);
        this.hoursConverted.emit(obj);
        break;
      default:
        break;
    }
  }

  viewItem(type:any){
    this.viewItemEvent.emit(type);
  }
}
