import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { CustomerSpaceService, UtilsService } from "../../../../helpers/services/index";
import { MESSAGES } from 'app/constants/msg-constants';

@Component({
  selector: 'app-contract-details-card',
  templateUrl: './contract-details-card.component.html',
  styleUrls: ['./contract-details-card.component.scss']
})
export class ContractDetailsCardComponent implements OnInit {

  @Input() resource: any;
  @Input() contract: any;
  @Input() list: any;
  @Input() type: any;
  @Output() removeAllFilters = new EventEmitter<Object>();
  @Output() edit = new EventEmitter<Object>();
  @Output() deleteContractRequest = new EventEmitter<Object>();
  searchString: any;
  selectedItem: any;
  disableBtn: any = false;
  dropdownMenu=["View Contract", "Edit", "Delete"]
  constructor(public utils: UtilsService, private customerService: CustomerSpaceService) { }

  ngOnInit() {
  }

  onEdit(data) {
    this.edit.emit(data);
  }

  deleteRequest(id) {
    const deleteRequest = {
      'id': id
    }
    this.deleteContractRequest.emit(deleteRequest);
  }

  getSelectedListItem(selectedItem: any, contractItem: any) {
    this.selectedItem = selectedItem;
    switch (this.selectedItem) {
      case "View Contract":
        window.open(contractItem.contractLink, '_blank');
        break;
    }
  }
}
