import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommunicationService } from './helpers/services/index';
import { Message } from 'primeng/primeng';
import { Router,NavigationEnd } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  msgs: Message[] = [];
  growlObj: any = {};
  appVersion = '3.15.2';
  isNavbarSticky = false;
  private destroy$ = new Subject<void>();
  constructor(private _myCommunicationService: CommunicationService,private router: Router) {
    this._myCommunicationService.changeEmitted$.subscribe(myMessage => {
      this.growlObj = myMessage;
      this.msgs = [];
      this.msgs.push(this.growlObj);
    })
  }

  ngOnInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      takeUntil(this.destroy$)
    ).subscribe((event: NavigationEnd) => {
      this.isNavbarSticky = event.url.includes('/feeds') || event.url.includes('/micro-learning');
      this._myCommunicationService.updateNavbarStickyState(this.isNavbarSticky);
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
