import { Injectable } from '@angular/core';
import { KRONOS } from '../../../app-constants';
import { API } from '../../../constants/index';
import { DataService } from '../core/interceptor.service';

@Injectable()
export class HolidayService {
  constructor(private dataService: DataService) { }

  addNew(obj) {
    const tempObj = obj;
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ADD_HOLIDAY, tempObj);
  }

  getAllHoliday(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_HOLIDAY, req);
  }


  getAllCalendarHolidayByMonth(end, start) {
    const req = {
      endDate: end, startDate: start
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_CALENDAR_HOLIDAY, req);
  }

  getAllCalendarLeavesByMonth(yearMonth, userId) {
    const req = {
      month: yearMonth, id: userId
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_CALENDAR_LEAVES_BY_MONTH, req);
  }

  getAllCalendarEventsByMonth(start, end) {
    const req = {
      startDate: start, endDate: end
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_CALENDAR_EVENTS_BY_MONTH, req);
  }

  updateHoliday(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.UPDATE_HOLIDAY, req);
  }

  deleteHoliday(id) {
    const req = {
      id: id
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.DELETE_HOLIDAY, req);
  }

  getDateRangeInfo(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_DATE_RANGE_INFO, req);
  }

  monthlyWorkingDays(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.MONTHLY_WORKING_DAYS, req);
  }

}
