import { Injectable } from '@angular/core';
import { DataService } from '../core/interceptor.service';
import { UtilsService } from '../core/utils.service';
import { KRONOS } from '../../../app-constants';
import { API } from '../../../constants/index';

@Injectable()
export class AssetManagementService {

  constructor(public utils: UtilsService, private dataService: DataService) { }


  addNewAsset(newAssetRequest) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ONBOARD_NEW_ASSET, newAssetRequest);
  }

  getAssetListByOrg() {
    const req = {
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_ASSET_BY_ORG, req);
  }

  getAssetById(asset) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ASSET_BY_ID, asset);
  }

  assignNewUserToAsset(newUser) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ASSIGN_NEW_USER_TO_ASSET, newUser);
  }

  updateAsset(asset) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.UPDATE_ONBOARDED_ASSET, asset);
  }

  deleteAssetFromUser(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.DELETE_ASSET, req);
  }

  getAssetByUser(obj) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ASSET_BY_USER, obj);
  }

  getAssets(obj) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ASSETS, obj);
  }

  getAllUsersWithAsset() {
    const req = {
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_USER_ASSEST, req);
  }
}


