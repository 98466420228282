import { Component, OnInit, Input } from '@angular/core';
import { UtilsService } from '../../../../helpers/services/index';

@Component({
  selector: 'app-profile-image',
  templateUrl: './profile-image.component.html',
  styleUrls: ['./profile-image.component.scss']
})
export class ProfileImageComponent implements OnInit {
  @Input() profile: any;

  constructor(public utils: UtilsService) { }

  ngOnInit() {
  }

}
