export class LearningResource {
	id: number;
	title: string;
	url: string;
	skills: Array<any>;
	orgId: string;
	userId: string;
	createdBy: string;
	createdByName: string;
	createdDate: string;
	updatedBy: string;
	updatedByName: string;
	updatedDate: string;
  isDeleted: boolean;
  active: boolean;
}
